import { apiClient } from 'api';

export const sendCheckoutStartedEvent = async (
  applicationUuid: string
): Promise<void> => {
  await apiClient('home').post(`/checkout-started`, {
    applicationUuid,
  });
};

export const sendClickoutStarted = async (
  applicationUuid: string,
  provider: string,
  applicants: number
): Promise<void> => {
  await apiClient('home').post(`/clickout-started`, {
    applicationUuid,
    provider,
    applicants,
  });
};

export const informAbTestToBE = async (
  applicationUuid: string,
  variantName: string,
  experimentName: string
): Promise<void> => {
  await apiClient('home').post(`/abtest-events`, {
    applicationUuid,
    variantName,
    experimentName,
  });
};

export const informAbTestToBEByUserId = async (
  userId: string,
  variantName: string,
  experimentName: string
): Promise<void> => {
  await apiClient('home').post(`/abtest-events`, {
    userId,
    variantName,
    experimentName,
  });
};

export const sendVIQESClickOutStatus = async (
  applicationUuid: string,
  debtor: 'KN1' | 'KN2',
  personID: string,
  url: string,
  status: 'clickout_completed' | 'clickout_started'
): Promise<void> => {
  await apiClient('home').post(`/viqes-clickout-status`, {
    applicationUuid,
    debtor,
    personID,
    url,
    status,
  });
};
