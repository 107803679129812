import { SignInState } from '@finanzcheck/ti-shared-ui/constants/interfaces/Session';
import BugsnagError from '@finanzcheck/ti-shared-ui/errors/bugsnagError';

export class UserContextError extends BugsnagError {
  signInState: SignInState;
  errorMsg: string;

  constructor(message: string, signInState: SignInState, errorMsg: string) {
    super(message);

    this.name = 'UserContextError';
    this.signInState = signInState;
    this.errorMsg = errorMsg;
  }

  getMetadata() {
    return {
      auth: {
        signInState: this.signInState,
      },
      error: {
        message: this.errorMsg,
      },
    };
  }
}
