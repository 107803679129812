import { getDevTenant } from './dev';
import { Tenant } from '@finanzcheck/teal-mui-theme';
import { getEnv } from './tenant';
import { RdiType } from '../constants/interfaces/Debtor';

export type Domain = 'smava' | 'finanzcheck';
export type Environment = 'shared' | 'stage' | 'live';

type RDIConfig = {
  text: string;
  shortText: string;
  tooltip: string;
};
export interface IDomainMetaData {
  clientUuid: string;
  domain: Domain;
  environment: Environment;
  themeTenant: Tenant;
  cookieDomain: string;
  tracking: {
    oneTrustDomainScriptId: string;
    segmentWriteKey: string;
    segmentHost: string;
    gtmContainerId: string;
    optimizelySdkKey: string;
  };
  documentUpload: {
    acceptedFileTypes: string;
    maxFileSize: number;
  };
  rdiInformationMessages: {
    [key in RdiType]: RDIConfig;
  };
  creditAmountLabel: string;
  recommendationTagColors: {
    cheapestOffer: {
      backgroundColor: string;
      color: string;
    };
  };
  backgroundColor: string;
  offerCardActiveBackgroundColor: string;
  disableFoaAffiliateIds: number[];
}

export const getMetaDataFromDomain = (): IDomainMetaData => {
  const base = {
    documentUpload: {
      acceptedFileTypes: import.meta.env.VITE_DOCUMENT_UPLOAD_FILE_TYPES,
      maxFileSize: parseInt(import.meta.env.VITE_DOCUMENT_UPLOAD_MAX_FILE_SIZE),
    },
    disableFoaAffiliateIds:
      import.meta.env.VITE_DISABLE_FOA_AFFILIATEIDS?.split(',').map(
        (id: string) => parseInt(id)
      ) || [],
  };
  const smavaBase = {
    ...base,
    domain: 'smava' as Domain,
    clientUuid: 'f9343d8a-4bd3-48b8-a504-e99f102f463c',
    themeTenant: Tenant.Smava,
    tracking: {
      oneTrustDomainScriptId: import.meta.env.VITE_ONETRUST_SCRIPT_KEY_SM,
      segmentWriteKey: import.meta.env.VITE_SEGMENT_WRITE_KEY_SM,
      segmentHost: import.meta.env.VITE_SEGMENT_HOST_SM,
      gtmContainerId: import.meta.env.VITE_GTM_ID_SM,
      optimizelySdkKey: import.meta.env.VITE_OPTIMIZELY_SDK_KEY_SM,
    },
    documentUpload: {
      acceptedFileTypes: import.meta.env.VITE_DOCUMENT_UPLOAD_FILE_TYPES,
      maxFileSize: +import.meta.env.VITE_DOCUMENT_UPLOAD_MAX_FILE_SIZE,
    },
    rdiInformationMessages: {
      [RdiType.COMPLETE]: {
        text: 'Komplett-Schutz',
        shortText: 'Komplett',
        tooltip: 'Schutz bei Arbeitslosigkeit, Arbeitsunfähigkeit und Tod',
      },
      [RdiType.STANDARD]: {
        text: 'Kombi-Schutz',
        shortText: 'Kombi',
        tooltip: 'Schutz bei Arbeitsunfähigkeit und Tod',
      },
      [RdiType.DEATH]: {
        text: 'einfacher Schutz',
        shortText: 'Einfach',
        tooltip: 'Schutz bei Tod',
      },
      [RdiType.NONE]: {
        text: 'keine Versicherung',
        shortText: 'keine',
        tooltip: 'keine Versicherung',
      },
    },
    creditAmountLabel: 'Kreditbetrag',
    recommendationTagColors: {
      cheapestOffer: {
        backgroundColor: '#C5E8C7', // theme.palette.primary[200]
        color: '#277634', // theme.palette.primary[600]
      },
    },
    backgroundColor: '#F7F7F7', // grey[50]
    offerCardActiveBackgroundColor: '#EDFAEB',
  };

  const fcBase = {
    ...base,
    domain: 'finanzcheck' as Domain,
    clientUuid: '744e3a15-6238-4c2e-9c39-8411cf2b6a1b',
    themeTenant: Tenant.Finanzcheck,
    tracking: {
      oneTrustDomainScriptId: import.meta.env.VITE_ONETRUST_SCRIPT_KEY_FC,
      segmentWriteKey: import.meta.env.VITE_SEGMENT_WRITE_KEY_FC,
      segmentHost: import.meta.env.VITE_SEGMENT_HOST_FC,
      gtmContainerId: import.meta.env.VITE_GTM_ID_FC,
      optimizelySdkKey: import.meta.env.VITE_OPTIMIZELY_SDK_KEY_FC,
    },
    rdiInformationMessages: {
      [RdiType.COMPLETE]: {
        text: 'Komplettschutz',
        shortText: 'Komplett',
        tooltip: 'Arbeitslosigkeit, Arbeitsunfähigkeit, Todesfall',
      },
      [RdiType.STANDARD]: {
        text: 'Standardschutz',
        shortText: 'Standard',
        tooltip: 'Arbeitsunfähigkeit, Todesfall',
      },
      [RdiType.DEATH]: {
        text: 'Todesfallschutz',
        shortText: 'Todesfall',
        tooltip: 'Todesfall',
      },
      [RdiType.NONE]: {
        text: 'Kein Schutz',
        shortText: 'keine',
        tooltip: 'Kein Schutz',
      },
    },
    creditAmountLabel: 'Wunschbetrag',
    recommendationTagColors: {
      cheapestOffer: {
        backgroundColor: '#C5E8C7', // we use the colors from smava but don't have them in the ffg theme
        color: '#277634', // we use the colors from smava but don't have them in the ffg theme
      },
    },
    backgroundColor: '#F9FBFD',
    offerCardActiveBackgroundColor: '#F3F6FA',
  };

  const { origin } = window.location;

  const matches = origin.match(/([a-z-]+)\.de/);

  if (!matches) {
    // fallback for local dev
    if (getEnv() !== 'live') {
      if (getDevTenant() === 'smava') {
        return {
          ...smavaBase,
          environment: 'stage',
          cookieDomain: 'localhost',
        };
      }
      if (getDevTenant() === 'finanzcheck') {
        return {
          ...fcBase,
          environment: 'stage',
          cookieDomain: 'localhost',
        };
      }
    }
    throw new Error(`Invalid Domain ${origin}`);
  }

  const [, domain] = matches;

  switch (domain) {
    case 'smava-shared':
      return {
        ...smavaBase,
        environment: 'shared',
        cookieDomain: '.smava-shared.de',
      };
    case 'smava-stage':
      return {
        ...smavaBase,
        environment: 'stage',
        cookieDomain: '.smava-stage.de',
      };
    case 'smava':
      return {
        ...smavaBase,
        environment: 'live',
        cookieDomain: '.smava.de',
      };
    case 'finanzcheck-shared':
      return {
        ...fcBase,
        clientUuid: '2245defb-94aa-4c24-9bb1-ce9c06e097f8',
        environment: 'shared',
        cookieDomain: '.finanzcheck-shared.de',
      };
    case 'finanzcheck-stage':
      return {
        ...fcBase,
        clientUuid: '744e3a15-6238-4c2e-9c39-8411cf2b6a1b',
        environment: 'stage',
        cookieDomain: '.finanzcheck-stage.de',
      };
    case 'finanzcheck':
      return {
        ...fcBase,
        clientUuid: 'de0b41bd-175b-4b13-adf8-eef7450bf698',
        environment: 'live',
        cookieDomain: '.finanzcheck.de',
      };
    default:
      throw new Error(`No Valid Domain MetaData found for ${origin}`);
  }
};
