import { FunctionComponent, memo } from 'react';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import { keyframes } from '@mui/system';

const ripple = keyframes`
0% {
  transform: scale(.8);
  opacity: 1;
}
100% {
  transform: scale(2.4);
  opacity: 0;
}
`;

interface IProps {
  alt: string;
  imgSrc: string;
  online?: boolean;
}

export const AvatarImage: FunctionComponent<IProps> = memo(
  ({ alt, imgSrc, online }) => (
    <Badge
      sx={(theme) => ({
        '.MuiBadge-badge': {
          backgroundColor: theme.palette.success.main,
          color: theme.palette.success.main,
          boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
          '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: `${ripple} 1.2s infinite ease-in-out`,
            border: '1px solid currentColor',
            content: '""',
          },
        },
      })}
      overlap="circular"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      variant={online ? 'dot' : 'standard'}
    >
      <Avatar
        alt={alt}
        src={imgSrc}
        sx={(theme) => ({
          width: theme.spacing(7),
          height: theme.spacing(7),
          [theme.breakpoints.down('xs')]: {
            width: theme.spacing(5),
            height: theme.spacing(5),
          },
        })}
      />
    </Badge>
  )
);
