import { useEffect } from 'react';
import { IClient } from '../../../constants/domain/client.interface';
import { Projects } from '../../../constants/domain/projects';

export const assemblePageTitle = (
  clientName: IClient['clientName'],
  project: Projects
) =>
  project === Projects.HOME
    ? `Kundenbereich - ${clientName}`
    : `Angebote - ${clientName}`;

export const useClientMetadata = (
  client: Partial<IClient>,
  project: Projects
) => {
  const { clientName } = client;
  useEffect(() => {
    if (!clientName) {
      return;
    }

    document.title = assemblePageTitle(clientName, project);
  }, [client, clientName, project]);
};
