import { logError } from '../log';
import OptimizelyError from '../../errors/optimizelyError';

const optimizelyErrorHandler = {
  /**
   * handleError
   *
   * Function which gets called when an error is thrown in the SDK
   * @param {Object} error - error object
   * @param {String} error.message - message of the error
   * @param {String} error.stack - stack trace for the error
   */
  handleError: (error: Error) => {
    logError(new OptimizelyError(JSON.stringify(error)));
  },
};

export default optimizelyErrorHandler;
