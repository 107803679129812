import { TenantDomain } from '@finanzcheck/ti-shared-ui/utils/tenant';

//TODO: Add corresponding URL's for Finanzcheck
export const HeaderURLs = {
  [TenantDomain.Smava]: {
    contactForm: 'https://www.smava.de/kontakt.html',
    faq: (isLoggedIn: boolean) =>
      isLoggedIn
        ? 'https://home.smava.de/faq'
        : 'https://smava.zendesk.com/hc/de',
  },
  [TenantDomain.Finanzcheck]: {
    contactForm: '',
    faq: () => undefined,
  },
};
