import { Box } from '@mui/material';
import { FunctionComponent, HTMLProps, memo } from 'react';
import snarkdown from 'snarkdown';

export const Markdown: FunctionComponent<
  Omit<HTMLProps<HTMLDivElement>, 'className' | 'ref'>
> = memo(({ children, ...rest }) => {
  if (typeof children !== 'string') {
    return <>{children}</>;
  }

  return (
    <Box
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: snarkdown(children),
      }}
      sx={(theme) => ({
        '& a': {
          color: theme.palette.primary.main,
          '&:visited': {
            color: theme.palette.primary.main,
          },
        },
      })}
      {...rest}
    />
  );
});
