import LinearProgress from '@mui/material/LinearProgress';
import { memo } from 'react';
import { LinearProgressProps } from '@mui/material/LinearProgress/LinearProgress';
import { Color } from '@mui/material';

interface IProps extends LinearProgressProps {
  isExpired?: boolean;
}

export const LoadingBarView = memo(({ isExpired = false, ...rest }: IProps) => {
  return (
    <LinearProgress
      sx={(theme) => ({
        height: 10,
        borderRadius: 5,
        backgroundColor: isExpired
          ? theme.palette.grey[300]
          : (theme.palette.primary as unknown as Color)[300],
        '& div': {
          backgroundColor: isExpired
            ? theme.palette.grey[400]
            : theme.palette.primary.main,
        },
        bar: {
          borderRadius: 18,
        },
      })}
      {...rest}
    />
  );
});
