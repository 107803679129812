import { AlertProps } from '@mui/material';
import { ToastStateActions, ToastStateActionTypes } from '../../actions/toast';

export interface IToastMessage {
  title: string;
  description?: string | JSX.Element;
  severity?: AlertProps['severity'];
  duration?: number | null; // "null" = does not close automatically
  key?: string; // the message can be cleared programmatically with this key
}

export interface IToastState {
  messages: IToastMessage[];
}

export const initialToastState: IToastState = {
  messages: [],
};

export const toastStateReducer = (
  state: IToastState,
  action: ToastStateActions
): IToastState => {
  switch (action.type) {
    case ToastStateActionTypes.QUEUE_MESSAGE: {
      return {
        ...state,
        messages: [...state.messages, action.message],
      };
    }

    case ToastStateActionTypes.POP_MESSAGE: {
      return {
        ...state,
        messages: state.messages.slice(1, state.messages.length),
      };
    }

    case ToastStateActionTypes.REMOVE_MESSAGE: {
      return {
        ...state,
        messages: state.messages.filter(
          (message) => message.key !== action.key
        ),
      };
    }

    default: {
      return state;
    }
  }
};
