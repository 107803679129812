import { useCallback, useEffect, useRef } from 'react';

const defaultEvents = [
  'load',
  'mousemove',
  'mousedown',
  'click',
  'scroll',
  'keypress',
];

export const useIdleTimer = (timeout: number, onAction: () => void) => {
  const timerRef = useRef<number | null>(null);

  const stopTimer = useCallback(() => {
    if (timerRef.current) clearTimeout(timerRef.current);
  }, [timerRef]);

  const startTimer = useCallback(() => {
    timerRef.current = window.setTimeout(onAction, timeout);
  }, [onAction, timerRef, timeout]);

  const resetTimeout = useCallback(() => {
    stopTimer();
    startTimer();
  }, [stopTimer, startTimer]);

  useEffect(() => {
    startTimer();
    defaultEvents.forEach((event) => {
      window.addEventListener(event, resetTimeout);
    });

    return () => {
      stopTimer();
      defaultEvents.forEach((event) => {
        window.removeEventListener(event, resetTimeout);
      });
    };
  }, [resetTimeout, startTimer, stopTimer]);
};
