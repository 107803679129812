import { GenderType } from '../domain/user.interface';

export enum SignInState {
  UNKNOWN = 'UNKNOWN',
  SIGNED_IN = 'SIGNED_IN',
  SIGNED_OUT = 'SIGNED_OUT',
  GUEST = 'GUEST',
  NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED',
}

export interface IUserDetails {
  firstName: string;
  lastName: string;
  personId: string;
  email: string;
  gender: GenderType;
}
