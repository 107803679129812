import { useEffect, useMemo } from 'react';
import initOneSegment, { cookieBannerSnippet } from '@finanzcheck/one-segment';
import { Helmet } from 'react-helmet';
import { getMetaDataFromDomain } from '../../utils/domain';

export const Segment = () => {
  const { tracking, cookieDomain } = useMemo(() => getMetaDataFromDomain(), []);
  useEffect(() => {
    initOneSegment(tracking.segmentWriteKey, {
      debug: import.meta.env.NODE_ENV === 'development',
      segmentHost: tracking.segmentHost,
      initialPageTrack: false,
      sessionCookieDomain: cookieDomain,
    });
  }, [cookieDomain, tracking.segmentHost, tracking.segmentWriteKey]);

  return (
    <Helmet>
      <script type="text/javascript">{cookieBannerSnippet()}</script>
      <script
        id="init-one-segment"
        src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
        data-domain-script={tracking.oneTrustDomainScriptId}
      />
    </Helmet>
  );
};
