import { useUserState } from 'components/Context/UserContext';
import { routes } from 'constants/routes';
import { useIsLoggedIn } from 'hooks/useIsLoggedIn';
import { FunctionComponent, memo } from 'react';
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';
import { RouteLoader } from '../RouteLoader';
import { SignInState } from '@finanzcheck/ti-shared-ui/constants/interfaces/Session';

export const UnAuthorizedRoute: FunctionComponent<RouteProps> = memo(
  ({ children, ...rest }) => {
    const isLoggedIn = useIsLoggedIn();
    const location = useLocation();
    const {
      user: { signInState },
    } = useUserState();

    if (signInState === SignInState.UNKNOWN) {
      return <RouteLoader />;
    }

    if (isLoggedIn) {
      return <Redirect to={`${routes.dashboard}${location.search}`} />;
    }

    return <Route {...rest}>{children}</Route>;
  }
);
