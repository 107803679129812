import {
  ResumeStateActions,
  ResumeStateActionTypes,
} from '../../actions/resume';

export const enum ResumeRequestOrigin {
  INITIAL = 'INITIAL',
  SEARCH = 'SEARCH',
  HEADER = 'HEADER',
}

export type IRequestInitiatedFrom =
  | ResumeRequestOrigin.INITIAL
  | ResumeRequestOrigin.SEARCH
  | ResumeRequestOrigin.HEADER;

export interface IResumeState {
  isLoading: boolean;
  isError: boolean;
  requestInitiatedFrom: IRequestInitiatedFrom;
}

export const InitialResumeState: IResumeState = {
  isLoading: false,
  isError: false,
  requestInitiatedFrom: ResumeRequestOrigin.INITIAL,
};

export const resumeStateReducer = (
  state: IResumeState,
  action: ResumeStateActions
): IResumeState => {
  const requestInitiatedFrom = action.payload;

  switch (action.type) {
    case ResumeStateActionTypes.RESUME_CALL_START: {
      return {
        ...state,
        isLoading: true,
        requestInitiatedFrom,
      };
    }

    case ResumeStateActionTypes.RESUME_CALL_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        requestInitiatedFrom,
      };
    }

    case ResumeStateActionTypes.RESUME_CALL_FAIL: {
      return {
        ...state,
        isLoading: false,
        isError: true,
        requestInitiatedFrom,
      };
    }

    case ResumeStateActionTypes.RESUME_CALL_RESET: {
      return {
        isLoading: false,
        isError: false,
        requestInitiatedFrom,
      };
    }

    default: {
      return state;
    }
  }
};
