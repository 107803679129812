import BugsnagError from '@finanzcheck/ti-shared-ui/errors/bugsnagError';

export class DocumentUploadError extends BugsnagError {
  public documentType: string;

  constructor(message: string, documentType: string) {
    super(message);

    this.name = 'DocumentUploadError';
    this.documentType = documentType;
  }

  getMetadata() {
    return {
      document: {
        type: this.documentType,
      },
    };
  }
}
