import BugsnagError from '@finanzcheck/ti-shared-ui/errors/bugsnagError';

export class ResumeError extends BugsnagError {
  public traversalId: string;

  constructor(message: string, traversalId: string) {
    super(message);

    this.traversalId = traversalId;
    this.name = 'ResumeError';
  }

  // eslint-disable-next-line class-methods-use-this
  getMetadata() {
    return {
      resume: {
        traversal: this.traversalId,
      },
    };
  }
}
