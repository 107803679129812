import {
  FunctionComponent,
  PropsWithChildren,
  useCallback,
  useMemo,
} from 'react';
import { useHistory, useLocation } from 'react-router';
import { getStorage } from '@finanzcheck/ti-shared-ui/utils/storage';

import { GuestAuthenticationScreenView } from '../../Screens/GuestSession/GuestAuthenticationScreenView';
import { useUserDispatch } from '../UserContext';
import { getCookie } from '@finanzcheck/ti-shared-ui/utils/cookie';
import useTrack from '@finanzcheck/ti-shared-ui/hooks/useTrack';
import { TrackingEvent } from '@finanzcheck/ti-shared-ui/utils/tracking/events.interface';
import { Typography } from '@mui/material';
import { removeGuestTokenFromSessionStorage } from '../UserContext/utils';
import { validateToken } from 'adapter/guestLoginAdapter';
import { logError } from '@finanzcheck/ti-shared-ui/utils/log';

export const isGuestLogin = () => {
  const exp = getCookie('guestRefreshTokenExp');

  if (exp && parseInt(exp) < Date.now() / 1000) {
    removeGuestTokenFromSessionStorage();
    return false;
  }

  const guestAccessToken = getCookie('guestAccessToken');
  const guestRefreshToken = getCookie('guestRefreshToken');

  if (!guestAccessToken || !guestRefreshToken) {
    return false;
  }

  return true;
};

const isDOBInSessionStorage = () =>
  getStorage('sessionStorage').getItem('dateOfBirth');

function getStoredRefreshToken() {
  return getCookie('guestRefreshToken');
}

export class WrongDOBError extends Error {
  constructor() {
    super('Wrong DOB');
  }
}

export class TokenGoneError extends Error {
  constructor() {
    super('Token Gone');
  }
}

export const getErrorMessage = (e: Error, tenantPhoneNumber: string) => {
  if (e instanceof WrongDOBError) {
    return (
      <Typography
        sx={{
          a: {
            color: 'error.main',
            fontWeight: 'bold',
            '&:hover': {
              textDecoration: 'underline',
            },
          },
          svg: {
            fontSize: '1rem',
          },
        }}
      >
        Tippfehler? Alternativ können Sie sich mit Ihrem Passwort anmelden oder
        sich an unseren Support wenden: {tenantPhoneNumber}.
      </Typography>
    );
  } else {
    return 'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.';
  }
};

export const GuestAuthenticationProvider: FunctionComponent<
  PropsWithChildren
> = ({ children }) => {
  const { search } = useLocation();
  const { guestLogin } = useUserDispatch();
  const { replace } = useHistory();
  const track = useTrack();
  const location = useLocation();
  const urlPath = location.pathname;
  const pathParts = urlPath.split('/');

  const { queryParams, queryGuestToken, campaign } = useMemo(() => {
    const queryParams = new URLSearchParams(search);
    const queryGuestToken = queryParams.get('guest');
    const campaign = queryParams.get('utm_campaign');

    return {
      queryParams,
      queryGuestToken,
      campaign,
    };
  }, [search]);

  const handleLogin = useCallback(
    async (newDateOfBirthValue: string): Promise<boolean> => {
      let currentToken = queryGuestToken;

      if (!currentToken) {
        const storedRefreshToken = getStoredRefreshToken();

        if (storedRefreshToken) {
          currentToken = storedRefreshToken;
        }
      }

      if (!currentToken) {
        return false;
      }

      try {
        await guestLogin(
          newDateOfBirthValue,
          currentToken,
          campaign ?? undefined
        );
        track({
          event: TrackingEvent.LoginSuccessOriginFromMagicToken,
          payload: {
            pathname: location.pathname,
            ...(pathParts[2] === 'angebote' && // Adding applicationUuid attribute conditionally based on URL matches to checkout
              pathParts[1] === 'anfrage' && {
                // Can't use the useParams() hook to read the applicationUuid from URL as this component is not inside <Route>
                applicationUuid: pathParts[3],
              }),
          },
        });
        queryParams.delete('guest');
        replace({
          search: queryParams.toString(),
        });
        return false;
      } catch (e) {
        track({
          event: TrackingEvent.LoginFailOriginFromMagicToken,
          payload: {
            error: e,
            pathname: location.pathname,
            ...(pathParts[2] === 'angebote' && // Adding applicationUuid attribute conditionally based on URL matches to checkout
              pathParts[1] === 'anfrage' && {
                // Can't use the useParams() hook to read the applicationUuid from URL as this component is not inside <Route>
                applicationUuid: pathParts[3],
              }),
          },
        });
        if (e.response?.status === 400) {
          throw new WrongDOBError();
        }
        if (e.response?.status === 410) {
          throw new TokenGoneError();
        }
        throw e;
      }
    },
    [
      queryGuestToken,
      guestLogin,
      campaign,
      track,
      location.pathname,
      pathParts,
      queryParams,
      replace,
    ]
  );

  const handleValidate = useCallback(async (token?: string) => {
    if (!token) {
      throw new Error('No token');
    }
    try {
      const data = await validateToken(token);
      return data;
    } catch (e) {
      logError(e);
      //if the validate request does not work, we don't want to block the user
      return { valid: true };
    }
  }, []);

  if (
    (queryGuestToken ||
      (isGuestLogin() &&
        !isDOBInSessionStorage() &&
        !/login/.test(window.location.href))) &&
    window.location.href.indexOf('/videoident/success') <= -1 // We don't show DOb login screen in case of user come with vi success link, for vi success case we show viSuccess login screen with DOB
  ) {
    const token = queryGuestToken || getCookie('guestRefreshToken');

    return (
      <GuestAuthenticationScreenView
        onSubmit={handleLogin}
        onValidate={handleValidate}
        token={token ?? undefined}
      />
    );
  }

  return <>{children}</>;
};
