import { ComponentType, lazy, LazyExoticComponent } from 'react';

import { logError } from '@finanzcheck/ti-shared-ui/utils/log';
import { ComponentImportError } from 'errors/componentImportError';

export interface IRoutes {
  dashboard: string;
  applicationOverview: string;
  accountCheck: string;
  faq: string;
  application: string;
  login: string;
  logout: string;
  loginPassword: string;
  forgotPassword: string;
  registration: string;
  magicLoginSent: string;
  resetPassword: string;
  invite: string;
  urlForwarding: string;
  taurine: string;
  passwordExpired: string;
  viSuccess: string;
}

export const faqBaseUrl = '/faq';

export const getFaqUrl = (
  section?: string | null,
  topic?: string | null,
  question?: string | null
) => {
  if (!section) {
    return faqBaseUrl;
  }

  if (!topic) {
    return `${faqBaseUrl}/${section}`;
  }

  if (!question) {
    return `${faqBaseUrl}/${section}/${topic}`;
  }

  return `${faqBaseUrl}/${section}/${topic}/${question}`;
};

export const getForwardingUrl = (
  applicationUuid: string,
  redirectUrl: string,
  secretToken: string
) =>
  routes.urlForwarding
    .replace(':applicationUuid', applicationUuid)
    .replace(':redirectUrl', redirectUrl)
    .replace(':secretToken', secretToken);

export const getTaurineUrl = (resumeHash = '') =>
  `${routes.taurine.replace(':resumeHash?', resumeHash)}`;

export const routes: IRoutes = {
  dashboard: '/',
  applicationOverview: '/anfrage/:traversalId',
  accountCheck: '/mein-kontoblick',
  faq: `${faqBaseUrl}/:section?/:topic?/:question?`,
  application: '/anfrage/angebote/:applicationUuid/:step?',
  loginPassword: '/login/password/',
  login: '/login',
  logout: '/logout',
  forgotPassword: '/passwort-vergessen',
  passwordExpired: '/passwort-abgelaufen',
  registration: '/registrierung',
  magicLoginSent: '/magic-login-sent',
  resetPassword: '/password-reset/:code',
  invite: '/invite',
  urlForwarding: '/forwarding/:applicationUuid/:redirectUrl/:secretToken',
  taurine: '/antrag/:resumeHash?',
  viSuccess: '/videoident/success/:viProvider/:applicationUuid?',
};

const lazyWithRetry = (
  componentImport: () => Promise<any>,
  route: keyof IRoutes | 'notFoundComponent'
) => {
  return lazy(async () => {
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
      window.sessionStorage.getItem('page-has-been-force-refreshed') || 'false'
    );

    try {
      const component = await componentImport();
      window.sessionStorage.setItem('page-has-been-force-refreshed', 'false');

      return component;
    } catch (error) {
      if (!pageHasAlreadyBeenForceRefreshed) {
        // Assuming that the user is not on the latest version of the application.
        // Let's refresh the page immediately.
        window.sessionStorage.setItem('page-has-been-force-refreshed', 'true');

        return window.location.reload();
      }
      // The page has already been reloaded
      // Assuming that user is already using the latest version of the application.
      // Let's let the application crash and raise the error.
      logError(new ComponentImportError(route));
    }
  });
};

export const routeComponents: Record<
  keyof IRoutes,
  LazyExoticComponent<ComponentType<unknown>>
> = {
  dashboard: lazyWithRetry(
    () => import('../components/Screens/DashboardScreen'),
    'dashboard'
  ),
  applicationOverview: lazyWithRetry(
    () => import('../components/Screens/ApplicationListScreen'),
    'applicationOverview'
  ),
  accountCheck: lazyWithRetry(
    () => import('../components/Screens/AccountViewScreen'),
    'accountCheck'
  ),
  faq: lazyWithRetry(() => import('../components/Screens/FaqScreen'), 'faq'),
  application: lazyWithRetry(
    () => import('../components/Screens/Application'),
    'application'
  ),
  login: lazyWithRetry(
    () => import('../components/Screens/LoginScreen'),
    'login'
  ),
  logout: lazyWithRetry(
    () => import('../components/Screens/LogoutScreen'),
    'logout'
  ),
  loginPassword: lazyWithRetry(
    () => import('../components/Screens/LoginPasswordScreen'),
    'loginPassword'
  ),
  forgotPassword: lazyWithRetry(
    () => import('../components/Screens/ForgotPasswordScreen'),
    'forgotPassword'
  ),
  registration: lazyWithRetry(
    () => import('../components/Screens/RegistrationScreen'),
    'registration'
  ),
  magicLoginSent: lazyWithRetry(
    () => import('../components/Screens/MagicLoginSentScreen'),
    'magicLoginSent'
  ),
  resetPassword: lazyWithRetry(
    () => import('../components/Screens/ResetPasswordScreen'),
    'resetPassword'
  ),
  passwordExpired: lazyWithRetry(
    () => import('../components/Screens/PasswordExpiredScreen'),
    'passwordExpired'
  ),
  invite: lazyWithRetry(
    () => import('../components/Screens/InviteScreen'),
    'invite'
  ),
  urlForwarding: lazyWithRetry(
    () => import('../components/Screens/ForwardingScreen'),
    'urlForwarding'
  ), // FIXME: Set correct component
  taurine: lazyWithRetry(
    () => import('../components/Screens/TaurineScreen'),
    'taurine'
  ),
  viSuccess: lazyWithRetry(
    () => import('../components/Screens/ViReturnScreen'),
    'viSuccess'
  ),
};

export const notFoundComponent = lazyWithRetry(
  () => import('../components/Screens/NotFound/NotFound'),
  'notFoundComponent'
);
