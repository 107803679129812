import { memo } from 'react';
import { Markdown } from 'components/Markdown';
import { Box } from '@mui/material';
import { keyframes } from '@mui/system';

interface Props {
  children: string;
  open?: boolean;
}
const fadeInFromTop = keyframes`
  0%: {
    opacity: 0;
    transform: translateY(-1.5rem);
  },
  100%: {
    opacity: 1,
    transform: 'translateY(0);
  }
`;

export const Tooltip = memo(({ children, open = false }: Props) => {
  if (!open) {
    return null;
  }

  return (
    <>
      <Box
        sx={(theme) => ({
          zIndex: theme.zIndex.tooltip,
          width: '0',
          height: '0',
          margin: '0 auto',
          borderLeft: '0.5rem solid transparent',
          borderRight: '0.5rem solid transparent',
          borderBottom: '0.5rem solid',
          borderBottomColor: theme.palette.primary.light,
          animation: `${fadeInFromTop} 0.2s`,
        })}
      />
      <Box
        sx={(theme) => ({
          zIndex: theme.zIndex.tooltip,
          margin: '0',
          padding: '0.625rem 0.875rem',
          borderRadius: '0.125rem',
          textAlign: 'start',
          animation: `${fadeInFromTop} 0.3s`,
          '& p': {
            margin: '0',
            lineHeight: '1.5rem',
          },
          backgroundColor: theme.palette.primary.light,
          color: theme.palette.primary.dark,
          '& a': {
            color: theme.palette.primary.dark,
          },
        })}
      >
        <Markdown>{children}</Markdown>
      </Box>
    </>
  );
});
