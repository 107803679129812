import { IUserDetails } from '@finanzcheck/ti-shared-ui/constants/interfaces/Session';
import {
  ICognitoUserAttributes,
  IEnrichedCognitoUser,
} from '@finanzcheck/ti-shared-ui/utils/amplify/amplify';

export enum UserStateActionTypes {
  USER_LOGIN_SUCCESSFUL = 'USER_LOGIN_SUCCESSFUL',
  USER_IN_CHALLENGEMODE = 'USER_IN_CHALLENGEMODE',
  USER_LOGOUT_SUCCESSFUL = 'USER_LOGOUT_SUCCESSFUL',
  USER_SET_ATTRIBUTES = 'USER_SET_ATTRIBUTES',
  USER_SET_DETAILS = 'USER_SET_DETAILS',
  USER_IDLE_LOGOUT = 'USER_IDLE_LOGOUT',
  USER_IDLE_DELETE = 'USER_IDLE_DELETE',
  USER_SET_ORIGINAL_LOCATION = 'USER_SET_ORIGINAL_LOCATION',
  GUEST_LOGIN_SUCCESSFUL = 'GUEST_LOGIN_SUCCESSFUL',
}

interface IUserAuthenticateSuccessful {
  type: UserStateActionTypes.USER_LOGIN_SUCCESSFUL;
  user: IEnrichedCognitoUser;
}

interface IUserAuthenticateIdleLogout {
  type: UserStateActionTypes.USER_IDLE_LOGOUT;
}

interface IUserAuthenticateIdleDelete {
  type: UserStateActionTypes.USER_IDLE_DELETE;
}

interface IUserSetAttributes {
  type: UserStateActionTypes.USER_SET_ATTRIBUTES;
  attributes: ICognitoUserAttributes | null;
}

interface IUserSetDetails {
  type: UserStateActionTypes.USER_SET_DETAILS;
  details: Partial<IUserDetails>;
}

interface IUserLogoutSuccessful {
  type: UserStateActionTypes.USER_LOGOUT_SUCCESSFUL;
}

interface IUserInChallengeMode {
  type: UserStateActionTypes.USER_IN_CHALLENGEMODE;
  user: IEnrichedCognitoUser;
}
interface IGuestAuthenticateSuccessful {
  type: UserStateActionTypes.GUEST_LOGIN_SUCCESSFUL;
  attributes: ICognitoUserAttributes | null;
}

export type UserStateActions =
  | IUserAuthenticateSuccessful
  | IUserLogoutSuccessful
  | IUserInChallengeMode
  | IUserSetAttributes
  | IUserSetDetails
  | IUserAuthenticateIdleLogout
  | IUserAuthenticateIdleDelete
  | IGuestAuthenticateSuccessful;
