import { useEffect, useCallback, useRef } from 'react';
import CobrowseIO from 'cobrowse-sdk-js';

import { logError } from '@finanzcheck/ti-shared-ui/utils/log';
import { TrackingEvent } from '@finanzcheck/ti-shared-ui/utils/tracking/events.interface';
import { LS_SURVEY_DIALOG_KEY } from '@finanzcheck/ti-shared-ui/utils/localStorage';

import useTrack from '@finanzcheck/ti-shared-ui/hooks/useTrack';
import { CoBrowseError } from '../errors/CoBrowseError';
import { useCoBrowseInstance } from 'components/Context/CoBrowse';
import { getStorage } from '@finanzcheck/ti-shared-ui/utils/storage';

interface UseCoBrowseProps {
  onSessionConfirm?: (flag: boolean) => void;
  onSessionEnd?: (flag: boolean) => void;
  onSessionEndFirstTime?: (flag: boolean) => void;
}

export const useCoBrowse = ({
  onSessionConfirm,
  onSessionEnd,
  onSessionEndFirstTime,
}: UseCoBrowseProps) => {
  const approveConsent = useRef<(flag: boolean) => void>();
  const rejectConsent = useRef<(flag: boolean) => void>();

  const { sessionCode, setSessionCode, setIsError, isError } =
    useCoBrowseInstance();
  const track = useTrack();

  const onSessionEnded = useCallback(() => {
    const isSurveyDialogOpened =
      getStorage('localStorage').getItem(LS_SURVEY_DIALOG_KEY);

    if (isSurveyDialogOpened !== 'true' && onSessionEndFirstTime) {
      onSessionEndFirstTime?.(true);
      getStorage('localStorage').setItem(LS_SURVEY_DIALOG_KEY, 'true');
    }
  }, [onSessionEndFirstTime]);

  useEffect(() => {
    CobrowseIO.on('session.ended', onSessionEnded);
  }, [onSessionEnded]);

  useEffect(() => {
    CobrowseIO.confirmSession = () => {
      track({ event: TrackingEvent.OnSupportConsentDialogShown });
      return new Promise((resolve, reject) => {
        approveConsent.current = resolve;
        rejectConsent.current = reject;
        onSessionConfirm?.(true);
      });
    };

    CobrowseIO.showSessionControls = () => {
      track({ event: TrackingEvent.OnSupportActiveSessionStarted });
      onSessionEnd?.(true);
    };

    CobrowseIO.hideSessionControls = () => {
      onSessionEnd?.(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * It generates the session code to access the session.
   */
  const generateSessionCode = useCallback(async () => {
    try {
      const sessionCode = await CobrowseIO.createSessionCode();
      setSessionCode(sessionCode);
    } catch (e) {
      track({
        event: TrackingEvent.OnGenericSupportErrorDialogShow,
        payload: {
          errorType: 'Error while generating the session code!',
        },
      });
      setIsError(true);
      logError(new CoBrowseError('Error while generating the session code!'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [CobrowseIO]);

  /**
   * It closes the current active session of the user.
   */
  const onClickCurrentSessionEnd = useCallback(async () => {
    try {
      track({ event: TrackingEvent.OnClickSupportActiveSessionEnd });
      if (CobrowseIO.currentSession) {
        await CobrowseIO.currentSession.end();
        onSessionEnd?.(false);
      }
    } catch (e) {
      track({
        event: TrackingEvent.OnGenericSupportErrorDialogShow,
        payload: {
          errorType: 'Error while ending the user session!',
        },
      });
      setIsError(true);
      logError(new CoBrowseError('Error while ending the user session!'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [CobrowseIO]);

  return {
    approveConsent: approveConsent.current,
    rejectConsent: rejectConsent.current,
    generateSessionCode,
    sessionCode,
    onClickCurrentSessionEnd,
    isError,
    setIsError,
  };
};
