import { useEffect, useState } from 'react';

import { enums } from '@optimizely/react-sdk';
import useTrack, { TrackFunction } from '../useTrack';
import { optimizelyClient } from '../../utils/optimizely/OptimizelyProvider';
import { logError } from '../../utils/log';
import { getDecisionDetails } from './parser';
import OptimizelyError from '../../errors/optimizelyError';

//https://docs.developers.optimizely.com/full-stack-experimentation/docs/set-up-segment
// decisionObject sadly has no in react-sdk types
function segmentDecision(decisionObject: any, track: TrackFunction) {
  const type = decisionObject.type;
  if (
    type != 'flag' ||
    !decisionObject.decisionInfo.variationKey ||
    decisionObject.decisionInfo.variationKey === 'off'
  ) {
    console.log(
      'ignoring decision of type: ' + type,
      'with variation key',
      decisionObject.decisionInfo.variationKey
    );
    return;
  }

  const decision = decisionObject.decisionInfo;
  try {
    // get properties needed to forward event from Segment to Optimizely
    const datafile = JSON.parse(
      optimizelyClient.getOptimizelyConfig()?.getDatafile() || '{}'
    );
    type dataFileKeysType = keyof typeof datafile;
    type dataFileValuesType = (typeof datafile)[dataFileKeysType];

    const decisionDetails = getDecisionDetails<dataFileValuesType>(datafile, {
      ruleKey: decision.ruleKey!,
      variationKey: decision.variationKey!,
    });

    const eventProperties: Record<string, unknown> = {
      experiment_name: decision.ruleKey,
      variation_name: decision.variationKey,
      ...decisionDetails,
    };
    track({
      event: 'Experiment Viewed',
      payload: eventProperties,
      optimizely: true,
    });
  } catch (e) {
    logError(
      new OptimizelyError('error on useDecision event hook:', e as Error)
    );
  }
}

export const useOptimizelySegmentSetup = () => {
  const [experiments, setExperiments] = useState<{ [key: string]: string }>({});

  const track = useTrack();
  useEffect(() => {
    optimizelyClient.notificationCenter.clearNotificationListeners(
      enums.NOTIFICATION_TYPES.DECISION
    );
    //according to the docs: https://docs.developers.optimizely.com/full-stack-experimentation/docs/set-up-segment
    optimizelyClient.notificationCenter.addNotificationListener<any>(
      enums.NOTIFICATION_TYPES.DECISION,
      (decisionObject) => {
        //prevent spamming segment with the same event
        if (
          decisionObject?.decisionInfo?.flagKey &&
          experiments[decisionObject.decisionInfo.flagKey] ===
            JSON.stringify(decisionObject)
        ) {
          return;
        }
        segmentDecision(decisionObject, track);
        setExperiments((e) => ({
          ...e,
          [decisionObject.decisionInfo.flagKey]: JSON.stringify(decisionObject),
        }));
      }
    );
  }, [experiments, track]);
};
