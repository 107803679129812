export interface Metadata {
  [section: string]: Record<
    string,
    | string
    | number
    | boolean
    | undefined
    | Array<unknown>
    | Record<string, unknown>
    | object
  >;
}

export default abstract class BugsnagError extends Error {
  abstract getMetadata(): Metadata;
}
