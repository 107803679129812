import { PropsWithChildren, memo, useMemo } from 'react';
import { getTheme } from '@finanzcheck/teal-mui-theme';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import SaveNowBadgeProvider from '@finanzcheck/ti-shared-ui/components/ApplicationList/OfferWidgetView/SaveNowBadge/context';

import ToastProvider from '@finanzcheck/ti-shared-ui/context/Toast';
import UserProvider from 'components/Context/UserContext';
import DashboardProvider from 'components/Context/Dashboard';
import ApplicationListProvider from 'components/Context/ApplicationList';
import OperatorProvider from 'components/Context/Operator';
import { Body } from './Body';
import { GuestAuthenticationProvider } from 'components/Context/GuestAuthentication';
import { getMetaDataFromDomain } from '@finanzcheck/ti-shared-ui/utils/domain';
import ResumeProvider from '@finanzcheck/ti-shared-ui/context/Resume/index';
import HeaderStateProvider from '@finanzcheck/ti-shared-ui/context/HeaderState';
import { CoBrowse } from '../CoBrowse';
import { CoBrowseInstanceProvider } from '../Context/CoBrowse';
import { OptimizelyProvider } from '../Context/Optimizely';
import { TrackProvider } from 'components/Context/Track';
import { Dataplane } from 'components/Dataplane';
import { TenantProvider } from '@finanzcheck/teal-components';

export const MainTheme: React.FC<PropsWithChildren> = memo(({ children }) => {
  const { themeTenant } = getMetaDataFromDomain();

  const theme = useMemo(() => {
    const theme = getTheme(themeTenant);
    return theme;
  }, [themeTenant]);
  return (
    <ThemeProvider theme={theme}>
      <ResumeProvider>
        <CssBaseline />
        <ToastProvider>
          <UserProvider>
            <TenantProvider tenant={themeTenant}>
              <TrackProvider>
                <Dataplane>
                  <DashboardProvider>
                    <HeaderStateProvider>
                      <CoBrowseInstanceProvider>
                        <OptimizelyProvider>
                          <Body>
                            <CoBrowse />
                            <GuestAuthenticationProvider>
                              <ApplicationListProvider>
                                <SaveNowBadgeProvider>
                                  <OperatorProvider>
                                    {children}
                                  </OperatorProvider>
                                </SaveNowBadgeProvider>
                              </ApplicationListProvider>
                            </GuestAuthenticationProvider>
                          </Body>
                        </OptimizelyProvider>
                      </CoBrowseInstanceProvider>
                    </HeaderStateProvider>
                  </DashboardProvider>
                </Dataplane>
              </TrackProvider>
            </TenantProvider>
          </UserProvider>
        </ToastProvider>
      </ResumeProvider>
    </ThemeProvider>
  );
});
