import React, { FunctionComponent, useCallback } from 'react';
import { Typography, Box, Button, Container } from '@mui/material';
import { useHistory } from 'react-router';
import errorSvg from './error.svg';
import { Mail } from 'components/FormFields';
import { MagicWand } from 'components/UI/Icons/MagicWand';
import { useLogin } from 'components/Context/Login';
import { Controller, useFormContext } from 'react-hook-form';
import { UserAccount } from 'components/Login';
import { TrackingEvent } from '@finanzcheck/ti-shared-ui/utils/tracking/events.interface';
import useTrack from '@finanzcheck/ti-shared-ui/hooks/useTrack';
import { routes } from 'constants/routes';

interface Props {
  error: string;
  description: string;
}

export const GuestAuthenticationError: FunctionComponent<Props> = ({
  error,
  description,
}) => {
  const history = useHistory();
  const track = useTrack();
  const { rememberLoginEmail } = useLogin();
  const { handleSubmit, control } = useFormContext<UserAccount>();
  const loginUser = async (data: UserAccount) => {
    rememberLoginEmail(data.userLogin);
    history.push('/login/password');
  };

  const handleMagicLoginRequested = useCallback(
    (userLogin: UserAccount) => {
      track({
        event: TrackingEvent.OnClickMagicLoginSent,
        payload: {
          location: 'GuestAuthenticationError',
        },
      });
      history.push(routes.magicLoginSent, { email: userLogin.userLogin });
    },
    [history, track]
  );

  return (
    <Container maxWidth={'sm'}>
      <Box mb={3} mt={3} sx={{ display: 'flex', flexDirection: 'column' }}>
        <img src={`${errorSvg}`} height={120} alt={error} />
        <Typography variant="h5" align="center" fontWeight={500}>
          {error}
        </Typography>
        <br />
        <Typography align="center">{description}</Typography>
      </Box>
      <form onSubmit={handleSubmit(loginUser)}>
        <Controller
          control={control}
          name="userLogin"
          defaultValue=""
          rules={{
            required: true,
          }}
          render={({ field }) => (
            <Mail
              onChange={field.onChange}
              value={field.value}
              autoComplete="username"
              label="E-Mail-Adresse"
              inputRef={field.ref}
            />
          )}
        />
        <Button
          color="primary"
          variant="contained"
          fullWidth
          startIcon={<MagicWand />}
          sx={{
            my: 2,
          }}
          onClick={handleSubmit(handleMagicLoginRequested)}
        >
          Neuen Anmelde-Link senden
        </Button>
        <Button color="primary" fullWidth type="submit">
          oder Anmelden mit Passwort
        </Button>
      </form>
    </Container>
  );
};
