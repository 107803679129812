import { ErrorFallback } from 'ErrorFallback';
import { createRoot } from 'react-dom/client';
import { HomeApp } from './components/App';
import { initializeAmplify } from '@finanzcheck/ti-shared-ui/utils/amplify/amplify';
import * as Bugsnag from './utils/bugsnag';
import './polyfills';
import { Segment } from '@finanzcheck/ti-shared-ui/components/Segment';

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    tertiary: PaletteColor;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    tertiary: true;
  }
}

initializeAmplify();
const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <Bugsnag.ErrorBoundary FallbackComponent={ErrorFallback}>
    <HomeApp />
    <Segment />
  </Bugsnag.ErrorBoundary>
);
