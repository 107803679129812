import {
  createContext,
  FunctionComponent,
  PropsWithChildren,
  useContext,
  useReducer,
} from 'react';

import {
  saveNowBadgeReducer,
  initialSaveNowBadgeState,
  ISaveNowBadgeState,
} from './reducer';
import { SaveNowBadgeActions } from './actions';

interface ISaveNowBadgeDispatchContextType {
  dispatch: React.Dispatch<SaveNowBadgeActions>;
}

export const SaveNowBadgeDispatchContext =
  createContext<ISaveNowBadgeDispatchContextType>({
    dispatch: () => {
      throw new Error('SaveNowBadgeProvider is missing');
    },
  });

export const SaveNowBadgeStateContext = createContext<ISaveNowBadgeState>(
  initialSaveNowBadgeState
);

const SaveNowBadgeProvider: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(
    saveNowBadgeReducer,
    initialSaveNowBadgeState
  );

  return (
    <>
      <SaveNowBadgeDispatchContext.Provider value={{ dispatch }}>
        <SaveNowBadgeStateContext.Provider value={state}>
          {children}
        </SaveNowBadgeStateContext.Provider>
      </SaveNowBadgeDispatchContext.Provider>
    </>
  );
};

export default SaveNowBadgeProvider;

export const useSaveNowBadgeState = () => useContext(SaveNowBadgeStateContext);
export const useSaveNowBadgeDispatch = () =>
  useContext(SaveNowBadgeDispatchContext);
