import { useCallback, useState } from 'react';

import { TrackingEvent } from '@finanzcheck/ti-shared-ui/utils/tracking/events.interface';

import { ConsentDialog } from './ConsentDialog';
import { useCoBrowse } from '../../hooks/useCoBrowse';
import { ActiveSessionBarView } from './ActiveSessionBar';
import { ErrorDialogView } from './ErrorDialog';
import useTrack from '@finanzcheck/ti-shared-ui/hooks/useTrack';
import { SurveyDialog } from './SurveyDialog';

// TODO: Move it in to .env file, currently .env file is omitting the values contains special charters like &, [, ], = etc..
const SURVEY_URL =
  'https://www.surveymonkey.de/r/X8V5SG9?cuid=[cuid_value]&utm_campaign=[utm_campaign_value]&utm_source=[utm_source_value]&utm_medium=[utm_medium_value]&utm_term=[utm_term_value]&utm_content=[utm_content_value]';

export const CoBrowse: React.FC = () => {
  const [showConsentModal, setShowConsentModal] = useState<boolean>(false);
  const [showActiveSessionBar, setActiveSessionBar] = useState<boolean>(false);
  const [showSurveyDialog, setShowSurveyDialog] = useState(false);

  const {
    approveConsent,
    rejectConsent,
    onClickCurrentSessionEnd,
    isError,
    setIsError,
  } = useCoBrowse({
    onSessionConfirm: setShowConsentModal,
    onSessionEnd: setActiveSessionBar,
    onSessionEndFirstTime: setShowSurveyDialog,
  });

  const track = useTrack();

  const handleUserConsentAgree = useCallback(() => {
    track({
      event: TrackingEvent.OnClickSupportConsentDialogAgree,
    });
    setShowConsentModal(false);
    approveConsent?.(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setShowConsentModal, approveConsent]);

  const handleUserConsentDisAgree = useCallback(() => {
    track({
      event: TrackingEvent.OnClickSupportConsentDialogDisAgree,
    });
    setShowConsentModal(false);
    rejectConsent?.(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setShowConsentModal, rejectConsent]);

  const handleCloseErrorDialog = useCallback(() => {
    track({ event: TrackingEvent.OnGenericSupportErrorDialogClose });
    setIsError(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setIsError]);

  const handleCloseFeedbackDialog = useCallback(() => {
    setShowSurveyDialog(false);
    track({ event: TrackingEvent.OnClickCloseSurveyModal });
  }, [track]);

  const handleProceedWithSurvey = useCallback(() => {
    setShowSurveyDialog(false);
    window.open(SURVEY_URL);
    track({ event: TrackingEvent.OnClickProceedWithSurvey });
  }, [track]);

  return (
    <>
      <ConsentDialog
        open={showConsentModal}
        onUserConsentAgree={handleUserConsentAgree}
        onUserConsentDisAgree={handleUserConsentDisAgree}
      />
      {showActiveSessionBar && (
        <ActiveSessionBarView
          onClickCurrentSessionEnd={onClickCurrentSessionEnd}
        />
      )}
      <ErrorDialogView
        open={isError}
        onCloseErrorDialog={handleCloseErrorDialog}
      />
      <SurveyDialog
        open={showSurveyDialog}
        onClose={handleCloseFeedbackDialog}
        onClickProceedWithSurvey={handleProceedWithSurvey}
      />
    </>
  );
};
