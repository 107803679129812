import { getTenantConfigForCurrentDomain, TenantDomain } from '.';
import React from 'react';

export type IMultiTenantConfigMap<T> = {
  [key in TenantDomain]: T;
};

export const getMultiTenantConfigMap = <T,>(
  tenantConfigMap: IMultiTenantConfigMap<T>
) => {
  const tenant = getTenantConfigForCurrentDomain();

  if (!tenantConfigMap[tenant.domain]) {
    throw new Error(`Could not find multi tenant config for tenant ${tenant}`);
  }

  return tenantConfigMap[tenant.domain];
};

export const useMultiTenantConfigMap = <T,>(
  tenantConfigMap: IMultiTenantConfigMap<T>
) => {
  return getMultiTenantConfigMap(tenantConfigMap);
};

interface IMultiTenantComponentProps {
  componentMap: {
    [key in TenantDomain]: React.ReactElement | null;
  };
}
export const MultiTenantComponent: React.FC<IMultiTenantComponentProps> = ({
  componentMap,
}) => {
  const tenant = getTenantConfigForCurrentDomain();

  if (!componentMap[tenant.domain]) {
    throw new Error(
      `Could not find multi tenant component for tenant ${tenant}`
    );
  }

  return componentMap[tenant.domain];
};
