import { getStorage } from './storage';
import { ILoanRequest } from '../constants/interfaces/Metadata';
import { IApplication } from '../constants/interfaces/Application';

// call function only once per session
export const Once = <R>(
  sessionKey: string,
  callback: () => R
): R | false | undefined => {
  const storage = getStorage('sessionStorage');

  if (storage.getItem(sessionKey)) {
    return false;
  }

  storage.setItem(sessionKey, 'true');
  if (callback) {
    return callback();
  }
  return false;
};

function filterByKeys<T extends object>(obj: T, keys: Array<keyof T>): T {
  return Object.entries(obj).reduce((previous, [key, value]) => {
    if (keys.includes(key as keyof T)) {
      return previous;
    }

    return {
      ...previous,
      [key]: value,
    };
  }, {} as T);
}

const forbiddenApplicationKeys: Array<keyof IApplication> = ['id', 'dops'];

export function restrictApplication(app: IApplication): IApplication {
  return filterByKeys(app, forbiddenApplicationKeys);
}

const forbiddenLoanRequestKeys: Array<keyof ILoanRequest> = [
  'resumeHash',
  'debtors',
];

export function restrictLoanRequest(request: ILoanRequest): ILoanRequest {
  return filterByKeys(request, forbiddenLoanRequestKeys);
}
