import React, { FunctionComponent } from 'react';
import { Typography, Box, Button, Container } from '@mui/material';
import { DatePicker } from '../../DatePicker';
import { useHistory } from 'react-router';

interface IProps {
  loginError: undefined | string | React.ReactElement;
  loading: boolean;
  onDobChange: (dob: string) => Promise<void>;
  setLoginError: (error?: string | React.ReactElement) => void;
  lastName?: string;
  gender?: string;
}

export const GuestAuthentication: FunctionComponent<IProps> = ({
  loginError,
  loading,
  onDobChange,
  setLoginError,
  lastName,
  gender,
}) => {
  const history = useHistory();
  const name =
    !!gender &&
    !!lastName &&
    `${gender === 'male' ? 'Herr' : 'Frau'} ${lastName}`;
  return (
    <Container>
      <Box mb={3} mt={3}>
        <Typography variant="h5" align="center" fontWeight={500}>
          Willkommen zurück
          {name && `, ${name}`}!
        </Typography>
        <br />
        <Typography align="center">
          Mit der Eingabe des Geburtsdatums des 1. Kreditnehmers gelangen Sie in
          den Kundenbereich und erhalten so Zugriff auf Ihre Angebote.
        </Typography>
      </Box>
      <Box maxWidth={'sm'} minWidth="250px" m="0 auto" mt={2} mb={2}>
        <DatePicker
          onChange={setLoginError}
          onComplete={onDobChange}
          disabled={loading}
          externalError={loginError}
        />
      </Box>
      <Box maxWidth={'sm'} minWidth="250px" mt={3} mb={4} mr="auto" ml="auto">
        <Button
          color="primary"
          fullWidth
          onClick={(e) => {
            e.preventDefault();
            history.push('/login');
          }}
        >
          oder Anmelden mit Passwort
        </Button>
      </Box>
    </Container>
  );
};
