import { apiClient } from 'api';
import { IClientResponse } from '@finanzcheck/ti-shared-ui/constants/domain/client.interface';

export const clientAdapter =
  (clientUuid: string) => async (): Promise<IClientResponse> => {
    const response = await apiClient('clientDiscovery').get<IClientResponse>(
      `client-details?clientUuid=${clientUuid}`
    );

    return response.data;
  };
