import { setEnv } from '@finanzcheck/ti-shared-ui/utils/tenant';
import { Domain } from '@finanzcheck/ti-shared-ui/utils/domain';

export type Environment = 'live' | 'stage' | 'shared' | 'development';

interface IConfig {
  origin: string;
  environment: Environment;
  baseUrl: { [key in Domain]: string };
  contractInformationBankBlacklist: string[];
  idleTimeout: number;
  websiteName: {
    [key in Domain]: string;
  };
  websiteUrl: {
    [key in Domain]: string;
  };
  api: {
    home: {
      [key in Domain]: {
        host: string;
      };
    };
    clientDiscovery: {
      [key in Domain]: {
        host: string;
      };
    };
  };
  bugsnag: {
    apiKey: string;
  };
  cdn: {
    [key in Domain]: {
      host: string;
    };
  };
  amplify: {
    region: string;
  };
  documentUpload: {
    acceptedFileTypes: string;
    maxFileSize: number;
  };
  taurine: {
    [key in Domain]: {
      host: string;
      urlParams?: string;
    };
  };
  appointment: {
    [key in Domain]: {
      baseUrl: string;
      appointmentTypeId: string;
    };
  };
  passwordExpire: Domain[];
  optimizelySdkKey?: string;
  loginMigrateRetry: number;
  coBrowse: {
    licenceKey?: string;
  };
  loanAmountPossibility: {
    minimumLoanAmountPossible?: string;
    maximumLoanAmountPossible?: string;
  };
}

export const config: IConfig = {
  origin: import.meta.env.VITE_ORIGIN,
  environment: (import.meta.env.VITE_ENV as Environment) || 'live',
  websiteName: {
    finanzcheck: 'FINANZCHECK.de',
    smava: 'smava.de',
  },
  baseUrl: {
    finanzcheck: import.meta.env.VITE_BASE_URL_FC,
    smava: import.meta.env.VITE_BASE_URL_SM,
  },
  websiteUrl: {
    finanzcheck: import.meta.env.VITE_WEBSITE_URL_FC,
    smava: import.meta.env.VITE_WEBSITE_URL_SM,
  },
  contractInformationBankBlacklist:
    import.meta.env.VITE_CONTRACT_INFORMATION_BANK_BLACKLIST?.split(',') || [],
  idleTimeout:
    parseInt(import.meta.env.VITE_IDLE_LOGOUT_MINUTES, 10) * 1000 * 60,
  api: {
    home: {
      finanzcheck: { host: import.meta.env.VITE_HOME_API_URL_FC },
      smava: { host: import.meta.env.VITE_HOME_API_URL_SM },
    },
    clientDiscovery: {
      finanzcheck: {
        host: import.meta.env.VITE_CLIENT_DISCOVERY_SERVICE_HOST_FC,
      },
      smava: {
        host: import.meta.env.VITE_CLIENT_DISCOVERY_SERVICE_HOST_SM,
      },
    },
  },
  bugsnag: {
    apiKey: import.meta.env.VITE_BUGSNAG_API_KEY,
  },
  amplify: {
    region: import.meta.env.VITE_COGNITO_REGION,
  },
  documentUpload: {
    acceptedFileTypes: import.meta.env.VITE_DOCUMENT_UPLOAD_FILE_TYPES,
    maxFileSize: +import.meta.env.VITE_DOCUMENT_UPLOAD_MAX_FILE_SIZE,
  },
  taurine: {
    finanzcheck: {
      host: import.meta.env.VITE_TAURINE_BASE_URL_FC,
    },
    smava: {
      host: import.meta.env.VITE_TAURINE_BASE_URL_SM,
      urlParams: 'formConfig=ddf_smava',
    },
  },
  cdn: {
    finanzcheck: {
      host: import.meta.env.VITE_CDN_URL_FC,
    },
    smava: {
      host: import.meta.env.VITE_CDN_URL_SM,
    },
  },
  appointment: {
    finanzcheck: {
      baseUrl: import.meta.env.VITE_APPOINTMENT_BASE_URL_FC,
      appointmentTypeId: import.meta.env.VITE_APPOINTMENT_ID_FC,
    },
    smava: {
      baseUrl: import.meta.env.VITE_APPOINTMENT_BASE_URL_SM,
      appointmentTypeId: import.meta.env.VITE_APPOINTMENT_ID_SM,
    },
  },
  passwordExpire: import.meta.env.VITE_PASSWORD_EXPIRE_TENANTS
    ? (import.meta.env.VITE_PASSWORD_EXPIRE_TENANTS.split(',') as Domain[])
    : [],
  optimizelySdkKey: import.meta.env.OPTIMIZELY_SDK_KEY,
  loginMigrateRetry: parseInt(import.meta.env.VITE_MIGRATE_RETRY, 10),
  coBrowse: {
    licenceKey: import.meta.env.VITE_CO_BROWSE_LICENCE_KEY,
  },
  loanAmountPossibility: {
    minimumLoanAmountPossible: import.meta.env.VITE_MIN_LOAN_AMOUNT_POSSIBLE,
    maximumLoanAmountPossible: import.meta.env.VITE_MAX_LOAN_AMOUNT_POSSIBLE,
  },
};

setEnv(config.environment);
