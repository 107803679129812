import React, {
  createContext,
  FunctionComponent,
  useContext,
  useState,
  useEffect,
  PropsWithChildren,
} from 'react';
import { useIsMobile } from '../../hooks/useIsMobile';

interface IHeaderContext {
  isHeaderLoading: boolean;
  isLogoShown: boolean;
  setIsLogoShown: React.Dispatch<React.SetStateAction<boolean>>;
  setIsHeaderLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const HeaderContext = createContext<IHeaderContext | null>(null);

const HeaderStateProvider: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const isMobile = useIsMobile();
  const [isHeaderLoading, setIsHeaderLoading] = useState(false);
  const [isLogoShown, setIsLogoShown] = useState(true);

  useEffect(() => {
    if (!isMobile) {
      setIsLogoShown(true);
    }
  }, [isMobile]);

  return (
    <HeaderContext.Provider
      value={{
        isHeaderLoading,
        setIsHeaderLoading,
        isLogoShown,
        setIsLogoShown,
      }}
    >
      {children}
    </HeaderContext.Provider>
  );
};

export default HeaderStateProvider;

export const useHeaderState = () => {
  const context = useContext(HeaderContext);

  if (!context) {
    console.warn('please wrap into HeaderStateProvider to access header state');
    return {
      isHeaderLoading: false,
      isLogoShown: false,
      setIsLogoShown: () => undefined,
      setIsHeaderLoading: () => undefined,
    };
  }

  return context;
};
