import { FunctionComponent, memo, useCallback, useState } from 'react';

import { TrackingEvent } from '@finanzcheck/ti-shared-ui/utils/tracking/events.interface';

import { PasswordView, IProps as PasswordViewProps } from './Password';
import useTrack from '@finanzcheck/ti-shared-ui/hooks/useTrack';

type IProps = Omit<
  PasswordViewProps,
  'handleShowPassword' | 'isPasswordVisible'
>;

export const Password: FunctionComponent<IProps> = memo((props) => {
  const [isPasswordVisible, setPasswordVisible] = useState(false);
  const track = useTrack();

  const handleClickShowPassword = useCallback(() => {
    const isVisible = !isPasswordVisible;

    setPasswordVisible(isVisible);
    track({
      event: TrackingEvent.OnClickPasswordVisibility,
      payload: {
        isVisible,
      },
    });
  }, [isPasswordVisible, track]);

  return (
    <PasswordView
      isPasswordVisible={isPasswordVisible}
      handleShowPassword={handleClickShowPassword}
      {...props}
    />
  );
});
