import {
  IApplication,
  IIdentificationSignature,
} from '../constants/interfaces/Application';

export enum ApplicationInProgressStatus {
  IDENTITY_SIGNATURE_IDENTIFICATION_COMPLETED = 'identification_completed',
  IDENTITY_SIGNATURE_SIGNATURE_DROP = 'signature_drop',
  IDENTITY_SIGNATURE_SIGNATURE_COMPLETED = 'signature_completed',
  UNKNOWN = 'unknown',
  CLICKOUT_COMPLETED = 'clickout_completed',
}

export const isClickoutCompleted = (
  identificationSignatures?: IIdentificationSignature[]
) => {
  return (
    identificationSignatures &&
    identificationSignatures.length > 0 &&
    identificationSignatures?.every(
      (debtor: IIdentificationSignature) =>
        debtor.status === ApplicationInProgressStatus.CLICKOUT_COMPLETED
    )
  );
};

export const isSignatureCompleted = (
  identificationSignatures?: IIdentificationSignature[]
) => {
  return (
    identificationSignatures &&
    identificationSignatures.length > 0 &&
    identificationSignatures?.every(
      (debtor: IIdentificationSignature) =>
        debtor.status ===
        ApplicationInProgressStatus.IDENTITY_SIGNATURE_SIGNATURE_COMPLETED
    )
  );
};

export const isSignatureDrop = (
  identificationSignatures?: IIdentificationSignature[]
) => {
  const res =
    identificationSignatures &&
    identificationSignatures.length > 0 &&
    identificationSignatures?.some(
      (debtor: IIdentificationSignature) =>
        debtor.status ===
        ApplicationInProgressStatus.IDENTITY_SIGNATURE_SIGNATURE_DROP
    );
  return res;
};

export const isRed = (application?: IApplication) => {
  return (
    application?.progressStatus &&
    application?.progressStatus?.ciStatus === 'red'
  );
};

export const isDeclined = (application?: IApplication) => {
  return (
    application?.progressStatus &&
    application?.progressStatus?.ciStatus === 'declined'
  );
};
