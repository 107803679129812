/* eslint-disable no-param-reassign */
import { getStorage } from '@finanzcheck/ti-shared-ui/utils/storage';
import { getGuestToken } from 'adapter/guestLoginAdapter';
import { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';
import { getCurrentSession } from '@finanzcheck/ti-shared-ui/utils/amplify/amplify';
import { logMessage } from '@finanzcheck/ti-shared-ui/utils/log';
import { getCookie, setCookie } from '@finanzcheck/ti-shared-ui/utils/cookie';

interface RetryableAxiosInstance extends AxiosInstance {
  __isRetrying?: boolean;
}

interface RetryableAxiosConfig extends AxiosRequestConfig {
  __isRetry?: boolean;
}

interface QueuedRequest {
  process: () => void;
  reject: (error: AxiosError) => void;
}

const requestQueue: QueuedRequest[] = [];

function rejectQueue(error: AxiosError) {
  let request: QueuedRequest | undefined;
  // eslint-disable-next-line no-cond-assign
  while ((request = requestQueue.shift())) {
    request.reject(error);
  }
}

function processQueue() {
  let request: QueuedRequest | undefined;
  // eslint-disable-next-line no-cond-assign
  while ((request = requestQueue.shift())) {
    request.process();
  }
}

const isGuestLogin = () => {
  const guestAccessToken = getCookie('guestAccessToken');
  const guestRefreshToken = getCookie('guestRefreshToken');

  return guestAccessToken && guestRefreshToken;
};

export function refreshExpiredAccessToken(instance: RetryableAxiosInstance) {
  const interceptor = instance.interceptors.response.use(
    undefined,
    async (error: AxiosError) => {
      if (
        error.response?.status !== 401 ||
        (error.config as RetryableAxiosConfig).__isRetry
      ) {
        return Promise.reject(error);
      }

      if (instance.__isRetrying) {
        return new Promise((resolve, reject) => {
          requestQueue.push({
            process: () => {
              if (error.config) {
                resolve(instance.request(error.config)); //retry depending if we had a valid axios config before (e.g. request failed due to http bad status)
              } else {
                reject(new Error('Axios error.config is undefined'));
              }
            },
            reject: (err) => {
              reject(err);
            },
          });
        });
      }

      logMessage('Session expired, refreshing token...', 'warning');

      instance.__isRetrying = true;

      try {
        if (isGuestLogin()) {
          const guestRefreshToken = getCookie('guestRefreshToken');

          const dateOfBirth =
            getStorage('sessionStorage').getItem('dateOfBirth');

          if (guestRefreshToken && dateOfBirth) {
            const { token } = await getGuestToken(
              dateOfBirth,
              guestRefreshToken
            );
            setCookie(
              'guestAccessToken',
              token,
              parseInt(import.meta.env.VITE_GUEST_SESSION_TTLS_DAYS)
            );

            instance.defaults.headers['Authorization'] = `Bearer ${token}`;
          }
        } else {
          const session = await getCurrentSession();
          const accessToken = session.getIdToken().getJwtToken();

          instance.defaults.headers['Authorization'] = `Bearer ${accessToken}`;
        }

        return instance.request({
          ...error.config,
          __isRetry: true,
        } as RetryableAxiosConfig);
      } catch (retryError) {
        rejectQueue(retryError);
      } finally {
        instance.__isRetrying = false;
        processQueue();
      }
    }
  );

  return () => {
    instance.interceptors.response.eject(interceptor);
  };
}
