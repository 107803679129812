import { logError } from '@finanzcheck/ti-shared-ui/utils/log';
import { resumeAdapter } from 'adapter/resumeAdapter';
import { IApolloTraversal } from 'constants/domain/resume';
import { ITraversal } from 'constants/domain/traversal.interface';
import { getTaurineUrl } from 'constants/routes';
import { DeepPartial } from 'react-hook-form';
import { getTimestamp } from './date';

interface ResumeOptions {
  entryPoint?: string;
  traversal?: DeepPartial<IApolloTraversal>;
}

interface TraurineUrl {
  traversal?: ITraversal;
  taurineUrl: string;
}

export async function getResumeHash(options: ResumeOptions = {}, id?: string) {
  const url = await resumeAdapter(options.entryPoint, options.traversal, id);
  const params = new URLSearchParams(url.substring(url.indexOf('?')));
  const resumeHash = params.get('resume');

  if (!resumeHash) {
    return undefined;
  }

  return resumeHash;
}

export async function getResumeUrl(
  options?: ResumeOptions,
  traversalId?: string
) {
  try {
    return getTaurineUrl(await getResumeHash(options, traversalId));
  } catch (e) {
    logError(e);
    return getTaurineUrl();
  }
}

function sortTraversals(a: ITraversal, b: ITraversal) {
  return getTimestamp(a.createdAt) - getTimestamp(b.createdAt);
}

export function getOptimalTraversal(traversals: ITraversal[]) {
  const completeTraversals = traversals.filter(
    (traversal) => traversal.progress === 100
  );
  const consideredTraversals =
    completeTraversals.length > 0 ? completeTraversals : traversals;

  return consideredTraversals.sort(sortTraversals).pop();
}

export async function getOptimalResumeUrl(
  entryPoint?: string
): Promise<TraurineUrl> {
  return {
    taurineUrl: await getResumeUrl({ entryPoint }),
  };
}
