import React, { PropsWithChildren } from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

import { config } from '../constants/config';

import pkg from '../../package.json';

Bugsnag.start({
  apiKey: config.bugsnag.apiKey,
  plugins: [new BugsnagPluginReact()],
  appVersion: pkg.version,
  collectUserIp: false,
  releaseStage: config.environment,
  enabledReleaseStages: ['live', 'stage', 'shared'],
});

const bugsnagReactPlugin = Bugsnag.getPlugin('react');
if (!bugsnagReactPlugin) {
  throw new Error('Bugsnag: React plugin is missing');
}

export const ErrorBoundary: React.ComponentType<
  PropsWithChildren<{
    FallbackComponent?: React.ComponentType<{
      error: Error;
      info: React.ErrorInfo;
      clearError: () => void;
    }>;
  }>
> = bugsnagReactPlugin.createErrorBoundary(React);
