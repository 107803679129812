/* eslint-disable no-console */
import Bugsnag, { Event } from '@bugsnag/js';

import BugsnagError, { Metadata } from '../../errors/bugsnagError';

export class GenericEvent extends BugsnagError {
  payload?: Record<string, any>;

  constructor(
    message: string,
    name = 'GenericEvent',
    payload?: Record<string, any>
  ) {
    super(message);
    console.log('GenericEvent payload: ' + JSON.stringify(payload));
    this.name = name;
    this.payload = payload;
  }

  getMetadata(): Metadata {
    if (this.payload) {
      return {
        payload: this.payload,
      };
    }
    return {};
  }
}

type Severity = 'info' | 'warning' | 'error';

const errorLogger: Record<Severity, (message: string) => void> = {
  info: console.info,
  warning: console.warn,
  error: console.error,
};

export const logMessage = (message: string | undefined, severity: Severity) => {
  if (!message) {
    return;
  }
  const logger = errorLogger[severity] || errorLogger.error;
  logger(message);
};

export function logError(
  error: Error = new Error('Unknown Error'),
  severity: Severity = 'error'
) {
  Bugsnag.notify(error, (originalEvent: Event) => {
    originalEvent.severity = severity;

    if (error instanceof BugsnagError) {
      for (const [section, metadata] of Object.entries(error.getMetadata())) {
        originalEvent.addMetadata(section, metadata);
      }
    }
  });

  logMessage(error.message, severity);
}

export function logEvent(
  message: string,
  eventName?: string,
  metadata: Record<string, any> = {}
) {
  const err = new GenericEvent(message, eventName, metadata);
  const severity = 'info';
  Bugsnag.notify(err, (originalEvent: Event) => {
    originalEvent.severity = severity;
    const m = err.getMetadata();
    console.log('Log event: ' + JSON.stringify(m));
    for (const [section, metadata] of Object.entries(m)) {
      console.log(
        'Log event: ' + section + ' metadata:' + JSON.stringify(metadata)
      );
      originalEvent.addMetadata(section, metadata);
    }
  });

  logMessage(err.message, severity);
}
