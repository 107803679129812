import {
  createContext,
  useCallback,
  useContext,
  Dispatch,
  SetStateAction,
} from 'react';
import TagManager from 'react-gtm-module';

import { TrackingEvent } from '../utils/tracking/events.interface';
import { getFcId } from '../utils/cookie';
import { SignInState } from '../constants/interfaces/Session';
import { IApplication } from '../constants/interfaces/Application';
import { ILoanRequest } from '../constants/interfaces/Metadata';
import { GTMEvents } from '../constants/genericEvents';
import { emitterFunctions } from './useEventEmitter';
import { useClient } from '../components/Context/Client';

const team = 'players';

export interface ITrackOptions {
  event?: TrackingEvent | string;
  payload?: Record<string, unknown>;
  type?: 'page' | 'track' | 'identify';
  global?: boolean;
  legacyDestination?: string;
  context?: Record<string, unknown>;
  optimizely?: boolean;
}

interface ITrackContext {
  signInState: SignInState;
  advertisementId?: string;
  applicationUuid?: string;
  setApplicationUuid?: Dispatch<SetStateAction<string | undefined>>;
}

export const TrackContext = createContext<ITrackContext>({
  signInState: SignInState.UNKNOWN,
});

const generateGtmEvent = () => {
  const { gtmEvent } = emitterFunctions;
  return (
    gtmlEvent: GTMEvents,
    properties:
      | { application: IApplication }
      | { loanRequest: ILoanRequest }
      | { loanRequestId: string }
      | { action: string }
  ) => {
    gtmEvent && gtmEvent(gtmlEvent, properties);
  };
};

export type TrackFunction = (trackOptions: ITrackOptions) => boolean;

const useTrack = () => {
  const { project } = useClient();
  const { signInState, advertisementId, applicationUuid } =
    useContext(TrackContext);

  const track: TrackFunction = useCallback(
    (trackingOptions: ITrackOptions) => {
      const {
        event,
        payload,
        type = 'track',
        global,
        legacyDestination,
        context,
        optimizely,
      } = trackingOptions;
      const userId = getFcId();

      const eventPayload = {
        ...payload,
        ...(applicationUuid && { applicationUuid }),
        tealOrigin: project,
        authenticatedState: signInState,
        originUrl: window.location.origin,
        isMobile: window.innerWidth < 768,
      };

      let eventName = event;
      if (legacyDestination === 'gtm') {
        eventName = '/FinalUi' + eventName;
      }

      switch (type) {
        case 'track':
          window.analytics?.track(
            eventName,
            global ? eventPayload : { team, ...eventPayload },
            optimizely
              ? {
                  ...context,
                  Optimizely: { userId },
                  userAttributes: {
                    advertisementId: advertisementId,
                    ...Object.assign({}, context?.userAttributes),
                  },
                }
              : context
          );
          break;
        case 'page':
          window.analytics?.page(
            eventName,
            global ? eventPayload : { team, ...eventPayload }
          );
          break;
        case 'identify':
          window.analytics?.identify(
            eventName,
            global ? eventPayload : { team, ...eventPayload }
          );
          break;
        default:
          throw new Error('Unknown tracking type for segment');
      }
      switch (legacyDestination) {
        case 'taurine':
          TagManager.dataLayer({
            dataLayer: { event, ...eventPayload },
          });
          break;
        case 'gtm':
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          generateGtmEvent()(event as GTMEvents, eventPayload as any);
          break;
      }
      return true;
    },
    [applicationUuid, project, signInState, advertisementId]
  );
  return track;
};
export default useTrack;
