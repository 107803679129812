import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';

import { SurveyIcon } from './Icons/SurveyIcon';

interface IConsentDialogProps {
  open: boolean;
  onUserConsentAgree?: () => void;
  onUserConsentDisAgree?: () => void;
  onClickProceedWithSurvey: () => void;
  onClose?: () => void;
}

const CustomCloseIcon = styled(IconButton)({
  position: 'absolute',
  right: 8,
  top: 8,
});

export const SurveyDialog: React.FC<IConsentDialogProps> = ({
  open,
  onClose,
  onClickProceedWithSurvey,
}) => (
  <Dialog maxWidth="xs" open={open} onClose={onClose}>
    <CustomCloseIcon onClick={onClose}>
      <CloseIcon />
    </CustomCloseIcon>
    <Box pt={3} px={3}>
      <Box display="flex" justifyContent="center" pb={2}>
        <SurveyIcon />
      </Box>
      <DialogContent>
        <Box pb={1}>
          <Typography variant="h6">
            <b>Wir freuen uns über Ihr Feedback!</b>
          </Typography>
        </Box>
        <Typography variant="body2">
          Haben Sie eine Minute Zeit für uns? Teilen Sie uns Ihre Meinung zur
          Beratung mit.
        </Typography>
      </DialogContent>
      <DialogActions sx={{ pb: 4, px: 2 }}>
        <Button
          variant="contained"
          fullWidth
          onClick={onClickProceedWithSurvey}
        >
          Zur Umfrage
        </Button>
      </DialogActions>
    </Box>
  </Dialog>
);
