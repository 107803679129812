import List from '@mui/material/List';

import { DescriptionListItem } from './DescriptionListItem';
import { getCurrentTenantConfig } from '../../../utils/tenant';

const { domain } = getCurrentTenantConfig();

const descriptionListContent = [
  'Sie behalten jederzeit die Kontrolle über Ihren Bildschirm',
  <>
    Die Sitzung wird <b>nicht</b> aufgezeichnet
  </>,
  `Wir übertragen nur die Inhalte der ${domain}-Webseite`,
];

export const DescriptionList: React.FC = () => (
  <List sx={{ mt: 2, mx: 2 }}>
    {descriptionListContent.map((description, index) => (
      <DescriptionListItem key={index}>{description}</DescriptionListItem>
    ))}
  </List>
);
