interface Input {
  ruleKey: string;
  variationKey: string;
}

// https://docs.developers.optimizely.com/optimizely-data/docs/event-api-getting-started
interface DecisionEvent {
  campaign_id: string;
  experiment_id: string;
  variation_id: string;
}

export function getDecisionDetails<T>(
  parsedDatafile: Record<string, T>,
  input: Input
): DecisionEvent {
  const df = parsedDatafile;
  const result: DecisionEvent = {
    campaign_id: '',
    experiment_id: '',
    variation_id: '',
  };

  if (!df || !df.experiments || !Array.isArray(df.experiments)) {
    throw new Error('invalid config');
  }

  const experiment = df.experiments.find((e: any) => e.key === input.ruleKey);
  if (!experiment) {
    throw new Error('experiment does not exist');
  }
  result.experiment_id = experiment.id;
  result.campaign_id = experiment.layerId;

  if (!experiment.variations) {
    throw new Error('invalid experiment object');
  }
  const variation = (experiment.variations as Array<any>).find(
    (v) => v.key === input.variationKey
  );
  if (!variation) {
    throw new Error('variation not assigned to experiment');
  }
  result.variation_id = variation.id;
  return result;
}
