import { memo, useCallback, useState } from 'react';
import { Box } from '@mui/material';
import { useHistory } from 'react-router';
import { routes } from '../../constants/routes';
import { getOptimalResumeUrl } from '../../utils/traversal';

import { clearTaurineSession } from '../../utils/taurine';
import { useDashboardState } from '../Context/Dashboard';
import { HelpAndSupportView } from './HelpAndSupport';
import { useUserDispatch, useUserState } from 'components/Context/UserContext';
import { useIsLoggedIn } from 'hooks/useIsLoggedIn';
import { useClient } from '@finanzcheck/ti-shared-ui/components/Context/Client';
import { getCurrentTenantConfig } from '../../utils/tenant';
import { config } from '../../constants/config';
import {
  HeaderView,
  MenuItem,
} from '@finanzcheck/ti-shared-ui/components/Header/HeaderView';
import useTrack from '@finanzcheck/ti-shared-ui/hooks/useTrack';
import { Projects } from '@finanzcheck/ti-shared-ui/constants/domain/projects';
import { useHeaderState } from '@finanzcheck/ti-shared-ui/context/HeaderState';
import { TrackingEvent } from '@finanzcheck/ti-shared-ui/utils/tracking/events.interface';

export const NewHeader = memo(() => {
  const { logos } = useClient();
  const { domain } = getCurrentTenantConfig();
  const url = config.websiteUrl[domain];
  const history = useHistory();
  const [isHelpDrawerShown, setHelperDrawerShown] = useState(false);
  const { logOut } = useUserDispatch();
  const isLoggedIn = useIsLoggedIn(true);
  const { user } = useUserState();
  const track = useTrack();

  const { isLogoShown } = useHeaderState();
  const {
    state: { dashboard },
  } = useDashboardState();

  const handleNewLoanRequestClick = useCallback(async () => {
    const { taurineUrl, traversal } = await getOptimalResumeUrl('first');
    if (traversal) {
      track({ event: 'home_prefilled', payload: { origin: traversal.origin } });
    }

    clearTaurineSession();
    history.push(taurineUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboard?.traversals, history]);

  const handleMenuItemClickNew = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (menuItem: any) => {
      switch (menuItem) {
        case MenuItem.OVERVIEW: {
          history.push(routes.dashboard);
          return;
        }
        case MenuItem.NEW_LOAN_REQUEST: {
          await handleNewLoanRequestClick();
          return;
        }
        case MenuItem.DIGITAl_ACCOUNT_CHECK: {
          history.push(routes.accountCheck);
          return;
        }
        case MenuItem.SERVICE_CENTER: {
          setHelperDrawerShown(true);
          track({ event: TrackingEvent.HelpAndSupportMenuClick });
          return;
        }
        case MenuItem.ACCOUNT: {
          history.push(routes.login);
          return;
        }
        case MenuItem.LOGOUT: {
          logOut();
          return;
        }
        default:
          return null;
      }
    },
    [handleNewLoanRequestClick, history, logOut, track]
  );

  const handleCloseHelperDrawer = useCallback(() => {
    setHelperDrawerShown(false);
  }, []);

  return (
    <Box display="flex" flexDirection="column">
      <HeaderView
        project={Projects.HOME}
        logo={logos.client}
        url={url}
        isLoggedIn={isLoggedIn}
        showLogo={isLogoShown}
        user={{
          firstName: user.details.firstName || '',
          lastName: user.details.lastName || '',
          email: user.details.email || '',
        }}
        onMenuItemClick={handleMenuItemClickNew}
      />
      <HelpAndSupportView
        isHelpDrawerShown={isHelpDrawerShown}
        onCloseHelpDrawer={handleCloseHelperDrawer}
      />
    </Box>
  );
});
