import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import IconButton from '@mui/material/IconButton';

import { IStyles } from '../OfferPage.experiment.NewOfferPage/OfferCardDetails/types';

export interface ICloseButtonProps extends IStyles {
  onClick: () => void;
}

function CloseButton({ onClick, sx }: ICloseButtonProps) {
  return (
    <IconButton
      sx={{
        '&:hover': {
          background: 'white',
        },
        zIndex: 10,
        position: 'absolute',
        left: 16,
        top: 16,
        borderRadius: '8px',
        boxShadow: ' 0px 1px 12px rgba(115, 151, 193, 0.2)',
        width: 24,
        height: 24,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'white',
        ...sx,
      }}
      onClick={onClick}
    >
      <CloseRoundedIcon
        sx={{ fontSize: 18, color: (theme) => theme.palette.grey[800] }}
      />
    </IconButton>
  );
}

export default CloseButton;
