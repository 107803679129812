import { FunctionComponent, memo } from 'react';
import { Header } from '../Header';
import { Box, Divider, Grid, List, Typography } from '@mui/material';
import { ExitToApp as Logout } from '@mui/icons-material';
import { MenuItem } from './HeaderView';
import { Projects } from '../../constants/domain/projects';

interface IProps {
  user?: {
    firstName: string;
    lastName: string;
    email: string;
  };
  project?: Projects;
  onMenuItemClick?: (menuItem: MenuItem) => void;
  onRequestClose?: () => void;
}

export const AccountInfo: FunctionComponent<IProps> = memo(
  ({ onMenuItemClick, onRequestClose, user, project }) => {
    const onItemClick = (menuItem: MenuItem) => () => {
      onRequestClose?.();
      onMenuItemClick?.(menuItem);
    };
    return (
      <>
        {user && (
          <>
            <Box p={2}>
              <Grid container spacing={2}>
                <Grid item>
                  <Header.Avatar
                    alt={`${user.firstName} ${user.lastName}`}
                    firstName={user.firstName}
                    lastName={user.lastName}
                  />
                </Grid>
                <Grid item>
                  <Box
                    display="flex"
                    justifyContent="center"
                    flexDirection="column"
                    style={{ height: '100%' }}
                  >
                    <Typography variant="h6">
                      <b>
                        {user.firstName} {user.lastName}
                      </b>
                    </Typography>

                    <Typography>{user.email}</Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>

            <Divider />
          </>
        )}

        <Box p={2}>
          <List>
            <Header.MenuItem
              sx={{ whiteSpace: 'nowrap' }}
              onMenuItemClick={onItemClick(MenuItem.OVERVIEW)}
            >
              <Header.MenuItemLink>Meine Übersicht</Header.MenuItemLink>
            </Header.MenuItem>
            <Header.MenuItem
              sx={{ whiteSpace: 'nowrap' }}
              onMenuItemClick={onItemClick(MenuItem.DIGITAl_ACCOUNT_CHECK)}
            >
              <Header.MenuItemLink>Digitaler Kontoauszug</Header.MenuItemLink>
            </Header.MenuItem>
            {project === Projects.HOME && (
              <Header.MenuItem
                sx={{ whiteSpace: 'nowrap' }}
                onMenuItemClick={onItemClick(MenuItem.NEW_LOAN_REQUEST)}
              >
                <Header.MenuItemLink>Neuer Kreditvergleich</Header.MenuItemLink>
              </Header.MenuItem>
            )}
            <Header.MenuItem
              sx={{ whiteSpace: 'nowrap' }}
              onMenuItemClick={onItemClick(MenuItem.LOGOUT)}
            >
              <Header.MenuListItemIcon>
                <Logout style={{ fill: 'inherit' }} />
              </Header.MenuListItemIcon>
              <Header.MenuItemLink>Abmelden</Header.MenuItemLink>
            </Header.MenuItem>
          </List>
        </Box>
      </>
    );
  }
);
