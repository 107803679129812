import {
  createContext,
  FunctionComponent,
  PropsWithChildren,
  useContext,
  useReducer,
} from 'react';

import {
  OfferDetailsReducer,
  initialOfferDetailsState,
  IOfferDetailsState,
} from './reducer';
import { OfferDetailsActions } from './actions';

interface IOfferDetailsDispatchContextType {
  dispatch: React.Dispatch<OfferDetailsActions>;
}

export const OfferDetailsDispatchContext =
  createContext<IOfferDetailsDispatchContextType>({
    dispatch: () => {
      throw new Error('OfferDetailsProvider is missing');
    },
  });

export const OfferDetailsStateContext = createContext<IOfferDetailsState>(
  initialOfferDetailsState
);

const OfferDetailsProvider: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(
    OfferDetailsReducer,
    initialOfferDetailsState
  );

  return (
    <OfferDetailsDispatchContext.Provider value={{ dispatch }}>
      <OfferDetailsStateContext.Provider value={state}>
        {children}
      </OfferDetailsStateContext.Provider>
    </OfferDetailsDispatchContext.Provider>
  );
};

export default OfferDetailsProvider;

export const useOfferDetailsState = () => useContext(OfferDetailsStateContext);
export const useOfferDetailsDispatch = () =>
  useContext(OfferDetailsDispatchContext);
