import { IRequestInitiatedFrom } from '../../reducers/resume';

export enum ResumeStateActionTypes {
  RESUME_CALL_START = 'RESUME_CALL_START',
  RESUME_CALL_SUCCESS = 'RESUME_CALL_SUCCESS',
  RESUME_CALL_FAIL = 'RESUME_CALL_FAIL',
  RESUME_CALL_RESET = 'RESUME_CALL_RESET',
}

interface IResumeCallStart {
  type: ResumeStateActionTypes.RESUME_CALL_START;
  payload: IRequestInitiatedFrom;
}

interface IResumeCallSuccessful {
  type: ResumeStateActionTypes.RESUME_CALL_SUCCESS;
  payload: IRequestInitiatedFrom;
}

interface IResumeCallFail {
  type: ResumeStateActionTypes.RESUME_CALL_FAIL;
  payload: IRequestInitiatedFrom;
}

interface IResumeCallReset {
  type: ResumeStateActionTypes.RESUME_CALL_RESET;
  payload: IRequestInitiatedFrom;
}

export type ResumeStateActions =
  | IResumeCallSuccessful
  | IResumeCallFail
  | IResumeCallStart
  | IResumeCallReset;
