import { SignInState } from '@finanzcheck/ti-shared-ui/constants/interfaces/Session';
import { useUserState } from 'components/Context/UserContext';

export function useIsLoggedIn(allowGuest = false) {
  const {
    user: { signInState },
  } = useUserState();

  return (
    signInState === SignInState.SIGNED_IN ||
    (allowGuest && signInState === SignInState.GUEST)
  );
}

export function useIsLoggedInAsGuest() {
  const {
    user: { signInState },
  } = useUserState();

  return signInState === SignInState.GUEST;
}
