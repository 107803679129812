import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import DoneIcon from '@mui/icons-material/Done';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import { PropsWithChildren } from 'react';

const DoneBox = styled(Box)({
  borderRadius: '9px',
  display: 'flex',
  width: '24px',
  height: '24px',
  justifyContent: 'center',
});

const CustomDoneIcon = styled(DoneIcon)({
  width: '14px',
  height: 'auto',
});

const CustomListItem = styled(ListItem)({
  alignItems: 'flex-start',
});

export const DescriptionListItem: React.FC<PropsWithChildren> = ({
  children,
}) => (
  <CustomListItem>
    <DoneBox
      sx={(theme) => ({
        backgroundColor: 'primary.light',
        padding: theme.spacing(1),
        marginRight: theme.spacing(2),
      })}
    >
      <CustomDoneIcon />
    </DoneBox>
    <Typography variant="body2">{children}</Typography>
  </CustomListItem>
);
