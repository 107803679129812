import { getDevTenant, setDevTenant } from '../../utils/dev';
import { TenantDomain, tenantDomainMap } from '../../utils/tenant';
import { FunctionComponent, memo } from 'react';

export const Dev: FunctionComponent = memo(() => {
  if (import.meta.env.MODE !== 'development') {
    return null;
  }

  return (
    <form>
      {Object.keys(tenantDomainMap).map((tenant) => (
        <label key={tenant} htmlFor={'debug-tenant' + tenant}>
          <input
            type="radio"
            name="tenant"
            value={tenant}
            id={'debug-tenant' + tenant}
            checked={getDevTenant() === tenant}
            onChange={(e) => {
              setDevTenant(e.currentTarget.value as TenantDomain);
              window.location.reload();
            }}
          />
          {tenant}
        </label>
      ))}
    </form>
  );
});
