export class ComponentImportError extends Error {
  route: string;

  constructor(route: string) {
    super('Unable to import the component');

    this.name = 'ComponentImportError';
    this.route = route;
  }

  getMetadata() {
    return {
      component: {
        route: this.route,
      },
    };
  }
}
