import {
  createContext,
  useContext,
  memo,
  useReducer,
  useCallback,
  useMemo,
  ReactNode,
  FC,
} from 'react';
import { ToastContainer } from '../../components/ToastContainer';
import {
  initialToastState,
  IToastMessage,
  IToastState,
  toastStateReducer,
} from '../../reducers/toast';
import { ToastStateActionTypes } from '../../actions/toast';

const ToastStateContext = createContext<IToastState>(initialToastState);

function throwMissingProvider() {
  throw new Error('ToastProvider is missing');
}

interface IToastProviderProps {
  children: ReactNode;
}
interface ToastDispatchContextType {
  queueMessage: (message: IToastMessage) => void;
  removeMessage: (key: string) => void;
}

const ToastDispatchContext = createContext<ToastDispatchContextType>({
  queueMessage: throwMissingProvider,
  removeMessage: throwMissingProvider,
});

const ToastProvider: FC<IToastProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(toastStateReducer, initialToastState);

  const queueMessage = useCallback(
    (message: IToastMessage) => {
      dispatch({
        type: ToastStateActionTypes.QUEUE_MESSAGE,
        message,
      });
    },
    [dispatch]
  );

  const removeMessage = useCallback(
    (key: string) => {
      dispatch({
        type: ToastStateActionTypes.REMOVE_MESSAGE,
        key,
      });
    },
    [dispatch]
  );

  const handlePop = useCallback(() => {
    dispatch({
      type: ToastStateActionTypes.POP_MESSAGE,
    });
  }, [dispatch]);

  const currentMessage = useMemo(() => {
    if (state.messages.length === 0) {
      return undefined;
    }

    return state.messages[0];
  }, [state.messages]);

  return (
    <ToastDispatchContext.Provider value={{ queueMessage, removeMessage }}>
      <ToastStateContext.Provider value={state}>
        {currentMessage && (
          <ToastContainer message={currentMessage} onRemove={handlePop} />
        )}
        {children}
      </ToastStateContext.Provider>
    </ToastDispatchContext.Provider>
  );
};

export default memo(ToastProvider);

export const useToastState = () => useContext(ToastStateContext);
export const useToastDispatch = () => useContext(ToastDispatchContext);
