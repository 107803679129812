import { Action } from '@finanzcheck/catalyst-pollard';
import { getStorage } from '@finanzcheck/ti-shared-ui/utils/storage';

const IMOD_DATA_LOCAL_LS_KEY = 'IMOD_CURRENT_TRAVERSAL_DATA';
const IMOD_CURRENT_NAVIGATION_SS_KEY = 'IMOD_CURRENT_NAVIGATION';

export function clearTaurineSession() {
  getStorage('sessionStorage').removeItem(IMOD_CURRENT_NAVIGATION_SS_KEY);
  getStorage('localStorage').removeItem(IMOD_DATA_LOCAL_LS_KEY);
}

interface IPayload {
  service: string | undefined;
}

const getTenantSpecificConsentPayload = <T extends IPayload>(payload: T) => {
  return (window as any).OnetrustActiveGroups || payload?.service;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const actionMap: Record<string, (action: Action<string>) => Action<string>> = {
  cookieConsentRequest: ({ type, payload }) => ({
    type,
    payload: getTenantSpecificConsentPayload(payload),
  }),
};

export function taurineActionHandler<T>(action: Action<string, T>) {
  const handler = actionMap[action.type];
  if (!handler) {
    return action;
  }

  return handler(action);
}
