import { useUserDispatch, useUserState } from 'components/Context/UserContext';
import { useIsLoggedIn } from 'hooks/useIsLoggedIn';
import { FunctionComponent, memo, useCallback } from 'react';
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';

import { routes } from '../../../constants/routes';
import { RouteLoader } from '../RouteLoader';
import { useIdleTimer } from '../../../hooks/useIdleTimer';
import { config } from '../../../constants/config';
import { SignInState } from '@finanzcheck/ti-shared-ui/constants/interfaces/Session';

export const AuthorizedRoute: FunctionComponent<
  RouteProps & { allowGuest?: boolean }
> = memo(({ children, allowGuest, ...rest }) => {
  const isLoggedIn = useIsLoggedIn(allowGuest);
  const {
    user: { signInState },
  } = useUserState();
  const { idleLogout } = useUserDispatch();
  const location = useLocation();

  const onIdlehandler = useCallback(() => {
    if (isLoggedIn) {
      idleLogout();
    }
  }, [isLoggedIn, idleLogout]);

  useIdleTimer(config.idleTimeout, onIdlehandler);

  if (signInState === SignInState.UNKNOWN) {
    return <RouteLoader />;
  }

  if (!isLoggedIn) {
    return <Redirect to={`${routes.login}${location.search}`} />;
  }

  return <Route {...rest}>{children}</Route>;
});

AuthorizedRoute.displayName = 'AuthorizedRoute';
