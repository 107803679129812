import { FunctionComponent, memo } from 'react';
import { TealFooterUnified } from '@finanzcheck/teal-components';
import { getCurrentTenantConfig } from '../../utils/tenant';
import { useRegistrationRouteBasePath } from '../Context/Client/useClientDomain';
import { useTheme } from '@mui/material';
import { Dev } from '../Dev';
import { config } from '../../constants/config';

export const Footer: FunctionComponent = memo(() => {
  const { domain } = getCurrentTenantConfig();
  const theme = useTheme();
  return (
    <footer style={{ backgroundColor: theme.palette.common.white }}>
      <Dev />
      <TealFooterUnified
        oneTrustHandler={() => {
          (window as any).OneTrust &&
            (window as any).OneTrust.ToggleInfoDisplay();
        }}
        origin={domain}
        footerLinks={{
          agb: `${config.websiteUrl}/agb`,
          impressum: `${config.websiteUrl}/impressum`,
          datenschutz: `${config.websiteUrl}/datenschutz`,
        }}
        logoLink={useRegistrationRouteBasePath()}
      />
    </footer>
  );
});
