import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import { memo, MouseEvent, TouchEvent, useCallback } from 'react';

interface Props {
  onClick: (
    event: MouseEvent<HTMLButtonElement> | TouchEvent<HTMLButtonElement>
  ) => void;
}

export const TooltipButton = memo(({ onClick }: Props) => {
  const handleMouseDown = useCallback(
    (event: TouchEvent<HTMLButtonElement> | MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      event.preventDefault();
      onClick(event);
    },
    [onClick]
  );

  return (
    <IconButton
      sx={(theme) => ({
        color: 'rgba(113, 128, 150, 0.7)',
        alignSelf: 'start',
        marginTop: theme.spacing(0.5),
      })}
      aria-label="toggle tooltip"
      onTouchEnd={handleMouseDown}
      onMouseDown={handleMouseDown}
    >
      <InfoIcon />
    </IconButton>
  );
});
