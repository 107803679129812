import { getDevTenant } from '../utils/dev';
import { Environment } from '../utils/environment';
import { TenantDomain } from '../utils/tenant';

export interface IApiConfig {
  kronos: {
    host: string;
    limit: number;
    interval: number;
  };
  taurine: {
    host: string;
    secondDebtorAnchor: { finanzcheck: string; smava: string };
  };
  paas: {
    url: string;
  };
  postident: {
    host: string;
  };
  ekomi: {
    baseUrl: string;
  };
  clientDiscoveryService: {
    host: string;
  };
  home: {
    host: string;
  };
  website: {
    host: string;
  };
}

export interface IDomainConfig {
  api: IApiConfig;
  clientHostUrl: string;
  clientTermsAndConditionsUrl: string;
  reactAppApiInitialUrl: string;
  offerPageUrl: string;
  homePageUrl: string;
  websiteUrl: string;
  hubbleUrl: string;
}

interface IApplicationUrls {
  base: string;
  clientBase: string;
  kronos: string;
  pass: string;
  taurine: string;
  postident: string;
  clientDiscovery: string;
  website: string;
  cdn: string;
  offers: string;
  home: string;
  homeFrontend: string;
  hubbleUrl: string;
}

const finanzcheckConfig: Record<Environment, IApplicationUrls> = {
  development: {
    clientBase: 'https://final-ui.finanzcheck-stage.de',
    base: 'https://final-ui.finanzcheck-stage.de',
    kronos: 'https://ti-kronos.finanzcheck-stage.de',
    pass: 'https://paas.finanzcheck-stage.de',
    taurine: 'https://taurine.finanzcheck-stage.de',
    postident: 'https://ti-postident.finanzcheck-stage.de',
    clientDiscovery: 'https://ti-client-discovery.finanzcheck-stage.de',
    website: 'https://website.finanzcheck-stage.de',
    cdn: 'https://cdn.finanzcheck-stage.de',
    offers: 'https://offers.finanzcheck-stage.de',
    home: 'https://ti-home.finanzcheck-stage.de',
    homeFrontend: 'https://home.finanzcheck-stage.de',
    hubbleUrl: 'https://hubble.finanzcheck-stage.de/v1',
  },
  shared: {
    clientBase: 'https://final-ui.finanzcheck-shared.de',
    base: 'https://final-ui.finanzcheck-shared.de',
    kronos: 'https://ti-kronos.finanzcheck-shared.de',
    pass: 'https://paas.finanzcheck-shared.de',
    taurine: 'https://taurine.finanzcheck-shared.de',
    postident: 'https://ti-postident.finanzcheck-shared.de',
    clientDiscovery: 'https://ti-client-discovery.int.finanzcheck-shared.de',
    website: 'https://website.finanzcheck-shared.de',
    cdn: 'https://cdn.finanzcheck-shared.de',
    offers: 'https://offers.finanzcheck-shared.de',
    home: 'https://ti-home.finanzcheck-shared.de',
    homeFrontend: 'https://home.finanzcheck-shared.de',
    hubbleUrl: 'https://hubble.finanzcheck-shared.de/v1',
  },
  stage: {
    clientBase: 'https://final-ui.finanzcheck-stage.de',
    base: 'https://final-ui.finanzcheck-stage.de',
    kronos: 'https://ti-kronos.finanzcheck-stage.de',
    pass: 'https://paas.finanzcheck-stage.de',
    taurine: 'https://taurine.finanzcheck-stage.de',
    postident: 'https://ti-postident.finanzcheck-stage.de',
    clientDiscovery: 'https://ti-client-discovery.finanzcheck-stage.de',
    website: 'https://website.finanzcheck-stage.de',
    cdn: 'https://cdn.finanzcheck-stage.de',
    offers: 'https://offers.finanzcheck-stage.de',
    home: 'https://ti-home.finanzcheck-stage.de',
    homeFrontend: 'https://home.finanzcheck-stage.de',
    hubbleUrl: 'https://hubble.finanzcheck-stage.de/v1',
  },
  live: {
    clientBase: 'https://final-ui.finanzcheck.de',
    base: 'https://final-ui.finanzcheck.de',
    kronos: 'https://ti-kronos.finanzcheck.de',
    pass: 'https://paas.finanzcheck.de',
    taurine: 'https://taurine.finanzcheck.de',
    postident: 'https://ti-postident.finanzcheck.de',
    clientDiscovery: 'https://ti-client-discovery.finanzcheck.de',
    website: 'https://www.finanzcheck.de',
    cdn: 'https://cdn.finanzcheck.de',
    offers: 'https://offers.finanzcheck.de',
    home: 'https://ti-home.finanzcheck.de',
    homeFrontend: 'https://home.finanzcheck.de',
    hubbleUrl: 'https://hubble.finanzcheck.de/v1',
  },
};

const smavaConfig: Record<Environment, IApplicationUrls> = {
  development: {
    clientBase: 'https://final-ui.smava-stage.de',
    base: 'https://final-ui.smava-stage.de',
    kronos: 'https://ti-kronos.smava-stage.de',
    pass: 'https://paas.smava-stage.de',
    taurine: 'https://taurine.smava-stage.de',
    postident: 'https://ti-postident.smava-stage.de',
    clientDiscovery: 'https://ti-client-discovery.smava-stage.de',
    website: 'https://website.smava-stage.de',
    cdn: 'https://cdn.smava-stage.de',
    offers: 'https://offers.smava-stage.de',
    home: 'https://ti-home.smava-stage.de',
    homeFrontend: 'https://home.smava-stage.de',
    hubbleUrl: 'https://hubble.smava-stage.de/v1',
  },
  shared: {
    clientBase: 'https://final-ui.smava-shared.de',
    base: 'https://final-ui.smava-shared.de',
    kronos: 'https://ti-kronos.smava-shared.de',
    pass: 'https://paas.smava-shared.de',
    taurine: 'https://taurine.smava-shared.de',
    postident: 'https://ti-postident.smava-shared.de',
    clientDiscovery: 'https://ti-client-discovery.int.finanzcheck-shared.de',
    website: 'https://website.smava-shared.de',
    cdn: 'https://cdn.smava-shared.de',
    offers: 'https://offers.smava-shared.de',
    home: 'https://ti-home.smava-shared.de',
    homeFrontend: 'https://home.smava-shared.de',
    hubbleUrl: 'https://hubble.smava-shared.de/v1',
  },
  stage: {
    clientBase: 'https://final-ui.smava-stage.de',
    base: 'https://final-ui.smava-stage.de',
    kronos: 'https://ti-kronos.smava-stage.de',
    pass: 'https://paas.smava-stage.de',
    taurine: 'https://taurine.smava-stage.de',
    postident: 'https://ti-postident.smava-stage.de',
    clientDiscovery: 'https://ti-client-discovery.smava-stage.de',
    website: 'https://website.smava-stage.de',
    cdn: 'https://cdn.smava-stage.de',
    offers: 'https://offers.smava-stage.de',
    home: 'https://ti-home.smava-stage.de',
    homeFrontend: 'https://home.smava-stage.de',
    hubbleUrl: 'https://hubble.smava-stage.de/v1',
  },
  live: {
    clientBase: 'https://final-ui.smava.de',
    base: 'https://final-ui.smava.de',
    kronos: 'https://ti-kronos.smava.de',
    pass: 'https://paas.smava.de',
    taurine: 'https://taurine.smava.de',
    postident: 'https://ti-postident.smava.de',
    clientDiscovery: 'https://ti-client-discovery.smava.de',
    website: 'https://www.smava.de',
    cdn: 'https://cdn.smava.de',
    offers: 'https://offers.smava.de',
    home: 'https://ti-home.smava.de',
    homeFrontend: 'https://home.smava.de',
    hubbleUrl: 'https://hubble.smava.de/v1',
  },
};

const clientConfig = {
  smava: {
    config: smavaConfig,
  },
  finanzcheck: {
    config: finanzcheckConfig,
  },
};

const getUrls = (environment: Environment): IApplicationUrls => {
  const { origin, hostname } = window.location;
  const matchedDomain = Object.keys(clientConfig).find((domain) =>
    origin.includes(domain)
  );
  if (!matchedDomain) {
    if (hostname === 'localhost') {
      if (getDevTenant() && clientConfig[getDevTenant() as TenantDomain]) {
        return clientConfig[getDevTenant() as TenantDomain].config[environment];
      }
      return clientConfig.finanzcheck.config[environment];
    }
    throw new Error(`final ui could not find api config for ${origin}`);
  }

  return clientConfig[matchedDomain as TenantDomain].config[environment];
};

const cache = {};

export const getDomainConfig = (environment: Environment): IDomainConfig => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const cachedConfig = cache['domainConfig'];
  if (cachedConfig) {
    return cachedConfig;
  }

  const domainUrls = getUrls(environment);

  const domainConfig = {
    clientHostUrl: domainUrls.clientBase,
    clientTermsAndConditionsUrl: `${domainUrls.website}/uber-uns/agb/`,
    reactAppApiInitialUrl: `${domainUrls.kronos}/v1/final`,
    offerPageUrl: domainUrls.offers,
    homePageUrl: domainUrls.homeFrontend,
    hubbleUrl: domainUrls.hubbleUrl,
    websiteUrl: domainUrls.website,
    api: {
      ekomi: {
        baseUrl: `${domainUrls.offers}/`,
      },
      kronos: {
        host: `${domainUrls.kronos}/v1`,
        limit: parseInt(import.meta.env.VITE_API_KRONOS_POLL_LIMIT, 10),
        interval: parseInt(import.meta.env.VITE_API_KRONOS_POL_INTERVAL, 10),
      },
      taurine: {
        host: `${domainUrls.taurine}/`,
        secondDebtorAnchor: {
          finanzcheck: import.meta.env
            .VITE_TAURINE_SECOND_DEBTOR_DOMAINCONFIG_FC,
          smava: import.meta.env.VITE_TAURINE_SECOND_DEBTOR_DOMAINCONFIG_SM,
        },
      },
      paas: {
        url: `${domainUrls.pass}/v2/prefill`,
      },
      postident: {
        host: `${domainUrls.postident}/api/v1`,
      },
      clientDiscoveryService: {
        host: `${domainUrls.clientDiscovery}/v1`,
      },
      home: {
        host: `${domainUrls.home}/api/v1`,
      },
      website: {
        host: domainUrls.website,
      },
    },
  };
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  cache['domainConfig'] = domainConfig;
  return domainConfig;
};
