import { IApplication } from '../../../../constants/interfaces/Application';

export enum OfferDetailsActionTypes {
  SET_VIEWING_OFFER = 'SET_VIEWING_OFFER',
  SHOW_LOADER = 'SHOW_LOADER',
  HIDE_LOADER = 'HIDE_LOADER',
}

export type OfferDetailsActions =
  | IOfferDetailsSetViewingOfferActionType
  | IOfferDetailsShowLoaderActionType
  | IOfferDetailsHideLoaderActionType;

interface IOfferDetailsSetViewingOfferActionType {
  type: OfferDetailsActionTypes.SET_VIEWING_OFFER;
  offer: IApplication | null;
}

interface IOfferDetailsShowLoaderActionType {
  type: OfferDetailsActionTypes.SHOW_LOADER;
}

interface IOfferDetailsHideLoaderActionType {
  type: OfferDetailsActionTypes.HIDE_LOADER;
}
