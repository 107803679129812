import { Tenant } from '@finanzcheck/teal-mui-theme';
export enum TenantUuid {
  Smava = 'f9343d8a-4bd3-48b8-a504-e99f102f463c',
  FinanzcheckShared = '2245defb-94aa-4c24-9bb1-ce9c06e097f8',
  FinanzcheckStage = '744e3a15-6238-4c2e-9c39-8411cf2b6a1b',
  FinanzcheckLive = 'de0b41bd-175b-4b13-adf8-eef7450bf698',
}

export const getTenantForClientUuid = (clientUuid: string) => {
  switch (clientUuid) {
    case TenantUuid.Smava:
      return Tenant.Smava;
    case TenantUuid.FinanzcheckShared:
    case TenantUuid.FinanzcheckStage:
    case TenantUuid.FinanzcheckLive:
      return Tenant.Finanzcheck;
  }

  throw new Error('ClientUuid ' + clientUuid + ' is not matching a tenant');
};
