import { TenantDomain } from '@finanzcheck/ti-shared-ui/utils/tenant';
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { config } from 'constants/config';
import { getCurrentTenantConfig } from 'utils/tenant';
import { IClientName } from './api.interface';
import { refreshExpiredAccessToken } from './refreshExpiredAccessToken';

const DEFAULT_HEADERS = {
  'Content-type': 'application/json; charset=utf-8',
};

const getClientConfig = (
  domain: TenantDomain
): Record<IClientName, AxiosRequestConfig> => ({
  home: {
    baseURL: config.api.home[domain].host,
    withCredentials: true,
    headers: DEFAULT_HEADERS,
  },
  clientDiscovery: {
    baseURL: config.api.clientDiscovery[domain].host,
  },
});

const axiosInstances: Partial<Record<IClientName, AxiosInstance>> = {};

function createInstance(name: IClientName) {
  const { domain } = getCurrentTenantConfig();
  const clientConfig = getClientConfig(domain);
  const instance = axios.create(clientConfig[name]);

  if (name === 'home') {
    refreshExpiredAccessToken(instance);
  }

  axiosInstances[name] = instance;

  return instance;
}

export function apiClient(name: IClientName) {
  const instance = axiosInstances[name];
  if (instance) {
    return instance;
  }

  return createInstance(name);
}
