import { IApplicationOverviewListInterface } from '../../constants/domain/application-overview-list.interface';
import {
  ApplicationListStateActions,
  ApplicationListStateActionTypes,
} from '../../actions/applicationList';
import { IApplication } from '@finanzcheck/ti-shared-ui/constants/interfaces/Application';
import {
  isDeclined,
  isRed,
} from '@finanzcheck/ti-shared-ui/utils/applicationStatus';

interface ILoadingDetailedApplicationState {
  isLoading: true;
  error: null;
  application: null;
  iban?: string;
}

interface IErroredDetailedApplicationState {
  isLoading: false;
  error: string;
  application: null;
  iban?: string;
}

interface ILoadedDetailedApplicationState {
  isLoading: false;
  error: null;
  application: IApplication;
  iban?: string;
}

export type IDetailedApplicationState =
  | ILoadingDetailedApplicationState
  | IErroredDetailedApplicationState
  | ILoadedDetailedApplicationState;

export type DetailedApplicationType = {
  [key: string]: IDetailedApplicationState;
};

export interface IApplicationListState {
  traversal: IApplicationOverviewListInterface | null;
  applications: IApplication[];
  deviantApplications: IApplication[];
  detailedApplication: DetailedApplicationType;
  error: string | null;
  isLoading: boolean;
  noAlternativeOffersSection?: boolean;
}

export const initialApplicationListState: IApplicationListState = {
  traversal: null,
  applications: [],
  deviantApplications: [],
  detailedApplication: {},
  error: null,
  isLoading: false,
};

export const applicationListReducer = (
  state: IApplicationListState,
  action: ApplicationListStateActions
): IApplicationListState => {
  switch (action.type) {
    case ApplicationListStateActionTypes.APPLICATIONLIST_LOAD_START: {
      return {
        ...state,
        error: null,
        traversal: null,
        applications: [],
        deviantApplications: [],
        isLoading: true,
      };
    }

    case ApplicationListStateActionTypes.APPLICATIONLIST_LOAD_SUCCESSFUL: {
      return {
        ...state,
        traversal: action.traversal,
        applications: action.traversal.applications.filter(
          (app) => !app.deviantAmount || state.noAlternativeOffersSection
        ),
        deviantApplications: action.traversal.applications.filter(
          (app) =>
            !state.noAlternativeOffersSection &&
            app.deviantAmount &&
            !isRed(app) &&
            !isDeclined(app)
        ),
        error: null,
        isLoading: false,
      };
    }

    case ApplicationListStateActionTypes.APPLICATIONLIST_LOAD_ERROR: {
      return {
        ...state,
        traversal: null,
        error: action.error,
        isLoading: false,
      };
    }

    case ApplicationListStateActionTypes.APPLICATIONLIST_ADD_DETAILED_APPLICATION_START: {
      return {
        ...state,
        detailedApplication: {
          [action.applicationUuid]: {
            isLoading: true,
            error: null,
            application: null,
          },
        },
      };
    }

    case ApplicationListStateActionTypes.APPLICATIONLIST_ADD_DETAILED_APPLICATION_FAILED: {
      return {
        ...state,
        detailedApplication: {
          [action.applicationUuid]: {
            isLoading: false,
            error: action.message,
            application: null,
          },
        },
      };
    }

    case ApplicationListStateActionTypes.APPLICATIONLIST_ADD_DETAILED_APPLICATION_SUCCESSFUL: {
      return {
        ...state,
        detailedApplication: {
          ...state.detailedApplication,
          [action.application.uuid]: {
            isLoading: false,
            error: null,
            application: action.application,
            iban: state.traversal?.iban,
          },
        },
      };
    }

    case ApplicationListStateActionTypes.APPLICATIONLIST_INVALIDATE_STATE: {
      return initialApplicationListState;
    }

    default: {
      return state;
    }
  }
};
