import { createContext } from 'react';
import { IUserState, InitialUserState } from 'reducers/user';

const throwMissingProvider = () => {
  throw new Error('UserProvider is missing');
};

export interface IUserStateContextType {
  user: IUserState;
  isLoading: boolean;
  isError: boolean;
}

export interface IGuestToken {
  applicationUuid: string;
  clientUuid: string;
  customerUuid: string;
  exp: number;
  iat: number;
}

export const UserStateContext = createContext<IUserStateContextType>({
  user: InitialUserState,
  isLoading: false,
  isError: false,
});

export interface IUserDispatchContextType {
  guestLogin: (dob: string, token: string, campaign?: string) => void;
  logIn: (
    userLogin: string,
    password: string,
    redirectUrl?: string
  ) => Promise<void>;
  logOut: () => Promise<void>;
  completeChallenge: (password: string) => Promise<void>;
  idleLogout: () => void;
  idleDelete: () => void;
}

export const UserDispatchContext = createContext<IUserDispatchContextType>({
  guestLogin: throwMissingProvider,
  logIn: throwMissingProvider,
  logOut: throwMissingProvider,
  completeChallenge: throwMissingProvider,
  idleLogout: throwMissingProvider,
  idleDelete: throwMissingProvider,
});
