import { apiClient } from 'api';
import { ResumeError } from '../errors/resumeError';
import { IApolloTraversal, IResume } from '../constants/domain/resume';
import { DeepPartial } from 'react-hook-form';

export const resumeAdapter = async (
  entryPoint = 'furthest',
  traversal: DeepPartial<IApolloTraversal> = {},
  traversalId?: string
): Promise<string> => {
  const {
    data: { resumeUrl },
  } = await apiClient('home').post<IResume>(
    traversalId ? `resume/${traversalId}` : 'resume',
    {
      entryPoint,
      traversal,
    }
  );

  if (!resumeUrl) {
    throw new ResumeError('resume hash is missing', traversalId || 'N/A');
  }

  return resumeUrl;
};
