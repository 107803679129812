import {
  createContext,
  useReducer,
  useContext,
  memo,
  FC,
  ReactNode,
} from 'react';

import {
  ResumeStateActions,
  ResumeStateActionTypes,
} from '../../actions/resume';
import { ErrorSnackbar } from '../../components/ApplicationList/ErrorSnackbar';
import {
  InitialResumeState,
  IResumeState,
  ResumeRequestOrigin,
  resumeStateReducer,
} from '../../reducers/resume';

interface IResumeProviderProps {
  children: ReactNode;
}

const throwMissingProvider = () => {
  throw new Error('ResumeProvider is missing');
};

interface IResumeStateContext {
  state: IResumeState;
  dispatch: React.Dispatch<ResumeStateActions>;
}

export const ResumeContext = createContext<IResumeStateContext>({
  state: InitialResumeState,
  dispatch: throwMissingProvider,
});

const ResumeProvider: FC<IResumeProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(resumeStateReducer, InitialResumeState);

  return (
    <ResumeContext.Provider value={{ state, dispatch }}>
      <ErrorSnackbar
        open={state.isError}
        onClose={() =>
          dispatch({
            type: ResumeStateActionTypes.RESUME_CALL_RESET,
            payload: ResumeRequestOrigin.INITIAL,
          })
        }
      />
      {children}
    </ResumeContext.Provider>
  );
};

export default memo(ResumeProvider);

export const useResumeState = () => useContext(ResumeContext);
