const DATA_START_OFFSET = 7;

export const fileEncode = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.addEventListener(
      'load',
      () => {
        if (typeof reader.result === 'string') {
          const base64Index = reader.result.indexOf('base64,');
          resolve(reader.result.substr(base64Index + DATA_START_OFFSET));
        }
      },
      false
    );

    reader.addEventListener(
      'error',
      () => {
        reject(new Error(`can not load handle ${file.name}`));
      },
      false
    );

    reader.readAsDataURL(file);
  });
