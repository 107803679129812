import React, {
  FunctionComponent,
  PropsWithChildren,
  memo,
  useState,
} from 'react';
import ReactDOM from 'react-dom';
import {
  Box,
  Container,
  ContainerProps,
  Hidden,
  Popover,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { HelpOutline, ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import HelpIcon from '@mui/icons-material/Help';

import { Header } from '../Header';
import { AccountInfo } from './AccountInfo';
import { Projects } from '../../constants/domain/projects';
import { getCurrentTenantConfig, TenantDomain } from '../../utils/tenant';

interface IProps {
  logo?: string;
  url: string;
  isLoggedIn: boolean;
  showLogo: boolean;
  user?: {
    firstName: string;
    lastName: string;
    email: string;
  };
  project?: Projects;
  onLogoClick?: () => void;
  onMenuItemClick?: (menuItem: MenuItem) => void;
}

const StyledContainer: FunctionComponent<ContainerProps> = ({
  children,
  ...props
}) => (
  <Container
    sx={{ display: 'flex', padding: 0, alignItems: 'center' }}
    {...props}
  >
    {children}
  </Container>
);

export enum MenuItem {
  OVERVIEW = 'OVERVIEW',
  SERVICE_CENTER = 'SERVICE_CENTER',
  ACCOUNT = 'ACCOUNT',
  DIGITAl_ACCOUNT_CHECK = 'DIGITAL_ACCOUNT_CHECEK',
  NEW_LOAN_REQUEST = 'NEW_LOAN_REQUEST',
  LOGOUT = 'LOGOUT',
}

enum ContentPlaceholders {
  DYNAMIC_HEADER_MAIN_CONTENT_PLACEHOLDER_ID = 'DYNAMIC-HEADER-MAIN-CONTENT-PLACEHOLDER',
  DYNAMIC_HEADER_BOTTOM_CONTENT_PLACEHOLDER_ID = 'DYNAMIC-HEADER-BOTTOM-CONTENT-PLACEHOLDER',
  DYNAMIC_HEADER_LEFT_ICON_PLACEHOLDER_ID = 'DYNAMIC_HEADER_LEFT_ICON_PLACEHOLDER_ID',
  DYNAMIC_HEADER_TOP_CONTENT_PLACEHOLDER_ID = 'DYNAMIC_HEADER_TOP_CONTENT_PLACEHOLDER_ID',
  DYNAMIC_HEADER_TOP_BANNER_PLACEHOLDER_ID = 'DYNAMIC_HEADER_TOP_BANNER_PLACEHOLDER_ID',
}

export enum Positions {
  LEFT = 'LEFT',
  MIDDLE = 'MIDDLE',
  BOTTOM = 'BOTTOM',
  TOP = 'TOP',
  TOP_BANNER = 'TOP_BANNER',
}

export const HeaderView: FunctionComponent<IProps> = memo(
  ({ url, logo, onMenuItemClick, isLoggedIn, showLogo, user, project }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { domain } = getCurrentTenantConfig();
    const isSmava = domain === TenantDomain.Smava;

    const [anchorElAccount, setAnchorElAccount] = useState<null | HTMLElement>(
      null
    );

    const onItemClick = (menuItem: MenuItem) => () => {
      handleCloseAccountMenu();
      onMenuItemClick && onMenuItemClick(menuItem);
    };

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorElAccount(event.currentTarget);
    };

    const handleCloseAccountMenu = () => {
      setAnchorElAccount(null);
    };

    const isAccountMenuOpen = Boolean(anchorElAccount);

    return (
      <Header>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          style={{ width: '100%' }}
        >
          <Box
            id={ContentPlaceholders.DYNAMIC_HEADER_TOP_BANNER_PLACEHOLDER_ID}
          />
          <StyledContainer maxWidth="lg">
            <Box
              id={ContentPlaceholders.DYNAMIC_HEADER_LEFT_ICON_PLACEHOLDER_ID}
            />
            {showLogo && (
              <Header.LogoWrapper href={url}>
                <Header.Logo src={logo as string} />
              </Header.LogoWrapper>
            )}
            <Box
              id={
                ContentPlaceholders.DYNAMIC_HEADER_MAIN_CONTENT_PLACEHOLDER_ID
              }
              sx={{
                display: 'flex',
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            />
            <Header.MenuSection>
              <Header.MenuItem
                sx={{
                  p: '4px',
                }}
                noPadding={isMobile}
                onMenuItemClick={onItemClick(MenuItem.SERVICE_CENTER)}
              >
                <Header.MenuListItemIcon>
                  <IconButton
                    sx={{
                      width: '20px',
                      height: '20px',
                      margin: '0 4px',
                      // Apply larger touch area only for mobile screens
                      '@media (max-width:600px)': {
                        // Target mobile devices (screen width < 600px)
                        width: '40px',
                        height: '40px',
                        margin: 0,
                      },
                    }}
                  >
                    {isSmava ? (
                      <HelpOutline
                        style={{
                          fill: 'inherit',
                          fontSize: isMobile ? '32px' : '20px',
                        }}
                      />
                    ) : (
                      <HelpIcon
                        style={{
                          fill: 'inherit',
                          fontSize: isMobile ? '32px' : '20px',
                        }}
                      />
                    )}
                  </IconButton>
                </Header.MenuListItemIcon>
                <Hidden smDown>Hilfe und Support</Hidden>
              </Header.MenuItem>
              {isLoggedIn && !!user?.firstName && !!user?.lastName && (
                <>
                  <Hidden smDown>
                    <Header.MenuItemDivider />
                  </Hidden>
                  {user && (
                    <Header.MenuItem
                      sx={{
                        whiteSpace: 'nowrap',
                        marginLeft: isMobile ? '4px' : 0,
                        padding: 0,
                      }}
                      aria-describedby="account-menu"
                      onMenuItemClick={handleOpenUserMenu}
                      noPadding
                    >
                      <IconButton
                        sx={{
                          width: '24px',
                          height: '24px',
                          margin: '0 4px',
                          // Apply larger touch area only for mobile screens
                          '@media (max-width:600px)': {
                            // Target mobile devices (screen width < 600px)
                            width: '40px',
                            height: '40px',
                            margin: 0,
                          },
                        }}
                      >
                        <Header.AvatarSmall
                          alt={`${user.firstName} ${user.lastName}`}
                          firstName={user.firstName}
                          lastName={user.lastName}
                        />
                      </IconButton>
                      <Hidden smDown>
                        {user.firstName}
                        {isAccountMenuOpen ? (
                          <ArrowDropUp
                            style={{ fill: '#165297', fontSize: '16px' }}
                          />
                        ) : (
                          <ArrowDropDown
                            style={{ fill: '#165297', fontSize: '16px' }}
                          />
                        )}
                      </Hidden>
                    </Header.MenuItem>
                  )}

                  {isMobile ? (
                    <Header.Drawer
                      title="Kundenkonto"
                      isDrawerShown={isAccountMenuOpen}
                      onCloseDrawer={handleCloseAccountMenu}
                    >
                      <AccountInfo
                        project={project}
                        user={user}
                        onMenuItemClick={onMenuItemClick}
                        onRequestClose={handleCloseAccountMenu}
                      />
                    </Header.Drawer>
                  ) : (
                    <Popover
                      id="account-menu"
                      open={isAccountMenuOpen}
                      anchorEl={anchorElAccount}
                      onClose={handleCloseAccountMenu}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                      sx={{
                        '&.MuiPopover-paper': {
                          boxShadow: '0px 0px 16px rgba(115, 151, 193, 0.25)',
                          borderRadius: '8px',
                        },
                      }}
                    >
                      <AccountInfo
                        user={user}
                        project={project}
                        onMenuItemClick={onMenuItemClick}
                        onRequestClose={handleCloseAccountMenu}
                      />
                    </Popover>
                  )}
                </>
              )}
            </Header.MenuSection>
          </StyledContainer>
          <StyledContainer maxWidth="lg">
            <Box
              id={ContentPlaceholders.DYNAMIC_HEADER_TOP_CONTENT_PLACEHOLDER_ID}
              width="100%"
            />
          </StyledContainer>
          <StyledContainer maxWidth="lg">
            <Box
              id={
                ContentPlaceholders.DYNAMIC_HEADER_BOTTOM_CONTENT_PLACEHOLDER_ID
              }
              width="100%"
            />
          </StyledContainer>
        </Box>
      </Header>
    );
  }
);

export interface IInjectDynamicHeaderContent {
  position: Positions;
}

export const InjectDynamicHeaderContent: FunctionComponent<
  PropsWithChildren<IInjectDynamicHeaderContent>
> = ({ children, position }) => {
  let mountEl: HTMLElement | null = null;
  switch (position) {
    case Positions.TOP_BANNER:
      mountEl = document.getElementById(
        ContentPlaceholders.DYNAMIC_HEADER_TOP_BANNER_PLACEHOLDER_ID
      );
      break;
    case Positions.TOP:
      mountEl = document.getElementById(
        ContentPlaceholders.DYNAMIC_HEADER_TOP_CONTENT_PLACEHOLDER_ID
      );
      break;
    case Positions.MIDDLE:
      mountEl = document.getElementById(
        ContentPlaceholders.DYNAMIC_HEADER_MAIN_CONTENT_PLACEHOLDER_ID
      );
      break;
    case Positions.BOTTOM:
      mountEl = document.getElementById(
        ContentPlaceholders.DYNAMIC_HEADER_BOTTOM_CONTENT_PLACEHOLDER_ID
      );
      break;
    case Positions.LEFT:
      mountEl = document.getElementById(
        ContentPlaceholders.DYNAMIC_HEADER_LEFT_ICON_PLACEHOLDER_ID
      );
      break;
  }

  if (!mountEl) {
    return null;
  }
  return ReactDOM.createPortal(children, mountEl);
};
