import { OfferDetailsActions, OfferDetailsActionTypes } from './actions';
import { IApplication } from '../../../../constants/interfaces/Application';

export interface IOfferDetailsState {
  viewingOffer: IApplication | null;
  isLoading: boolean;
}

export const initialOfferDetailsState: IOfferDetailsState = {
  viewingOffer: null,
  isLoading: false,
};

export const OfferDetailsReducer = (
  state: IOfferDetailsState,
  action: OfferDetailsActions
): IOfferDetailsState => {
  switch (action.type) {
    case OfferDetailsActionTypes.SET_VIEWING_OFFER: {
      return {
        ...state,
        viewingOffer: action.offer,
      };
    }

    case OfferDetailsActionTypes.SHOW_LOADER: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case OfferDetailsActionTypes.HIDE_LOADER: {
      return {
        ...state,
        isLoading: false,
      };
    }

    default: {
      return state;
    }
  }
};
