import BugsnagError from '@finanzcheck/ti-shared-ui/errors/bugsnagError';

export class CoBrowseError extends BugsnagError {
  public customerUuid: string | undefined;

  constructor(message: string, customerUuid?: string) {
    super(message);

    this.customerUuid = customerUuid;
    this.name = 'CoBrowseError';
  }

  // eslint-disable-next-line class-methods-use-this
  getMetadata() {
    return {
      user: {
        uuid: this.customerUuid,
      },
    };
  }
}
