import { apiClient } from 'api';
import { IDocumentUploadState } from '../components/Application/DocumentUpload/reducer';
import { IDocumentMetaData } from '../constants/domain/document.interface';

import { fileEncode } from '../utils/fileEncoder';
import { logError } from '@finanzcheck/ti-shared-ui/utils/log';
import { DocumentUploadError } from '../errors/documentUploadError';
import { ClearingError } from '../errors/clearingError';
import { config } from '../constants/config';
import { UploadCallback } from '@finanzcheck/ti-shared-ui/components/DocumentUpload';
import { DocumentToUpload } from '@finanzcheck/ti-shared-ui/components/DocumentUpload/reducer';

interface IUploadRequest {
  numberOfDebtors: 1 | 2;
  documents: DocumentToUpload[];
  uploadGroupHash: string;
}

const notifyClearing = async (
  applicationUuid: string,
  { documents, numberOfDebtors, uploadGroupHash }: IUploadRequest
): Promise<void> => {
  try {
    await apiClient('home').post(`document/${applicationUuid}/notify`, {
      numberOfDebtors,
      uploadGroupHash: uploadGroupHash,
      documents: documents.map((doc) => ({
        documentName: doc.file.name,
        documentType: doc.documentType,
        debtor: doc.debtor === 'primary' ? 1 : 2,
      })),
    });
  } catch (e) {
    logError(new ClearingError(e.message));
  }
};

const upload = async (
  document: DocumentToUpload,
  {
    applicationUuid,
    personId,
    loanLeadUuid,
    uploadGroupHash,
  }: IDocumentMetaData,
  callback: UploadCallback
): Promise<void> => {
  try {
    const result = await apiClient('home').post(
      `document/${applicationUuid}/upload`,
      {
        file: {
          type: document.documentType,
          data: await fileEncode(document.file as File),
          name: document.file.name,
          debtorType: document.debtor?.toUpperCase() || 'PRIMARY',
        },
        uploadGroupHash: uploadGroupHash,
        origin: config.origin,
        personId,
        loanLeadUuid,
      }
    );
    if (result) {
      callback('SUCCESS', document);
    } else {
      throw new Error('Failed');
    }
  } catch (e) {
    logError(new DocumentUploadError(e.message, document.documentType));
    callback('FAIL', document);
    throw e;
  }
};

const uploadFilesToHome = async (
  uploadRequest: IUploadRequest,
  meta: IDocumentMetaData,
  callback: UploadCallback
): Promise<boolean> => {
  let hasSuccessfulUpload = false;
  await Promise.allSettled(
    uploadRequest.documents.map((document) => upload(document, meta, callback))
  )
    .then((results) => {
      hasSuccessfulUpload =
        results.filter((result) => result.status === 'fulfilled').length > 0;
    })
    .catch((e) => logError(e));
  return hasSuccessfulUpload;
};

export const uploadDocuments = async (
  applicationUuid: string,
  loanLeadUuid: string,
  documentsToUpload: IDocumentUploadState['documents'],
  personId: string,
  uploadGroupHash: string,
  callback: UploadCallback
): Promise<void> => {
  const documents: Array<DocumentToUpload> = [];

  Object.keys(documentsToUpload).forEach((debtor) => {
    documentsToUpload[
      debtor as keyof IDocumentUploadState['documents']
    ]!.forEach(async (document: DocumentToUpload) => {
      if (document.status !== 'ADDED') {
        return;
      }

      documents.push(document);
    });
  });

  const uploadRequest: IUploadRequest = {
    numberOfDebtors: Object.keys(documentsToUpload).length > 1 ? 2 : 1,
    documents,
    uploadGroupHash,
  };

  const hasSuccessfulUploads = await uploadFilesToHome(
    uploadRequest,
    { applicationUuid, personId, loanLeadUuid, uploadGroupHash },
    callback
  );
  if (hasSuccessfulUploads) {
    await notifyClearing(applicationUuid, uploadRequest);
  }
};
