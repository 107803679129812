import { styled, Theme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import StopScreenShareIcon from '@mui/icons-material/StopScreenShare';
import { useIsMobile } from '@finanzcheck/ti-shared-ui/hooks/useIsMobile';
import { Button } from '@mui/material';

const ActiveSessionBarContainer = styled(Box)(({ theme }) => ({
  bottom: '0',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(3, 0),
  position: 'fixed',
  left: '50%',
  color: theme.palette.common.white,
  background: 'rgba(0, 0, 0, 0.7)',
  borderRadius: '8px',
  zIndex: 99999,
  transform: 'translateX(-50%)',
  width: '800px',
  height: '72px',
  [theme.breakpoints.up('sm')]: {
    bottom: '50px',
  },
}));

const CustomStopScreenShareIcon = styled(StopScreenShareIcon)(({ theme }) => ({
  color: theme.palette.common.white,
}));

interface IActiveSessionBarViewProps {
  onClickCurrentSessionEnd: () => void;
}

export const ActiveSessionBarView: React.FC<IActiveSessionBarViewProps> = ({
  onClickCurrentSessionEnd,
}) => {
  const isMobile = useIsMobile();

  return (
    <ActiveSessionBarContainer maxWidth="xs">
      <Box display="flex" alignItems="center">
        {!isMobile && (
          <Typography variant="body2" sx={{ pr: 5 }}>
            Sie teilen Ihren Bildschirm mit unserem Support-Mitarbeiter.
          </Typography>
        )}
        <Button
          sx={(theme: Theme) => ({
            backgroundColor: theme.palette.error.main,
            ':hover': {
              backgroundColor: theme.palette.error.main,
            },
          })}
          startIcon={<CustomStopScreenShareIcon />}
          variant="contained"
          onClick={onClickCurrentSessionEnd}
        >
          Bildschirmfreigabe beenden
        </Button>
      </Box>
    </ActiveSessionBarContainer>
  );
};
