import {
  createContext,
  FC,
  memo,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
} from 'react';

import { ICampaignsAndVariants, getCampaignsAndVariants } from './index';
import { setCookie } from '../cookie';

declare global {
  interface Window {
    sitespect: string | undefined;
  }
}

interface ISitespectProviderProps {
  children: ReactNode;
  personId: string;
}

const SitespectContext = createContext<ICampaignsAndVariants>({
  variants: {},
  campaigns: {},
});

const SITESPECT_OMNICHANNEL_COOKIE_NAME = 'SSOC';

const CustomSitespectProvider: FC<ISitespectProviderProps> = ({
  children,
  personId,
}) => {
  const sitespect = useMemo(() => getCampaignsAndVariants(), []);

  useEffect(() => {
    if (personId) {
      setCookie(SITESPECT_OMNICHANNEL_COOKIE_NAME, personId, 30);
    }
  }, [personId]);

  return (
    <SitespectContext.Provider value={sitespect}>
      {children}
    </SitespectContext.Provider>
  );
};

export default memo(CustomSitespectProvider);

export const useSitespect = () => useContext(SitespectContext);
