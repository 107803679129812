import {
  DashboardStateActions,
  DashboardStateActionTypes,
} from '../../actions/dashboard';
import { IDashboard } from '../../constants/domain/dashboard.interface';
import { logError } from '@finanzcheck/ti-shared-ui/utils/log';
import { NoTraversalsError } from '../../errors/noTraversalsError';

export interface IDashboardState {
  dashboard: IDashboard | null;
}

export const InitialDashboardState: IDashboardState = {
  dashboard: null,
};

export const dashboardStateReducer = (
  state: IDashboardState,
  action: DashboardStateActions
): IDashboardState => {
  switch (action.type) {
    case DashboardStateActionTypes.DASHBOARD_LOAD_SUCCESSFUL: {
      let dashboardState: IDashboard['state'];

      if (action.dashboard.traversals.length > 0) {
        dashboardState = 'FILLED';
      } else if (
        action.dashboard.traversals.length === 0 &&
        action.dashboard.traversalCounter !== 0
      ) {
        dashboardState = 'EMPTY_WITH_MORE';
      } else {
        dashboardState = 'EMPTY';
        logError(new NoTraversalsError(`No traversals available`));
      }

      return {
        ...state,
        dashboard: {
          ...action.dashboard,
          state: dashboardState,
        },
      };
    }

    case DashboardStateActionTypes.DASHBOARD_LOAD_MORE_TRAVERSALS_SUCCESSFUL: {
      if (!state.dashboard) {
        return state;
      }

      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          traversals: [...state.dashboard.traversals, ...action.traversals],
        },
      };
    }

    case DashboardStateActionTypes.DASHBOARD_INVALIDATE_STATE: {
      return InitialDashboardState;
    }

    default: {
      return state;
    }
  }
};
