import { FunctionComponent, memo } from 'react';
import { useEmail } from '@finanzcheck/catalyst-renderless-components';
import { Input } from '../Input';
import { IProps } from '.';

export const MailView: FunctionComponent<IProps> = memo(
  ({ value, ...inputProps }) => {
    const { getProps, ref } = useEmail({ value });
    const { error, helperText, ...rest } = getProps();

    return (
      <Input
        {...inputProps}
        {...rest}
        inputRef={ref}
        error={error ? helperText : undefined}
        helperText={helperText}
      />
    );
  }
);
