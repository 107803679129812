import {
  getCurrentAuthenticatedUser,
  getUserAttributes,
} from '@finanzcheck/ti-shared-ui/utils/amplify/amplify';
import { UnauthorizedUserError } from 'errors/unathorizedUserError';
import { useShowToast } from '@finanzcheck/ti-shared-ui/hooks/useShowToast';
import useTrack from '@finanzcheck/ti-shared-ui/hooks/useTrack';
import { useCallback, useEffect, useMemo, useReducer } from 'react';
import { useQuery } from 'react-query';
import { useHistory, useLocation } from 'react-router';
import { InitialUserState, userStateReducer } from 'reducers/user';
import { isGuestLogin } from '../GuestAuthentication';
import { getStorage } from '@finanzcheck/ti-shared-ui/utils/storage';
import { loadCustomerAdapter } from 'adapter/customerAdapter';
import { UserStateActionTypes } from 'actions/user';
import {
  isGuestAuthError,
  removeGuestTokenFromSessionStorage,
  setAccessToken,
  updateApiClientAuthHeader,
} from './utils';
import { IUser } from '@finanzcheck/ti-shared-ui/constants/domain/user.interface';
import {
  clearCookie,
  getCookie,
  setCookie,
  setHardenedCookieBase64,
} from '@finanzcheck/ti-shared-ui/utils/cookie';
import { getMetaDataFromDomain } from '@finanzcheck/ti-shared-ui/utils/domain';
import jwtDecode from 'jwt-decode';
import { routes } from 'constants/routes';
import { logError } from '@finanzcheck/ti-shared-ui/utils/log';
import { UserContextError } from 'errors/userContextError';
import { apiClient } from 'api';
import { AxiosError } from 'axios';
import { TrackingEvent } from '@finanzcheck/ti-shared-ui/utils/tracking/events.interface';
import { SignInState } from '@finanzcheck/ti-shared-ui/constants/interfaces/Session';

const signInStateTracking: Partial<Record<SignInState, TrackingEvent>> = {
  SIGNED_IN: TrackingEvent.OnLoginSuccess,
  SIGNED_OUT: TrackingEvent.OnLogoutSuccess,
};

export function useUrlQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

export const useSession = () => {
  const history = useHistory();
  const track = useTrack();

  const showToast = useShowToast();
  const query = useUrlQuery();
  const [user, dispatch] = useReducer(userStateReducer, InitialUserState);

  // we should not load the customer if guest login is active
  // and we don't have a dob, otherwise we have an token-refreshment which is unsuccessful if we
  // don't enter the dob fast enough. (we only have a few retries…)
  const isLoadCustomerEnabled = Boolean(
    (!!user?.homeAccountId && !isGuestLogin()) ||
      (!!user?.homeAccountId &&
        isGuestLogin() &&
        getStorage('sessionStorage').getItem('dateOfBirth'))
  );

  const { data, isError, isLoading } = useQuery<IUser>(
    ['user', user?.homeAccountId],
    loadCustomerAdapter,
    {
      enabled: isLoadCustomerEnabled,
    }
  );

  useEffect(() => {
    if (data) {
      dispatch({
        type: UserStateActionTypes.USER_SET_DETAILS,
        details: data,
      });
    }
  }, [data, dispatch]);

  const setAttributes = useCallback(async () => {
    dispatch({
      type: UserStateActionTypes.USER_SET_ATTRIBUTES,
      attributes: await getUserAttributes(),
    });
  }, [dispatch]);

  useEffect(() => {
    if (user.signInState !== SignInState.SIGNED_IN) {
      return;
    }

    setAccessToken();
    setAttributes();
  }, [user.signInState, setAttributes]);

  useEffect(() => {
    const event = signInStateTracking[user.signInState];
    if (!event) {
      return;
    }
    track({ event: event });
  }, [user.signInState, track]);

  const logoutUser = useCallback(() => {
    dispatch({
      type: UserStateActionTypes.USER_LOGOUT_SUCCESSFUL,
    });
    delete apiClient('home').defaults.headers['Authorization'];
    removeGuestTokenFromSessionStorage();
  }, [dispatch]);

  const handleGuestLogin = useCallback(
    (guest: any, token: string) => {
      updateApiClientAuthHeader(token);
      dispatch({
        type: UserStateActionTypes.GUEST_LOGIN_SUCCESSFUL,
        attributes: guest,
      });
    },
    [dispatch]
  );

  useEffect(() => {
    const checkUserSession = async () => {
      try {
        const cognitoUser = await getCurrentAuthenticatedUser();

        if (!cognitoUser) {
          throw new UnauthorizedUserError();
        }

        dispatch({
          type: UserStateActionTypes.USER_LOGIN_SUCCESSFUL,
          user: cognitoUser,
        });
      } catch (error) {
        const guestToken = getCookie('guestAccessToken');
        if (guestToken) {
          const client = apiClient('home');
          updateApiClientAuthHeader(guestToken);

          client.interceptors.response.use(
            (res) => {
              // TODO: parse response and extract eventual new access token
              if (res && res.headers.GuestToken) {
                setCookie(
                  'guestAccessToken',
                  res.headers.GuestToken,
                  parseInt(import.meta.env.VITE_GUEST_SESSION_TTLS_DAYS)
                );
                updateApiClientAuthHeader(guestToken);
              }
              return res;
            },
            (error: AxiosError) => {
              if (!isGuestAuthError(error, user.signInState)) {
                logError(
                  new UserContextError(
                    'Axios interceptors response',
                    user.signInState,
                    error.message
                  )
                );
              } else {
                console.error(error);
              }
            }
          );

          // If we refresh the page but have the  token in the session storage you should be still authenticated
          const decodedToken = jwtDecode(guestToken);
          if (decodedToken) {
            handleGuestLogin(decodedToken, guestToken);
          }
          return;
        }
        user.signInState !== SignInState.SIGNED_OUT && logoutUser();

        if (user.signInState === SignInState.SIGNED_IN) {
          showToast({
            title: 'Achtung',
            description:
              'Sie wurden zu Ihrer Sicherheit automatisch ausgeloggt!',
            severity: 'warning',
            duration: 5000,
          });

          history.push(routes.login);
        }

        if (
          error instanceof Error &&
          error.message !== 'The user is not authenticated'
        ) {
          logError(error);
        }
      }
    };

    checkUserSession();
  }, [
    logoutUser,
    dispatch,
    history,
    showToast,
    user.signInState,
    query,
    handleGuestLogin,
  ]);

  //set cookie for cross-domain session
  useEffect(() => {
    if ([SignInState.GUEST, SignInState.SIGNED_IN].includes(user.signInState)) {
      const cookieDomain = getMetaDataFromDomain().cookieDomain;
      setHardenedCookieBase64(
        'TealUserDetails',
        JSON.stringify({
          firstname: user?.details?.firstName,
          lastname: user?.details?.lastName,
          email: user?.details?.email,
        }),
        1,
        cookieDomain
      );
    } else {
      clearCookie('TealUserDetails');
    }
  }, [user, user.signInState]);

  return {
    user,
    isError,
    isLoading,
    dispatch,
    logoutUser,
    handleGuestLogin,
  };
};
