import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  Link,
  Box,
  Divider,
  Avatar,
  AvatarProps,
  ListItemProps,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { PropsWithChildren } from 'react';
import { HeaderDrawer, IHeaderDrawerProps } from './HeaderDrawer';
import { getCurrentTenantConfig, TenantDomain } from '../../utils/tenant';

interface IHeaderComposition {
  LogoWrapper: React.FC<PropsWithChildren<ILogoWrapperProps>>;
  Logo: React.FC<ILogoProps>;
  MenuSection: React.FC<PropsWithChildren>;
  MenuItem: React.FC<MenuItemProps>;
  MenuItemLink: React.FC<PropsWithChildren>;
  MenuListItemIcon: React.FC<PropsWithChildren>;
  MenuItemDivider: React.FC<PropsWithChildren>;
  Avatar: React.FC<AvatarComponentProps>;
  AvatarSmall: React.FC<AvatarComponentProps>;
  Drawer: React.FC<PropsWithChildren<IHeaderDrawerProps>>;
}

export const Header: React.FC<PropsWithChildren> & IHeaderComposition = ({
  children,
  ...restProps
}: PropsWithChildren) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { domain } = getCurrentTenantConfig();
  const isSmava = domain === TenantDomain.Smava;

  return (
    <Grid
      container
      direction="row"
      wrap="nowrap"
      justifyContent={isMobile ? 'space-between' : 'space-around'}
      style={{
        padding: '0 24px',
        boxShadow: `
          0px 1px 5px 0px ${isSmava ? '#84848433' : '#0D315B33'},
          0px 2px 2px 0px ${isSmava ? '#84848433' : '#0D315B33'},
          0px 3px 1px -2px ${isSmava ? '#84848433' : '#0D315B33'}
        `,
        zIndex: 10,
        minHeight: '64px',
      }}
      {...restProps}
    >
      {children}
    </Grid>
  );
};

interface ILogoWrapperProps {
  href: string;
}
const LogoWrapper: React.FC<PropsWithChildren<ILogoWrapperProps>> = ({
  children,
  href,
}) => (
  <Box my={1}>
    <a href={href}>{children}</a>
  </Box>
);
Header.LogoWrapper = LogoWrapper;

interface ILogoProps {
  src: string;
}
const Logo: React.FC<ILogoProps> = ({ src }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <img
      src={src}
      style={{
        maxWidth: '200px',
        marginTop: '10px',
        height: !isMobile ? '30px' : '24px',
        width: 'auto',
      }}
      alt=""
    />
  );
};
Header.Logo = Logo;

Header.MenuSection = ({ children }: PropsWithChildren) => (
  <List sx={{ display: 'flex', pb: 0, pt: 0, minHeight: '64px' }}>
    {children}
  </List>
);

type MenuItemProps = ListItemProps & {
  noPadding?: boolean;
  onMenuItemClick: (event: React.MouseEvent<HTMLElement>) => void;
  button?: any;
};

const MenuItem: React.FC<MenuItemProps> = ({
  children,
  onMenuItemClick,
  noPadding = false,
  sx,
  ...restProps
}) => {
  const theme = useTheme();
  const { domain } = getCurrentTenantConfig();
  const isSmava = domain === TenantDomain.Smava;

  return (
    <ListItem
      button
      {...restProps}
      onClick={onMenuItemClick}
      style={{
        width: 'auto',
        ...(noPadding ? {} : { paddingTop: '12px', paddingBottom: '12px' }),
        whiteSpace: 'nowrap',
        display: 'flex',
      }}
      sx={sx}
    >
      <Typography
        variant="body2"
        fontWeight={isSmava ? 500 : 700}
        display="flex"
        alignItems="center"
        color={theme.palette.grey[900]}
      >
        {children}
      </Typography>
    </ListItem>
  );
};
Header.MenuItem = MenuItem;

Header.MenuItemLink = ({ children, ...restProps }: PropsWithChildren) => (
  <Link {...restProps} color="textPrimary" variant="body2">
    <b>{children}</b>
  </Link>
);

Header.MenuListItemIcon = ({ children }: PropsWithChildren) => (
  <ListItemIcon
    sx={{
      minWidth: 0,
      paddingRight: 0,
    }}
  >
    {children}
  </ListItemIcon>
);

Header.MenuItemDivider = () => (
  <Box ml={1} mr={1}>
    <Divider orientation="vertical" sx={{ backgroundColor: '#D0DCEA' }} />
  </Box>
);

type AvatarComponentProps = AvatarProps & {
  firstName: string;
  lastName: string;
};

const AvatarComponent: React.FC<AvatarComponentProps> = ({
  firstName,
  lastName,
}) => (
  <Avatar
    sx={{
      backgroundColor: '#D0DCEA',
      color: '#1B2D77',
      fontWeight: 'bold',
      width: '64px',
      height: '64px',
      border: '8px solid #E8EEF5',
      boxSizing: 'content-box',
    }}
  >
    {firstName.charAt(0)}
    {lastName.charAt(0)}
  </Avatar>
);

Header.Avatar = AvatarComponent;

const AvatarComponentSmall: React.FC<AvatarComponentProps> = ({
  firstName,
  lastName,
}) => (
  <Avatar
    sx={(theme) => ({
      backgroundColor: '#D0DCEA',
      fontWeight: 'bold',
      borderRadius: '14px',
      border: '1px solid #7397C1',
      width: '28px',
      height: '28px',
      [theme.breakpoints.up('sm')]: {
        width: '24px',
        height: '24px',
      },
    })}
  >
    <Typography fontSize="10px" fontWeight={700} color="#1B2D77">
      {firstName.charAt(0)}
      {lastName.charAt(0)}
    </Typography>
  </Avatar>
);

Header.AvatarSmall = AvatarComponentSmall;

Header.Drawer = HeaderDrawer;
