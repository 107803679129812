import { apiClient } from 'api';

export const getGuestToken = async (
  dob: string,
  token: string,
  campaign?: string
): Promise<{ token: string; refresh: string; expiryDate: number }> => {
  const params = new URLSearchParams({
    token,
    birthday: dob,
    ...(!!campaign && { campaign }),
  });
  const response = await apiClient('home').get<{
    token: string;
    refresh: string;
    expiryDate: number;
  }>(`/token/exchange?${params.toString()}`);

  return response.data;
};

export interface ValidateResponse {
  valid: boolean;
  lastName?: string;
  gender?: string;
}

export const validateToken = async (
  token: string
): Promise<ValidateResponse> => {
  const params = new URLSearchParams({
    token,
  });
  const response = await apiClient('home').get<ValidateResponse>(
    `/token/validate?${params.toString()}`
  );

  return response.data;
};
