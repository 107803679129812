import { PropsWithChildren, memo } from 'react';
import CustomSitespectProvider from '@finanzcheck/ti-shared-ui/utils/sitespect/SitespectProvider';
import { useUserState } from '../UserContext';

export const SitespectProvider: React.FC<PropsWithChildren> = memo(
  ({ children }) => {
    const { user } = useUserState();

    if (!user.details.personId) {
      return <>{children}</>;
    }

    return (
      <CustomSitespectProvider personId={user.details.personId}>
        {children}
      </CustomSitespectProvider>
    );
  }
);
