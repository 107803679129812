import React, {
  FunctionComponent,
  useCallback,
  useState,
  useEffect,
} from 'react';
import { useLocation } from 'react-router';
import {
  TokenGoneError,
  getErrorMessage,
} from 'components/Context/GuestAuthentication';
import useTrack from '@finanzcheck/ti-shared-ui/hooks/useTrack';
import { TrackingEvent } from '@finanzcheck/ti-shared-ui/utils/tracking/events.interface';
import { useClient } from '@finanzcheck/ti-shared-ui/components/Context/Client';
import { useQuery } from 'react-query';
import { GuestAuthentication } from './GuestAuthentication';
import { GuestAuthenticationError } from './GuestAuthenticationError';
import { LoadingBar } from 'components/Dashboard/LoadingBar';
import { FormProvider, useForm } from 'react-hook-form';
import { ValidateResponse } from 'adapter/guestLoginAdapter';

interface IProps {
  onSubmit: (dob: string) => Promise<boolean>;
  onValidate: (token?: string) => Promise<ValidateResponse>;
  token?: string;
}

export const GuestAuthenticationScreenView: FunctionComponent<IProps> = ({
  onSubmit,
  onValidate,
  token,
}) => {
  const [loginError, setLoginError] = useState<string | React.ReactElement>();
  const [loading, setLoading] = useState<boolean>(false);
  const track = useTrack();
  const location = useLocation();
  const urlPath = location.pathname;
  const pathParts = urlPath.split('/');
  const { defaultAdvisor } = useClient();
  const [isMaxRetries, setIsMaxRetries] = useState<boolean>(false);

  const { data, isLoading } = useQuery(['token-validate', token], () =>
    onValidate(token)
  );

  useEffect(() => {
    track({
      event: TrackingEvent.LoginScreenShownOriginFromMagicToken,
      payload: {
        pathname: location.pathname,
        ...(pathParts[2] === 'angebote' && // Adding applicationUuid attribute conditionally based on URL matches to checkout
          pathParts[1] === 'anfrage' && {
            // Can't use the useParams() hook to read the applicationUuid from URL as this component is not inside <Route>
            applicationUuid: pathParts[3],
          }),
      },
    });
  }, [location.pathname, pathParts, track]);

  const handleDobChange = useCallback(
    async (value: string) => {
      setLoginError(undefined);
      setLoading(true);
      try {
        await onSubmit(value);
      } catch (e) {
        if (e instanceof TokenGoneError) {
          setIsMaxRetries(true);
        } else {
          setLoginError(getErrorMessage(e, defaultAdvisor.phone));
        }
      }
      setLoading(false);
    },
    [defaultAdvisor.phone, onSubmit]
  );

  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    shouldFocusError: true,
  });

  if (isLoading) {
    return <LoadingBar />;
  }

  if (isMaxRetries) {
    return (
      <FormProvider {...formMethods}>
        <GuestAuthenticationError
          error="Sie haben Ihr Versuchslimit erreicht"
          description="Ihr Geburtsdatum wurde mehrfach falsch eingegeben. Fordern Sie einen neuen Link an oder melden Sie sich mit Ihrem Passwort an."
        />
      </FormProvider>
    );
  }
  if (!data?.valid) {
    return (
      <FormProvider {...formMethods}>
        <GuestAuthenticationError
          error="Ihr Anmelde-Link ist leider nicht mehr gültig"
          description="Fordern Sie einen neuen Link an oder melden Sie sich alternativ mit Ihrem Passwort an, um auf Ihre Angebote zuzugreifen."
        />
      </FormProvider>
    );
  }

  return (
    <GuestAuthentication
      loading={loading}
      loginError={loginError}
      setLoginError={setLoginError}
      onDobChange={handleDobChange}
      gender={data.gender}
      lastName={data.lastName}
    />
  );
};
