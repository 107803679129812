export interface IEmitterFunctions {
  gtmEvent?(event: GTMEvents, properties: unknown): void;
  affiliateEvent?(event: AffiliateEvents, properties: unknown): void;
  genericEvent?(event: GenericEvents): void;
}

export enum GenericEventType {
  SCROLL_TOP = 'SCROLL_TOP',
  UPDATE_RESUME = 'UPDATE_RESUME',
}

type IGenericEventScrollTop = {
  event: GenericEventType.SCROLL_TOP;
};

type IGenericEventUpdateResume = {
  event: GenericEventType.UPDATE_RESUME;
  data: string;
};

export type GenericEvents = IGenericEventScrollTop | IGenericEventUpdateResume;

export enum GTMEvents {
  'Loading_QuestionAnswer' = '/Loading/QuestionAnswer',
  'Bank_Offer' = '/Bank/Offer',
  'ApplicationListView_ApplicationList_offerSelected' = '/ApplicationListView/ApplicationList:offerSelected',
  'ApplicationListView_OfferSelected' = '/ApplicationListView/OfferSelected',
  'ApplicationsListEmptyView_Rendered' = '/ApplicationsListEmptyView/Rendered',
  'IsSalable' = ':isSalable',
  'RequestCallback' = ':requestCallback',
}

export enum AffiliateEvents {
  'Affiliate_isSalable' = '/affiliate:finalConversionWithOffers',
  'Affiliate_nonSalable' = '/affiliate:finalConversionNoOffers',
}
