import { PropsWithChildren, createContext, useContext } from 'react';
import { DacResuseInfo } from '../../../constants/domain/dac.interface';
import { DocumentToUpload } from '../../DocumentUpload/reducer';
import { UploadCallback } from '../../DocumentUpload';
import { IApplication } from '../../../constants/interfaces/Application';
import { ClickOutStatus } from '../../Checkout/Identification/Providers/Clickout/constants';

interface Props {
  fetchReuseInfo: (
    clientUuid: string,
    loanRequestId: string,
    personId: string,
    applicationUuid: string,
    debtorIndex: 'primary' | 'secondary'
  ) => Promise<DacResuseInfo>;
  acceptReuse: (
    dacInfo: DacResuseInfo,
    clientUuid: string,
    applicationUuid: string,
    debtorIndex: 'primary' | 'secondary'
  ) => void;
  emailAdapter: (applicationUuid: string, clientUuid: string) => Promise<void>;

  useUpload: (
    application?: IApplication
  ) => (
    documents: Record<string, DocumentToUpload[]>,
    uploadGroupHash: string,
    callback: UploadCallback
  ) => Promise<void>;

  sendCheckoutStartedEvent: (
    applicationUuid: string,
    clientUuid: string
  ) => void;

  sendClickoutStarted: (
    applicationUuid: string,
    provider: string,
    applicants: number
  ) => Promise<void>;

  sendVIQESClickOutStatus: (
    applicationUuid: string,
    debtor: 'KN1' | 'KN2',
    personID: string,
    url: string,
    status: ClickOutStatus
  ) => Promise<void>;
}

export const AdapterContext = createContext<Props>({
  fetchReuseInfo: async () => {
    throw new Error('fetchReuseInfo not implemented');
  },
  acceptReuse: () => {
    throw new Error('acceptReuse not implemented');
  },
  emailAdapter: async () => {
    throw new Error('emailAdapter not implemented');
  },
  useUpload: () => {
    throw new Error('upload not implemented');
  },
  sendCheckoutStartedEvent: () => {
    throw new Error('checkoutStarted not implemented');
  },
  sendClickoutStarted() {
    throw new Error('sendClickoutStarted not implemented');
  },
  sendVIQESClickOutStatus() {
    throw new Error('sendVIQESClickOutStatus not implemented');
  },
});

//Let's try to collect new API calls here, sothat we can use the hook in the shared lib and have a easy life
export const AdapterProvider: React.FC<PropsWithChildren<Props>> = ({
  children,
  fetchReuseInfo,
  acceptReuse,
  emailAdapter,
  useUpload,
  sendCheckoutStartedEvent,
  sendClickoutStarted,
  sendVIQESClickOutStatus,
}) => {
  return (
    <AdapterContext.Provider
      value={{
        fetchReuseInfo,
        acceptReuse,
        emailAdapter,
        useUpload,
        sendCheckoutStartedEvent,
        sendClickoutStarted,
        sendVIQESClickOutStatus,
      }}
    >
      {children}
    </AdapterContext.Provider>
  );
};

export const useProjectAdapter = () => {
  const context = useContext(AdapterContext);

  if (context === undefined) {
    throw new Error('useProjectAdapter must be used within a AdapterProvider');
  }
  return context;
};
