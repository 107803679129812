import React, {
  createContext,
  memo,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useQuery } from 'react-query';

import { Tenant } from '@finanzcheck/teal-mui-theme';
import { getCurrentTenantConfig } from '../../../utils/tenant';
import {
  IClient,
  IClientResponse,
} from '../../../constants/domain/client.interface';
import { useClientMetadata } from './useClientMetadata';
import { getMetaDataFromDomain } from '../../../utils/domain';
import { Projects } from '../../../constants/domain/projects';

const initialState: IClient = {
  tenant: Tenant.Finanzcheck,
  uuid: '',
  id: '',
  clientHashId: '',
  firstName: '',
  lastName: '',
  salutation: '',
  website: '',
  email: '',
  clientName: '',
  displayName: '',
  company: '',
  street: '',
  city: '',
  country: '',
  zipCode: '',
  phone: '',
  workingTimes: '',
  urls: {
    ekomi: '',
    imprint: '',
    dataProtection: '',
    termsAndConditions: '',
    faq: '',
    faqHome: '',
    home: '',
    dac: '',
    tuev: '',
    precontractual: '',
    startPage: '',
    logout: '',
  },
  icons: {
    appleTouch: '',
    favicon: '',
    favicon16: '',
    favicon32: '',
    safariPinned: '',
  },
  logos: {
    client: '',
    tuev: '',
    ekomi: '',
    ssl: '',
    schufaNeutral: '',
  },
  documentUpload: {
    submitLegalCopy: '',
  },
  defaultAdvisor: {
    gender: undefined,
    name: '',
    phone: '',
    email: '',
  },
  project: Projects.HOME,
};

interface IClientProviderProps {
  children: ReactNode;
  clientAdapter: (clientUuid: string) => () => Promise<IClientResponse>;
  project: Projects;
}

const ClientContext = createContext<IClient>(initialState);

const ClientProvider: React.FC<IClientProviderProps> = ({
  children,
  clientAdapter,
  project,
}) => {
  const { tenant } = getCurrentTenantConfig();
  const { clientUuid } = getMetaDataFromDomain();
  const { data, isLoading, error } = useQuery<IClientResponse>(
    'clientInformation',
    clientAdapter(clientUuid)
  );
  const [clientData, setClientData] = useState<IClient>({
    ...initialState,
    tenant: tenant,
    project: project,
  });

  useEffect(() => {
    if (isLoading || !data) {
      return;
    }

    const {
      result: { client },
    } = data;

    // CDS response doesn't have the tenant, so we need to set it explicitly.
    setClientData({ ...client, tenant, project });
  }, [isLoading, data, tenant, project]);

  useEffect(() => {
    if (!error) {
      return;
    }

    const errorInstance =
      error instanceof Error
        ? error
        : new Error('Could not fetch tenant information.');

    // send the user to the error failover page <ErrorFallback />
    throw errorInstance;
  }, [error]);

  useClientMetadata(clientData, project);

  return (
    <ClientContext.Provider value={clientData}>
      {children}
    </ClientContext.Provider>
  );
};

export default memo(ClientProvider);

export const useClient = () => useContext(ClientContext);
