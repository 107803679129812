import { FunctionComponent, memo, useCallback, useState } from 'react';
import { Box, TextFieldProps } from '@mui/material/';
import TextField from '@mui/material/TextField';
import {
  Tooltip,
  TooltipButton,
  TooltipProps,
} from 'components/FormFields/Tooltip';

export type IProps = Omit<TextFieldProps, 'error'> &
  TooltipProps & { error?: string };

export const InputView: FunctionComponent<IProps> = memo(
  ({
    error,
    tooltip,
    showTooltip,
    onTooltip,
    fullWidth = true,
    color = 'primary',
    variant = 'outlined',
    helperText,
    ...rest
  }) => {
    const [isTooltipDisplayed, setTooltipDisplayed] = useState(showTooltip);
    const tooltipClickHandler = useCallback(() => {
      const isDisplayed = !isTooltipDisplayed;

      setTooltipDisplayed(isDisplayed);

      if (onTooltip) {
        onTooltip(isDisplayed);
      }
    }, [isTooltipDisplayed, setTooltipDisplayed, onTooltip]);

    return (
      <>
        <Box display="flex" alignItems="center">
          <TextField
            {...rest}
            helperText={error || helperText}
            error={!!error}
            variant={variant}
            color={color}
            fullWidth={fullWidth}
          />
          {tooltip && <TooltipButton onClick={tooltipClickHandler} />}
        </Box>
        {tooltip && <Tooltip open={isTooltipDisplayed}>{tooltip}</Tooltip>}
      </>
    );
  }
);
