import { useMemo } from 'react';
import { Box, Grid, Link, Typography, Skeleton, Rating } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import SendIcon from '@mui/icons-material/Send';
import { getConfig } from '@finanzcheck/ti-shared-ui/utils/tenant';

import { AvatarImage } from '../../../Operator/AvatarImage';
import {
  isFemale,
  isMale,
} from '@finanzcheck/ti-shared-ui/constants/domain/user.interface';
import { useOperator } from '../../../Context/Operator';
import { getCurrentTenantConfig } from '../../../../utils/tenant';
import { useDashboardState } from '../../../Context/Dashboard';
import { AppointmentSection } from './AppointmentSection';
import { useIsLoggedIn } from '../../../../hooks/useIsLoggedIn';
import { config } from '../../../Operator/config';

export const Advisor = () => {
  const { domain } = getCurrentTenantConfig();

  const { operator, status } = useOperator();
  const isLoggedIn = useIsLoggedIn();

  const { OperatorMale, OperatorFemale, OperatorDefault, OperatorNeutral } =
    getConfig(domain, config);
  const {
    state: { dashboard },
  } = useDashboardState();

  const operatorImage: string = useMemo(() => {
    if (status === 'loading') {
      return OperatorNeutral;
    }

    if (
      !dashboard ||
      operator?.name === 'Matthias Behrens' ||
      operator?.name === 'Tina Möller'
    ) {
      return OperatorDefault;
    }

    if (isMale(operator?.gender)) {
      return OperatorMale;
    }

    if (isFemale(operator?.gender)) {
      return OperatorFemale;
    }

    return OperatorNeutral;
  }, [
    operator,
    OperatorMale,
    OperatorFemale,
    OperatorDefault,
    OperatorNeutral,
    dashboard,
    status,
  ]);

  return (
    <>
      <Grid item>
        <Box
          px={2}
          pt={2}
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box>
            {['idle', 'loading'].includes(status) ? (
              <Skeleton variant="circular" width={56} height={56} />
            ) : (
              <AvatarImage alt={operator!.name} imgSrc={operatorImage} />
            )}
          </Box>
          <Box pl={2} py={2}>
            <Typography variant="subtitle1">
              <b>Wir sind hier, um Sie zu unterstützen.</b>
            </Typography>
            <Typography variant="subtitle2">
              <Rating
                sx={{
                  iconFilled: {
                    color: 'success.main',
                  },
                }}
                name="readOnly"
                value={5}
                size="small"
                readOnly
              />
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item>
        <Box
          px={2}
          py={2}
          mx={2}
          my={2}
          sx={(t) => ({
            border: `1px solid ${t.palette.grey[200]}`,
            borderRadius: '4px',
          })}
        >
          <Box
            pb={2}
            sx={(t) => ({
              borderBottom: `1px solid ${t.palette.grey[200]}`,
              display: 'flex',
              alignItems: 'flex-start',
            })}
          >
            <Box>
              <PhoneIcon
                sx={{
                  width: '18px',
                  height: 'auto',
                  marginTop: '6px',
                }}
              />
            </Box>
            <Box pl={2}>
              {['idle', 'loading'].includes(status) ? (
                <Skeleton variant="rectangular" height={20} />
              ) : (
                <Link
                  color="textPrimary"
                  href={`tel:${operator?.phone}`}
                  variant="subtitle1"
                >
                  <b>{operator?.phone}</b>
                </Link>
              )}
              <Typography
                variant="subtitle2"
                sx={(t) => ({
                  color: t.palette.grey[600],
                })}
              >
                Kostenlose Beratung
              </Typography>
            </Box>
          </Box>
          <Box
            sx={(t) => ({
              borderBottom: isLoggedIn
                ? `1px solid ${t.palette.grey[200]}`
                : 'none',
              display: 'flex',
              alignItems: 'flex-start',
              paddingBottom: isLoggedIn ? t.spacing(2) : t.spacing(1),
            })}
            py={2}
          >
            <Box>
              <SendIcon
                sx={{
                  width: '18px',
                  height: 'auto',
                  marginTop: '6px',
                }}
              />
            </Box>
            <Box pl={2}>
              {['idle', 'loading'].includes(status) ? (
                <Skeleton variant="rectangular" width={143} height={20} />
              ) : (
                <Link
                  color="textSecondary"
                  href={`mailto:${operator?.email}`}
                  variant="subtitle1"
                >
                  E-Mail an uns
                </Link>
              )}
            </Box>
          </Box>
          {isLoggedIn && <AppointmentSection />}
        </Box>
      </Grid>
    </>
  );
};
