import { useCallback, useLayoutEffect, useRef } from 'react';

function useResizeObserver<T extends HTMLElement>(
  callback: (target: T, entry: ResizeObserverEntry) => void
) {
  const ref = useRef<T>(null);

  // Reference: https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserver#observation_errors
  const observerCallback: ResizeObserverCallback = useCallback(
    (entries: ResizeObserverEntry[]) => {
      const element = ref?.current;

      if (!element) {
        return;
      }

      window.requestAnimationFrame((): void | undefined => {
        if (!Array.isArray(entries) || !entries.length) {
          return;
        }
        callback(element, entries[0]);
      });
    },
    [callback]
  );

  useLayoutEffect(() => {
    const element = ref?.current;

    if (!element) {
      return;
    }

    const observer = new ResizeObserver(observerCallback);

    observer.observe(element);
    return () => {
      observer.disconnect();
    };
  }, [callback, observerCallback, ref]);

  return ref;
}

export default useResizeObserver;
