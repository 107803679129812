import { AdapterProvider } from '@finanzcheck/ti-shared-ui/components/Context/Adapter';
import { UploadCallback } from '@finanzcheck/ti-shared-ui/components/DocumentUpload';
import { DocumentsToUpload } from '@finanzcheck/ti-shared-ui/components/DocumentUpload/reducer';
import { IApplication } from '@finanzcheck/ti-shared-ui/constants/interfaces/Application';
import useTrack from '@finanzcheck/ti-shared-ui/hooks/useTrack';
import { TrackingEvent } from '@finanzcheck/ti-shared-ui/utils/tracking/events.interface';
import { acceptReuse, fetchDacReuseInfo } from 'adapter/dacReuseAdapter';
import { uploadDocuments } from 'adapter/documentUpload';
import { emailAdapter } from 'adapter/emailAdapter';
import {
  sendCheckoutStartedEvent,
  sendClickoutStarted,
  sendVIQESClickOutStatus,
} from 'adapter/eventAdapter';
import { useUserState } from 'components/Context/UserContext';
import { PropsWithChildren, useCallback } from 'react';

const useUpload = (application?: IApplication) => {
  const track = useTrack();
  const {
    user: {
      details: { personId = '' },
    },
  } = useUserState();

  const handleFileUpload = useCallback(
    async (
      documents: DocumentsToUpload,
      uploadGroupHash: string,
      uploadCallback: UploadCallback
    ) => {
      if (!application) {
        throw new Error('No application found');
      }
      track({
        event: TrackingEvent.DocumentUploadSubmitClicked,
        payload: {
          bank: application.bankName,
          applicationUuid: application.uuid,
          leadId: application.loanLeadUuid,
        },
        optimizely: true,
      });
      try {
        await uploadDocuments(
          application.uuid,
          application.loanLeadUuid,
          documents,
          personId,
          uploadGroupHash,
          uploadCallback
        );
      } catch (e) {
        track({
          event: TrackingEvent.DocumentUploadError,
          payload: {
            errorType: e.message || 'Generic FileUploadError',
          },
          optimizely: true,
        });
      }
    },
    [application, personId, track]
  );
  return handleFileUpload;
};

export const Dataplane: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <AdapterProvider
      fetchReuseInfo={fetchDacReuseInfo}
      acceptReuse={acceptReuse}
      emailAdapter={emailAdapter}
      useUpload={useUpload}
      sendCheckoutStartedEvent={sendCheckoutStartedEvent}
      sendClickoutStarted={sendClickoutStarted}
      sendVIQESClickOutStatus={sendVIQESClickOutStatus}
    >
      {children}
    </AdapterProvider>
  );
};
