import {
  Box,
  Grid,
  Typography,
  Drawer,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import CloseButton from '../UI/CloseButton';
import { PropsWithChildren } from 'react';

export interface IHeaderDrawerProps {
  isDrawerShown: boolean;
  title: string;
  onCloseDrawer: () => void;
}
export const HeaderDrawer: React.FC<PropsWithChildren<IHeaderDrawerProps>> = ({
  isDrawerShown,
  onCloseDrawer,
  title,
  children,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Drawer
      anchor={isMobile ? 'bottom' : 'right'}
      open={isDrawerShown}
      onClose={onCloseDrawer}
      sx={{
        '.MuiPaper-root': { borderRadius: '12px 12px 0 0' },
        boxShadow:
          '0px 8px 10px -5px rgba(0,0,0,0.2), 0px 16px 24px 2px rgba(0,0,0,0.14), 0px 6px 30px 5px rgba(0,0,0,0.12)',
      }}
    >
      <Grid
        justifyContent="center"
        sx={{
          borderBottom: `1px solid ${theme.palette.grey[200]}`,
          position: 'relative',
        }}
        container
      >
        <CloseButton onClick={onCloseDrawer} />
        <Box px={2} py={2}>
          <Typography variant="subtitle1">
            <b>{title}</b>
          </Typography>
        </Box>
      </Grid>
      {children}
    </Drawer>
  );
};
