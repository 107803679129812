declare global {
  interface Window {
    sitespect: string | undefined;
  }
}

export interface ISitespectVariants {
  [variantId: number]: boolean;
}

export interface ISitespectCampaigns {
  [campaignId: number]: number[];
}

export interface ICampaignsAndVariants {
  variants: ISitespectVariants;
  campaigns: ISitespectCampaigns;
}

export function getCampaignsAndVariants(): ICampaignsAndVariants {
  if (typeof window.sitespect !== 'string') {
    return { campaigns: {}, variants: {} };
  }

  return window.sitespect
    .split('|')
    .map((campaignAndVariant = '') => campaignAndVariant.split('='))
    .reduce(
      (previous, [campaign = '', variant = '']) => {
        const previousTyped: { campaigns: ISitespectCampaigns } = previous;
        const campaignId = +campaign.substring(1);
        const [variantString, isInVariant] = variant.split('/');
        const variantId = +variantString.substring(2);

        return {
          campaigns: {
            ...previous.campaigns,
            [campaignId]: [
              ...(previousTyped.campaigns[campaignId] || []),
              variantId,
            ],
          },
          variants: {
            ...previous.variants,
            [variantId]: +isInVariant === 1,
          },
        };
      },
      { variants: {}, campaigns: {} }
    );
}

export function hasActiveVariant(
  variants: ISitespectVariants,
  variantIds: number[]
): boolean {
  return variantIds
    .map((variantId) => variants[variantId])
    .filter(Boolean)
    .some((isInVariant) => isInVariant);
}
