import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useState,
} from 'react';

interface ILoginContextType {
  email: string;
  rememberLoginEmail: (loginEmail: string) => void;
  redirectUrl?: string;
  setRedirectUrl: (url: string) => void;
  retryLogin: boolean;
  enableLoginRetry: () => void;
}

export const LoginContext = createContext<ILoginContextType>({
  email: '',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  rememberLoginEmail: () => {},
  retryLogin: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  enableLoginRetry: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setRedirectUrl: () => {},
});

const LoginProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [email, rememberLoginEmail] = useState('');
  const [redirectUrl, setRedirectUrl] = useState('');
  const [retryLogin, setRetryLogin] = useState(false);

  const enableLoginRetry = useCallback(() => setRetryLogin(true), []);

  return (
    <LoginContext.Provider
      value={{
        email,
        rememberLoginEmail,
        retryLogin,
        enableLoginRetry,
        redirectUrl,
        setRedirectUrl,
      }}
    >
      {children}
    </LoginContext.Provider>
  );
};

export default LoginProvider;

export const useLogin = () => useContext(LoginContext);
