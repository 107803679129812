import { IConfig, TenantDomain } from '@finanzcheck/ti-shared-ui/utils/tenant';

interface DomainConfig {
  description: string;
  iconColor: string;
  tenantDomainName: string;
  noScriptContent: string;
  font: string;
}

export const config: IConfig<DomainConfig> = {
  [TenantDomain.Finanzcheck]: {
    tenantDomainName: TenantDomain.Finanzcheck,
    description:
      'FINANZCHECK - Das TÜV geprüfte Onlineportal für Ihren günstigen Kredit. Starten Sie noch heute Ihren Vergleich und finden Sie mit uns für sich das optimale Angebot.',
    iconColor: '#165297',
    font: 'lato',
    noScriptContent:
      'Bitte aktivieren Sie JavaScript um den FINANZCHECK Kreditvergleich verwenden zu können.',
  },
  [TenantDomain.Smava]: {
    tenantDomainName: TenantDomain.Smava,
    description:
      'SMAVA Kredite vergleichen: ☎ Telefonische Expertenberatung ✓ Exklusive Kreditangebote ✓ Schufaneutral ✓ Schnell &amp;amp; unkompliziert ➤ Zum günstigen Kredit!',
    iconColor: '#39A949',
    font: 'roboto',
    noScriptContent:
      'Bitte aktivieren Sie JavaScript um den SMAVA Kreditvergleich verwenden zu können.',
  },
};
