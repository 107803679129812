import { Tenant } from '@finanzcheck/teal-mui-theme';
import { getDevTenant } from '../dev';

export const enum TenantDomain {
  Finanzcheck = 'finanzcheck',
  Smava = 'smava',
}

export type Environment = 'live' | 'stage' | 'shared' | 'development';

export interface Env {
  urls: {
    home: string;
  };
}

let env = 'shared';
export const setEnv = (e: string) => {
  env = e;
};
export const getEnv = () => {
  return env;
};

export const getCurrentEnvConfig = () => {
  const env = getEnv();
  const tenantConfig = getCurrentTenantConfig();
  return tenantConfig.envs[env as Environment] as Env;
};

export class TenantDomainError extends Error {
  constructor(origin: string) {
    super(`tenant domain error - unknown origin: ${origin}`);

    this.name = 'TenantDomainError';
  }
}

export interface ITenantConfig {
  domain: TenantDomain;
  tenant: Tenant;
  envs: {
    [e in Environment]: Env;
  };
}

export const tenantDomainMap: Record<string, ITenantConfig> = {
  [TenantDomain.Finanzcheck]: {
    domain: TenantDomain.Finanzcheck,
    tenant: Tenant.Finanzcheck,
    envs: {
      development: {
        urls: {
          home: 'https://home.finanzcheck.de',
        },
      },
      stage: {
        urls: {
          home: 'https://home.finanzcheck-stage.de',
        },
      },
      shared: {
        urls: {
          home: 'https://home.finanzcheck-shared.de',
        },
      },
      live: {
        urls: {
          home: 'https://home.finanzcheck.de',
        },
      },
    },
  },
  [TenantDomain.Smava]: {
    domain: TenantDomain.Smava,
    tenant: Tenant.Smava,
    envs: {
      development: {
        urls: {
          home: 'https://home.smava.de',
        },
      },
      stage: {
        urls: {
          home: 'https://home.smava-stage.de',
        },
      },
      shared: {
        urls: {
          home: 'https://home.smava-shared.de',
        },
      },
      live: {
        urls: {
          home: 'https://home.smava.de',
        },
      },
    },
  },
};

/**
 * This method is only exported for testing.
 * @see getCurrentTenantConfig
 */
export const getTenantConfigForCurrentDomain = (): ITenantConfig => {
  const { origin } = window.location;
  const matchedDomain = Object.keys(tenantDomainMap).find((domain) =>
    origin.includes(domain)
  );

  if (!matchedDomain) {
    if (env !== 'live') {
      return tenantDomainMap[getDevTenant()];
    }
    throw new TenantDomainError(origin);
  }

  return tenantDomainMap[matchedDomain];
};

let currentTenantConfig: ITenantConfig | undefined;
let lastLocationOrigin: string;

export const getCurrentTenantConfig = () => {
  const { origin } = window.location;
  if (!currentTenantConfig || origin !== lastLocationOrigin) {
    currentTenantConfig = getTenantConfigForCurrentDomain();
  }

  lastLocationOrigin = origin;

  return currentTenantConfig;
};

export type IConfig<T> = {
  [key in TenantDomain]: T;
};

export function getConfig<T>(domain: TenantDomain, config: IConfig<T>): T {
  if (typeof config[domain] === 'undefined') {
    throw new Error(`Invalid Domain ${domain}`);
  }

  return config[domain];
}
