import { memo, Suspense, useEffect } from 'react';
import { Route, Switch, useHistory } from 'react-router';

import {
  notFoundComponent,
  routeComponents,
  routes,
} from '../../constants/routes';

import { UnAuthorizedRoute } from './UnAuthorizedRoute';
import { AuthorizedRoute } from './AuthorizedRoute';
import { RouteLoader } from './RouteLoader';

export interface IAngeboteParams {
  applicationUuid: string;
}

export const RouteSwitchView = memo(() => {
  const history = useHistory();

  useEffect(() => {
    const unlistenHistory = history.listen(() => {
      requestAnimationFrame(() => {
        window.scrollTo(0, 0);
      });
    });

    return () => {
      unlistenHistory();
    };
  }, [history]);

  return (
    <Suspense fallback={<RouteLoader />}>
      <Switch>
        <AuthorizedRoute
          allowGuest
          exact
          component={routeComponents.dashboard}
          path={routes.dashboard}
        />
        <AuthorizedRoute
          allowGuest
          exact
          component={routeComponents.urlForwarding}
          path={routes.urlForwarding}
        />
        <Route component={routeComponents.faq} path={routes.faq} />
        <AuthorizedRoute
          allowGuest
          component={routeComponents.accountCheck}
          path={routes.accountCheck}
        />
        <AuthorizedRoute
          allowGuest
          exact
          component={routeComponents.applicationOverview}
          path={routes.applicationOverview}
        />
        <AuthorizedRoute
          allowGuest
          exact
          component={routeComponents.application}
          path={routes.application}
        />
        <UnAuthorizedRoute
          exact
          component={routeComponents.login}
          path={routes.login}
        />
        <AuthorizedRoute
          exact
          component={routeComponents.logout}
          path={routes.logout}
        />
        <UnAuthorizedRoute
          exact
          component={routeComponents.loginPassword}
          path={routes.loginPassword}
        />
        <UnAuthorizedRoute
          exact
          component={routeComponents.forgotPassword}
          path={routes.forgotPassword}
        />
        <UnAuthorizedRoute
          exact
          component={routeComponents.registration}
          path={routes.registration}
        />
        <UnAuthorizedRoute
          exact
          component={routeComponents.magicLoginSent}
          path={routes.magicLoginSent}
        />
        <UnAuthorizedRoute
          exact
          component={routeComponents.resetPassword}
          path={routes.resetPassword}
        />
        <UnAuthorizedRoute
          exact
          component={routeComponents.passwordExpired}
          path={routes.passwordExpired}
        />
        <UnAuthorizedRoute
          component={routeComponents.invite}
          path={routes.invite}
        />
        <AuthorizedRoute
          allowGuest
          component={routeComponents.taurine}
          path={routes.taurine}
        />
        <Route path={routes.viSuccess} component={routeComponents.viSuccess} />
        <Route component={notFoundComponent} />
      </Switch>
    </Suspense>
  );
});
