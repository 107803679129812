import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'url-search-params-polyfill';

/**
 * Promise.allSettled polyfill for IE11 and old Safari browsers
 */
Promise.allSettled =
  Promise.allSettled ||
  ((promises: Promise<unknown>[]) =>
    Promise.all(
      promises.map((promise) =>
        promise
          .then((value) => ({
            status: 'fulfilled',
            value,
          }))
          .catch((reason) => ({
            status: 'rejected',
            reason,
          }))
      )
    ));
