import { useCallback } from 'react';

import {
  AffiliateEvents,
  GenericEvents,
  GenericEventType,
  GTMEvents,
  IEmitterFunctions,
} from '../constants/genericEvents';
import { IAffiliateData, ILoanRequest } from '../constants/interfaces/Metadata';
import { IApplication } from '../constants/interfaces/Application';

import {
  Once,
  restrictApplication,
  restrictLoanRequest,
} from '../utils/trackingUtils';
import useTrack from '../hooks/useTrack';
import { EventEmitterError } from '../errors/eventEmitterError';
import { logError } from '../utils/log';
import { getFcId } from '../utils/cookie';

export let emitterFunctions: IEmitterFunctions = {
  gtmEvent: (
    event: GTMEvents,
    _properties:
      | { application: IApplication }
      | { loanRequest: ILoanRequest }
      | { loanRequestId: string }
  ): void => {
    logError(new EventEmitterError('gtm event emitter not set', event));
  },
  affiliateEvent: (
    event: AffiliateEvents,
    _properties: { affiliateData: IAffiliateData; loanRequestId: string }
  ): void => {
    logError(new EventEmitterError('affiliate event emitter not set', event));
  },
  genericEvent: (event: GenericEvents): void => {
    logError(new EventEmitterError('generic event emitter not set', event));
  },
};

export const setEventEmitterFunctions = ({
  gtmEvent,
  affiliateEvent,
  genericEvent,
}: IEmitterFunctions) => {
  emitterFunctions = {
    ...emitterFunctions,
    gtmEvent,
    affiliateEvent,
    genericEvent,
  };
};

export const useEventEmitter = () => {
  const { affiliateEvent, genericEvent } = emitterFunctions;
  const track = useTrack();

  const emitAffiliateEvent = useCallback(
    (
      event: AffiliateEvents,
      properties: { affiliateData: IAffiliateData; loanRequestId: string }
    ) => {
      Once(`${event}-${properties.loanRequestId}`, () => {
        affiliateEvent &&
          affiliateEvent(event, { ...properties, fcId: getFcId() });
      });
    },
    [affiliateEvent]
  );

  const emitGenericEvent = useCallback(
    (event: GenericEvents) => {
      genericEvent && genericEvent(event);
    },
    [genericEvent]
  );

  return {
    sendGTMEvent: {
      EventLoadingAnswerQuestion: (payload: { action: string }) =>
        track({
          event: GTMEvents.Loading_QuestionAnswer,
          payload,
          type: 'track',
          global: true,
          legacyDestination: 'gtm',
          optimizely: true,
        }),
      EventCallbackRequest: (datetime: string) =>
        track({
          event: GTMEvents.RequestCallback,
          payload: { action: datetime },
          type: 'track',
          global: true,
          legacyDestination: 'gtm',
          optimizely: true,
        }),
      EventBankSalable: (application: IApplication) =>
        Once(`Offer-${application.bankName}`, () =>
          track({
            event: GTMEvents.Bank_Offer,
            payload: { application: restrictApplication(application) },
            type: 'track',
            global: true,
            legacyDestination: 'gtm',
            optimizely: true,
          })
        ),
      OfferSelected: (application: IApplication) =>
        track({
          event: GTMEvents.ApplicationListView_ApplicationList_offerSelected,
          payload: { application: restrictApplication(application) },
          type: 'track',
          global: true,
          legacyDestination: 'gtm',
          optimizely: true,
        }),

      EventApplicationsListOfferSelected: (application: IApplication) =>
        track({
          event: GTMEvents.ApplicationListView_OfferSelected,
          payload: { application: restrictApplication(application) },
          type: 'track',
          global: true,
          legacyDestination: 'gtm',
          optimizely: true,
        }),
      EventApplicationsListEmptyRendered: (loanRequest: ILoanRequest) =>
        track({
          event: GTMEvents.ApplicationsListEmptyView_Rendered,
          payload: { loanRequest: restrictLoanRequest(loanRequest) },
          type: 'track',
          global: true,
          legacyDestination: 'gtm',
          optimizely: true,
        }),
      IsSalableOnce: (loanRequest: ILoanRequest, loanRequestId: string) =>
        Once(`IsSalableOnce-${loanRequestId}`, () =>
          track({
            event: GTMEvents.IsSalable,
            payload: {
              loanRequest: restrictLoanRequest(loanRequest),
              loanRequestId,
            },
            type: 'track',
            global: true,
            legacyDestination: 'gtm',
            optimizely: true,
          })
        ),
    },
    sendAffiliateEvents: {
      AffiliateIsSalable: (
        affiliateData: IAffiliateData,
        loanRequestId: string
      ) =>
        emitAffiliateEvent(AffiliateEvents.Affiliate_isSalable, {
          affiliateData,
          loanRequestId,
        }),

      AffiliateNonSalable: (
        affiliateData: IAffiliateData,
        loanRequestId: string
      ) =>
        emitAffiliateEvent(AffiliateEvents.Affiliate_nonSalable, {
          affiliateData,
          loanRequestId,
        }),
    },
    sendGenericEvents: {
      EventScrollTop: () =>
        emitGenericEvent({ event: GenericEventType.SCROLL_TOP }),
      EventUpdateResume: (resume?: string) => {
        if (!resume) {
          return;
        }

        emitGenericEvent({
          event: GenericEventType.UPDATE_RESUME,
          data: resume,
        });
      },
    },
    sendSegmentEvents: {
      IsSalableOnce: (loanRequestId: string, track: () => boolean) => {
        return Once(`segment-salable-${loanRequestId}`, track);
      },
    },
  };
};
