export type GenderType = 'male' | 'female' | undefined;

export function isMale(gender: GenderType) {
  return gender?.toLowerCase() === 'male';
}

export function isFemale(gender: GenderType) {
  return gender?.toLowerCase() === 'female';
}

export function getSalutation(gender: GenderType) {
  if (isMale(gender)) {
    return 'Herr';
  }

  return 'Frau';
}

export interface IUser {
  firstName: string;
  lastName: string;
  uuid: string;
  gender: GenderType;
  email: string;
}
