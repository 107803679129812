import { FunctionComponent } from 'react';
import { HeaderDrawer } from '@finanzcheck/ti-shared-ui/components/Header/HeaderDrawer';

import { Hotline } from './Sections/Hotline';
import { FAQ } from './Sections/FAQ';
import { Advisor } from './Sections/Advisor';
import OperatorProvider from '../../Context/Operator';

interface HelpAdnSupportViewProps {
  isHelpDrawerShown: boolean;
  onCloseHelpDrawer: () => void;
}

export const HelpAndSupportView: FunctionComponent<HelpAdnSupportViewProps> = ({
  isHelpDrawerShown,
  onCloseHelpDrawer,
}) => {
  return (
    <HeaderDrawer
      title="Hilfe & Support"
      isDrawerShown={isHelpDrawerShown}
      onCloseDrawer={onCloseHelpDrawer}
    >
      <OperatorProvider>
        <Advisor />
      </OperatorProvider>
      <Hotline />
      <FAQ />
    </HeaderDrawer>
  );
};
