import { useContext } from 'react';
import { Experiments } from '../utils/optimizely/experiments';
import { TenantDomain, getCurrentTenantConfig } from '../utils/tenant';
import {
  useDecision as nativeUseDecision,
  OptimizelyContext,
} from '@optimizely/react-sdk';

export const useDACFlowInCheckoutABTest = () => {
  const { domain } = getCurrentTenantConfig();
  const isSmava = domain === TenantDomain.Smava;
  const { optimizely } = useContext(OptimizelyContext);

  // We are calling the useDecision hook conditionally to avoid errors on console if app is not wrapped inside optimizely provider(affiliate cases!)
  const decision =
    optimizely &&
    nativeUseDecision(Experiments.PLAYERS_DAC_FLOW_IN_CHECKOUT_AB_TEST);

  if (!isSmava) {
    return {
      variationKey: 'variation_a',
    };
  }

  return {
    variationKey:
      decision && decision?.[0]?.variationKey
        ? decision?.[0]?.variationKey
        : 'variation_a',
  };
};
