import { apiClient } from 'api';
import { DacResuseInfo } from '@finanzcheck/ti-shared-ui/constants/domain/dac.interface';

export const fetchDacReuseInfo = async (
  clientUUID: string,
  loanRequestId: string,
  personId: string,
  applicationUUID: string,
  debtorIndex: 'primary' | 'secondary'
): Promise<DacResuseInfo> => {
  const response = await apiClient('home').post<DacResuseInfo>(
    `/digital-account-check/validate`,
    {
      applicationUUID,
      debtorIndex,
    }
  );
  return response.data;
};

export const acceptReuse = async (
  dacInfo: DacResuseInfo,
  clientUuid: string,
  applicationUuid: string,
  debtorIndex: 'primary' | 'secondary'
) => {
  const request = {
    applicationUUID: applicationUuid,
    dac: dacInfo.dac,
    performedAt: dacInfo.dacPerformedAt,
    debtorIndex: debtorIndex,
  };

  const response = await apiClient('home').post<DacResuseInfo>(
    `/digital-account-check/select`,
    request
  );
  return response.data;
};
