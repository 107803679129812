import { Box, Grid, Typography, Link } from '@mui/material';
import { HeaderURLs } from './constants';
import { getCurrentTenantConfig } from '../../../../utils/tenant';
import { useIsLoggedIn } from '../../../../hooks/useIsLoggedIn';
import { TopQuestion } from './TopQuestion';

const topQuestions = [
  {
    query: 'Wie lange dauert die Bearbeitung des Kreditantrags? ',
    url: '/faq/schnelle-einreichung-so-geht-es/ablauf/wie-lange-dauert-die-bearbeitung-des-kreditantrags',
  },
  {
    query: 'Warum erhalte ich kein Angebot mit "besseren Zinsen"?',
    url: '/faq/wie-bekomme-ich-noch-bessere-angebote/wieso-weshalb-warum/warum-erhalte-ich-kein-angebot-mit-besseren-zinsen',
  },
  {
    query: 'Warum möchte die Bank meine Kontoauszüge sehen?',
    url: '/faq/schnelle-einreichung-so-geht-es/die-erforderlichen-unterlagen/warum-moechte-die-bank-meine-kontoauszuege-sehen',
    last: true,
  },
];

//TODO: Change all english content to german
export const FAQ = () => {
  const { domain } = getCurrentTenantConfig();
  const isLoggedIn = useIsLoggedIn();

  return (
    <>
      <Box
        px={2}
        pt={1}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="h4">FAQ</Typography>
        <Typography
          variant="subtitle2"
          sx={{
            textDecoration: 'underline',
            cursor: 'pointer',
          }}
        >
          <Link
            href={HeaderURLs[domain].faq(isLoggedIn)}
            target="_blank"
            color="textSecondary"
            variant="body1"
          >
            Alle anzeigen
          </Link>
        </Typography>
      </Box>
      <Grid item>
        <Box
          px={2}
          mx={2}
          my={2}
          sx={(t) => ({
            border: `1px solid ${t.palette.grey[200]}`,
            borderRadius: '4px',
          })}
        >
          {topQuestions &&
            topQuestions.map((item) => (
              <TopQuestion
                key={item.query}
                query={item.query}
                url={item.url}
                last={item.last}
              />
            ))}
        </Box>
      </Grid>
    </>
  );
};
