import { FunctionComponent, SVGProps } from 'react';

export const SurveyIcon: FunctionComponent<SVGProps<SVGSVGElement>> = () => (
  <svg width="252" height="180" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.044 53.174c0-8.626 6.993-15.618 15.618-15.618H173.05c8.625 0 15.618 6.992 15.618 15.618v110.321c0 8.626-6.993 15.618-15.618 15.618H16.662c-8.625 0-15.618-6.992-15.618-15.618V53.174Z"
      fill="#EDFAEB"
      stroke="#F7F7F7"
      strokeWidth=".764"
    />
    <g filter="url(#a)">
      <path
        d="M22.259 31.577c0-8.837 7.163-16 16-16h156.387c8.836 0 16 7.164 16 16v110.321c0 8.837-7.164 16-16 16H38.259c-8.837 0-16-7.163-16-16V31.577Z"
        fill="#fff"
      />
      <path
        d="M22.64 31.577c0-8.626 6.993-15.618 15.619-15.618h156.387c8.625 0 15.618 6.992 15.618 15.618v110.321c0 8.626-6.993 15.618-15.618 15.618H38.259c-8.626 0-15.618-6.992-15.618-15.618V31.577Z"
        stroke="#F7F7F7"
        strokeWidth=".764"
      />
    </g>
    <rect
      x="44.261"
      y="50.055"
      width="108.632"
      height="8.251"
      rx="4.125"
      fill="#E8E8E8"
    />
    <rect
      x="44.261"
      y="67.244"
      width="55.003"
      height="8.251"
      rx="4.125"
      fill="#E8E8E8"
    />
    <g clipPath="url(#b)">
      <path
        d="M75.552 114.974c0 5.253-2.59 9.902-6.561 12.74-2.562 1.831-5.699 2.1-9.087 2.1-8.642 0-14.224-5.538-14.224-14.18 0-5.113 1.029-10.313 4.821-13.169a15.577 15.577 0 0 1 9.403-3.139c8.642 0 15.648 7.006 15.648 15.648Z"
        fill="#FFC1C1"
      />
      <path
        d="M70.499 126.49a15.595 15.595 0 0 1-10.594 4.132c-8.642 0-15.648-7.006-15.648-15.648 0-5.687 3.034-10.666 7.572-13.405a15.607 15.607 0 0 0-5.053 11.516c0 8.642 7.005 15.647 15.647 15.647 2.955 0 5.718-.819 8.076-2.242Z"
        fill="#EE9797"
      />
    </g>
    <path
      d="M54.317 113.955a.667.667 0 0 1-.655-.536.67.67 0 0 1 .394-.75l3.548-1.519-2.986-2.009a.669.669 0 0 1 .743-1.112l4.018 2.679a.666.666 0 0 1 .17.95.667.667 0 0 1-.278.221l-4.687 2.009a.673.673 0 0 1-.267.067Zm13.605 8.001a.67.67 0 0 1-.844-.422 5.593 5.593 0 0 0-5.396-3.562 5.592 5.592 0 0 0-5.39 3.562.67.67 0 0 1-1.238.084.67.67 0 0 1-.034-.512 7.026 7.026 0 0 1 6.662-4.473 7.028 7.028 0 0 1 6.662 4.473.668.668 0 0 1-.422.85Zm1.74-8.403a.666.666 0 0 1-.615.402.653.653 0 0 1-.261-.054l-4.687-2.008a.677.677 0 0 1-.402-.554.672.672 0 0 1 .295-.618l4.017-2.678a.674.674 0 0 1 .515-.125.658.658 0 0 1 .444.288.666.666 0 0 1-.016.767.671.671 0 0 1-.2.181l-2.986 2.009 3.549 1.52a.667.667 0 0 1 .348.864v.006Z"
      fill="#165297"
      stroke="#165297"
      strokeWidth=".489"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M53.306 117.491a1.551 1.551 0 0 1-2.787-.939c0-.719 1.09-2.519 1.443-3.084a.126.126 0 0 1 .215.002l.279.463c.46.767 1.167 2.04 1.167 2.619 0 .343-.112.66-.3.917l-.017.022Z"
      fill="#165297"
    />
    <g clipPath="url(#c)">
      <path
        d="M116.629 114.974c0 5.253-2.589 9.902-6.561 12.74-2.562 1.831-5.699 2.1-9.087 2.1-8.642 0-14.224-5.538-14.224-14.18 0-5.113 1.029-10.313 4.821-13.169a15.577 15.577 0 0 1 9.403-3.139c8.642 0 15.648 7.006 15.648 15.648Z"
        fill="#FFC998"
      />
      <path
        d="M111.576 126.49a15.595 15.595 0 0 1-10.594 4.132c-8.642 0-15.648-7.006-15.648-15.648 0-5.687 3.034-10.666 7.572-13.405a15.608 15.608 0 0 0-5.053 11.516c0 8.642 7.006 15.647 15.647 15.647 2.955 0 5.719-.819 8.076-2.242Z"
        fill="#E49E5D"
      />
    </g>
    <path
      d="M95.505 109.693a1.759 1.759 0 0 1 3.005-1.245 1.761 1.761 0 1 1-3.005 1.245Zm10.562 0a1.763 1.763 0 0 1 2.104-1.727 1.759 1.759 0 0 1 1.283 2.401 1.765 1.765 0 0 1-2.871.571 1.761 1.761 0 0 1-.516-1.245Zm3.595 9.388a1.178 1.178 0 0 1 .124.894 1.171 1.171 0 0 1-2.156.281 5.864 5.864 0 0 0-5.083-2.935 5.872 5.872 0 0 0-5.084 2.935 1.17 1.17 0 0 1-1.172.584 1.17 1.17 0 0 1-.983-.865 1.175 1.175 0 0 1 .123-.894 8.217 8.217 0 0 1 14.231 0Z"
      fill="#165297"
    />
    <g clipPath="url(#d)">
      <path
        d="M157.703 114.974c0 5.253-2.589 9.902-6.561 12.74-2.562 1.831-5.698 2.1-9.087 2.1-8.642 0-14.224-5.538-14.224-14.18 0-5.113 1.029-10.313 4.822-13.169a15.573 15.573 0 0 1 9.402-3.139c8.642 0 15.648 7.006 15.648 15.648Z"
        fill="#FFD764"
      />
      <path
        d="M152.65 126.49a15.594 15.594 0 0 1-10.594 4.132c-8.642 0-15.648-7.006-15.648-15.648 0-5.687 3.034-10.666 7.572-13.405a15.607 15.607 0 0 0-5.053 11.516c0 8.642 7.006 15.647 15.648 15.647 2.954 0 5.718-.819 8.075-2.242Z"
        fill="#FFAA4D"
      />
    </g>
    <path
      d="M136.581 110.475a1.758 1.758 0 0 1 2.104-1.726 1.761 1.761 0 1 1-2.104 1.726Zm12.323-1.76c-.349 0-.689.103-.978.297a1.753 1.753 0 0 0-.749 1.807 1.763 1.763 0 0 0 2.4 1.283 1.76 1.76 0 0 0 .571-2.872 1.76 1.76 0 0 0-1.244-.515Zm.586 8.215h-11.735a1.173 1.173 0 1 0 0 2.347h11.735a1.173 1.173 0 1 0 0-2.347Z"
      fill="#165297"
    />
    <g clipPath="url(#e)">
      <path
        d="M198.778 114.974c0 5.253-2.589 9.902-6.561 12.74-2.561 1.831-5.698 2.1-9.087 2.1-8.642 0-14.224-5.538-14.224-14.18 0-5.113 1.029-10.313 4.822-13.169a15.573 15.573 0 0 1 9.402-3.139c8.642 0 15.648 7.006 15.648 15.648Z"
        fill="#BFFFD5"
      />
      <path
        d="M193.725 126.49a15.592 15.592 0 0 1-10.594 4.132c-8.642 0-15.648-7.006-15.648-15.648 0-5.687 3.034-10.666 7.572-13.405a15.607 15.607 0 0 0-5.053 11.516c0 8.642 7.006 15.647 15.648 15.647 2.954 0 5.718-.819 8.075-2.242Z"
        fill="#7CEEA3"
      />
    </g>
    <path
      d="M178.438 110.475a1.758 1.758 0 0 1 2.104-1.726 1.761 1.761 0 1 1-2.104 1.726Zm10.562 0a1.758 1.758 0 0 1 2.104-1.726 1.761 1.761 0 1 1-2.104 1.726Zm3.595 7.042a8.223 8.223 0 0 1-7.116 4.107 8.217 8.217 0 0 1-7.115-4.107 1.174 1.174 0 0 1 2.032-1.175 5.875 5.875 0 0 0 10.167 0 1.183 1.183 0 0 1 .713-.553 1.167 1.167 0 0 1 1.241.42 1.165 1.165 0 0 1 .078 1.308Z"
      fill="#165297"
    />
    <g clipPath="url(#f)">
      <path
        d="M243.876 101.528c-2.559 7.033-8.29 11.995-14.99 13.859-4.321 1.202-8.652.035-13.188-1.616-11.569-4.211-16.343-14.345-12.132-25.913 2.491-6.845 6.402-13.306 12.871-15.28a22.191 22.191 0 0 1 14.116.379c11.569 4.21 17.534 17.002 13.323 28.571Z"
        fill="#93FBB6"
      />
      <path
        d="M231.501 114.482a22.21 22.21 0 0 1-16.196.369c-11.568-4.21-17.533-17.002-13.323-28.571 2.771-7.614 9.258-12.8 16.668-14.255-5.553 2.314-10.156 6.855-12.375 12.953-4.211 11.569 1.754 24.361 13.323 28.572a22.189 22.189 0 0 0 11.903.932Z"
        fill="#65E791"
      />
      <path
        d="m213.839 90.447 22.466 8.177c.805.293 1.173 1.206.789 1.956-2.852 5.544-9.536 8.205-15.643 5.982l-1.336-.486c-6.107-2.223-9.517-8.557-8.138-14.638.188-.822 1.057-1.284 1.862-.99Z"
        fill="#165297"
      />
      <g clipPath="url(#g)">
        <path
          d="M230.775 88.368a2.004 2.004 0 0 1 2.606-1.186 2.225 2.225 0 0 1 1.317 1.27 2.225 2.225 0 0 1 1.825-.126 2.008 2.008 0 0 1 1.234 2.584c-.681 1.87-3.421 2.471-5.092 3.128-.852-1.586-2.571-3.798-1.89-5.67Z"
          fill="#165297"
        />
      </g>
      <g clipPath="url(#h)">
        <path
          d="M217.945 83.698a2.003 2.003 0 0 1 2.606-1.186 2.226 2.226 0 0 1 1.317 1.27 2.225 2.225 0 0 1 1.825-.126 2.008 2.008 0 0 1 1.234 2.584c-.681 1.87-3.422 2.471-5.092 3.128-.852-1.586-2.572-3.798-1.89-5.67Z"
          fill="#165297"
        />
      </g>
    </g>
    <defs>
      <clipPath id="b">
        <path
          fill="#fff"
          transform="translate(44.259 99.326)"
          d="M0 0h31.295v31.295H0z"
        />
      </clipPath>
      <clipPath id="c">
        <path
          fill="#fff"
          transform="translate(85.334 99.326)"
          d="M0 0h31.295v31.295H0z"
        />
      </clipPath>
      <clipPath id="d">
        <path
          fill="#fff"
          transform="translate(126.409 99.326)"
          d="M0 0h31.295v31.295H0z"
        />
      </clipPath>
      <clipPath id="e">
        <path
          fill="#fff"
          transform="translate(167.484 99.326)"
          d="M0 0h31.295v31.295H0z"
        />
      </clipPath>
      <clipPath id="f">
        <path
          fill="#fff"
          transform="rotate(20 -80.458 627.03)"
          d="M0 0h44.583v44.583H0z"
        />
      </clipPath>
      <clipPath id="g">
        <path
          fill="#fff"
          transform="rotate(20 -125.972 698.038)"
          d="M0 0h8.917v8.917H0z"
        />
      </clipPath>
      <clipPath id="h">
        <path
          fill="#fff"
          transform="rotate(20 -119.145 659.316)"
          d="M0 0h8.917v8.917H0z"
        />
      </clipPath>
      <filter
        id="a"
        x="3.922"
        y=".296"
        width="225.061"
        height="178.996"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="3.056" />
        <feGaussianBlur stdDeviation="9.169" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0.124656 0 0 0 0 0.216667 0 0 0 0 0.122778 0 0 0 0.05 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1536_7126"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_1536_7126"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
