import { createContext, FC, useContext, useEffect, useState } from 'react';
import CobrowseIO from 'cobrowse-sdk-js';

import { config } from 'constants/config';
import { useUserState } from '../UserContext';
import {
  Feature,
  isFeatureActive,
} from '@finanzcheck/ti-shared-ui/utils/isFeatureActive';

interface ICoBrowseInstanceContext {
  sessionCode: string;
  isError: boolean;
  setSessionCode: React.Dispatch<React.SetStateAction<string>>;
  setIsError: React.Dispatch<React.SetStateAction<boolean>>;
}

interface ICoBowseInstanceProvider {
  children: React.ReactNode;
}

CobrowseIO.license = config.coBrowse.licenceKey;
const CoBrowseInstanceContext = createContext<ICoBrowseInstanceContext | null>(
  null
);

export const CoBrowseInstanceProvider: FC<ICoBowseInstanceProvider> = ({
  children,
}) => {
  const [sessionCode, setSessionCode] = useState<string>('');
  const [isError, setIsError] = useState(false);

  const { user } = useUserState();
  const isCoBrowseEnabled = isFeatureActive(Feature.CO_BROWSE);

  useEffect(() => {
    (async () => {
      await CobrowseIO.client(); // Ensure that CobrowseIO instance is available!
      CobrowseIO.customData = {
        user_email: user?.details?.email || '',
      };
    })();
  }, [user?.details?.email]);

  useEffect(() => {
    if (isCoBrowseEnabled) {
      CobrowseIO.start();
    }
  }, [isCoBrowseEnabled]);

  return (
    <CoBrowseInstanceContext.Provider
      value={{
        sessionCode,
        isError,
        setSessionCode,
        setIsError,
      }}
    >
      {children}
    </CoBrowseInstanceContext.Provider>
  );
};

export const useCoBrowseInstance = () => {
  const context = useContext(CoBrowseInstanceContext);
  if (!context) {
    console.warn('Please wrap component inside CoBowseInstanceProvider');
    return {
      sessionCode: '',
      isError: false,
      setSessionCode: () => undefined,
      setIsError: () => undefined,
    };
  }

  return context;
};
