import { TextField } from '@mui/material';
import React from 'react';

type Placeholder = 'T' | 'M' | 'J';

interface Props {
  placeholder: Placeholder;
  idx: number;
  onChange: (value?: number) => void;
  disabled?: boolean;
}

export const SingleInput: React.FC<Props> = ({
  placeholder,
  idx,
  onChange,
  disabled,
}) => {
  return (
    <TextField
      variant="outlined"
      placeholder={placeholder}
      inputMode="numeric"
      disabled={disabled}
      inputProps={{ maxLength: 1, pattern: '\\d*', 'aria-label': 'date' + idx }}
      onChange={(e) => {
        const nextField = document.querySelector<HTMLInputElement>(
          `[name=date${idx + 1}]`
        );
        const n = parseInt(e.currentTarget.value);
        if (isNaN(n)) {
          e.currentTarget.value = '';
        } else {
          onChange(n);
          if (nextField) {
            nextField.focus();
          } else {
            e.currentTarget.blur();
          }
        }
      }}
      onKeyDown={(e) => {
        if (e.key === 'Backspace') {
          const prevField = document.querySelector<HTMLInputElement>(
            `[name=date${idx - 1}]`
          );
          prevField?.focus();
        }
      }}
      onFocus={(e) => {
        e.currentTarget.value = '';
        onChange(undefined);
      }}
      color="primary"
      name={'date' + idx}
      sx={{
        width: '3em',
        input: {
          px: 0,
          textAlign: 'center',
        },
        'input:focus::placeholder': {
          color: 'transparent',
        },
      }}
    />
  );
};
