import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';

interface IDialogActionsViewProps {
  onUserConsentDisAgree?: () => void;
  onUserConsentAgree?: () => void;
}

export const DialogActionsView: React.FC<IDialogActionsViewProps> = ({
  onUserConsentDisAgree,
  onUserConsentAgree,
}) => (
  <DialogActions sx={{ pb: 4, px: 4 }}>
    <Button variant="outlined" fullWidth onClick={onUserConsentDisAgree}>
      Ablehnen
    </Button>
    <Button variant="contained" fullWidth onClick={onUserConsentAgree}>
      Zulassen
    </Button>
  </DialogActions>
);
