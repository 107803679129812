import { CognitoUser } from '@aws-amplify/auth';
import { Auth } from 'aws-amplify';

import { getMetaDataFromDomain } from '../domain';
import { amplifyConfig } from './amplifyConfig';
import { CognitoExceptionTypes } from './cognitoErrorTypes';
import { UsernameExistsError } from './errors/UsernameExistsError';
import { InvalidParameterError } from './errors/InvalidParameterError';
import { InvalidPasswordError } from './errors/InvalidPasswordError';
import { UnreachableError } from './errors/UnreachableError';
import { UserNotAuthorizedError } from './errors/UserNotAuthorizedError';

export interface IUserAttributes {
  clientUuid: string;
  customerUuid: string;
  customerMail: string;
  personId: string;
}

export interface ICognitoUserAttributes {
  'custom:client_uuid': string;
  'custom:customer_uuid': string;
  'custom:person_id': string;
  email: string;
  // eslint-disable-next-line camelcase
  email_verified: string;
  sub: string;
  applicationUuid: string;
  customerUuid: string;
  clientUuid: string;
}

type PreSignUpTypes =
  | 'SUCCESS'
  | 'USERNAME_EXISTS'
  | 'INVALID_DATA'
  | 'INVALID_PASSWORD'
  | 'INTERNAL_ERROR';

export type IEnrichedCognitoUser = CognitoUser;

export const initializeAmplify = (): void => {
  const domainEnvCognitoIdsMap = {
    smava: {
      shared: {
        userPoolId: 'eu-central-1_TRlOi33Bt',
        userPoolWebClientId: '2o5k0a202vn8vu56brv6e37qvi',
      },
      stage: {
        userPoolId: 'eu-central-1_n5PcFFEaX',
        userPoolWebClientId: '7j9u208ufnf7i1mlucfof4se8r',
      },
      live: {
        userPoolId: 'eu-central-1_MWdDgB0YD',
        userPoolWebClientId: '2vu2evm4aue1n4p69kfa89m7gn',
      },
    },
    finanzcheck: {
      shared: {
        userPoolId: 'eu-central-1_AThId2Peg',
        userPoolWebClientId: '1aq5nkcgudekolaehr0osa3875',
      },
      stage: {
        userPoolId: 'eu-central-1_YpDf2mV4x',
        userPoolWebClientId: '3o1l8i16jvafmlrtakbh0o5dge',
      },
      live: {
        userPoolId: 'eu-central-1_zUzYvc6it',
        userPoolWebClientId: '3c0ai985f223d84577kqtdgffr',
      },
    },
  };

  const { domain, environment } = getMetaDataFromDomain();

  const { userPoolId, userPoolWebClientId } =
    domainEnvCognitoIdsMap[domain][environment];

  const clientAmplifyConfig = {
    ...amplifyConfig('eu-central-1'),
    userPoolId,
    userPoolWebClientId,
  };

  Auth.configure(clientAmplifyConfig);
};

export const getCurrentAuthenticatedUser =
  async (): Promise<IEnrichedCognitoUser | null> =>
    Auth.currentAuthenticatedUser();

export const getCurrentSession = async () => Auth.currentSession();

export const signIn = async (
  userLogin: string,
  password: string
): Promise<IEnrichedCognitoUser> => {
  try {
    return Auth.signIn(userLogin.toLowerCase(), password);
  } catch (e: any) {
    switch (e.code) {
      case CognitoExceptionTypes.NotAuthorizedException: {
        throw new UserNotAuthorizedError();
      }

      default: {
        throw new Error(`Can not log in user ${userLogin.toLowerCase()}`);
      }
    }
  }
};

export const forgotPassword = async (
  userLogin: string,
  isExpiredPassword = false
): Promise<void> =>
  Auth.forgotPassword(userLogin.toLowerCase(), {
    expiredPassword: isExpiredPassword.toString(),
  });

export const signOut = async (): Promise<void> => Auth.signOut();

export const forgotPasswordSubmit = async (
  userLogin: string,
  code: string,
  newPassword: string
): Promise<string> =>
  Auth.forgotPasswordSubmit(userLogin.toLowerCase(), code, newPassword);

export const completeNewPasswordChallenge = async (
  user: IEnrichedCognitoUser,
  newPassword: string
): Promise<IEnrichedCognitoUser> => Auth.completeNewPassword(user, newPassword);

export const signUp = async (
  password: string,
  token: string,
  additionalUserAttributes: IUserAttributes
): Promise<PreSignUpTypes> => {
  try {
    await Auth.signUp({
      username: additionalUserAttributes.customerUuid,
      password,
      attributes: {
        email: additionalUserAttributes.customerMail.toLowerCase(),
        'custom:client_uuid': additionalUserAttributes.clientUuid,
        'custom:customer_uuid': additionalUserAttributes.customerUuid,
        'custom:person_id': additionalUserAttributes.personId,
      },
      validationData: {
        token,
      },
    });

    return 'SUCCESS';
  } catch (e: any) {
    switch (e.code) {
      case CognitoExceptionTypes.UsernameExistsException:
        throw new UsernameExistsError();
      case CognitoExceptionTypes.InvalidParameterException:
        throw new InvalidParameterError();
      case CognitoExceptionTypes.InvalidPasswordException:
        throw new InvalidPasswordError();
      default:
        throw new UnreachableError(e as never);
    }
  }
};

/* eslint-disable no-async-promise-executor */
/* eslint-disable consistent-return */
export const getUserAttributes = async (
  user?: CognitoUser
): Promise<ICognitoUserAttributes | null> =>
  new Promise(async (resolve, reject) => {
    try {
      const authUser = user || (await getCurrentAuthenticatedUser());
      if (!authUser) {
        reject();
        return;
      }

      authUser.getUserAttributes((error, result) => {
        if (error) {
          reject();
          return;
        }

        if (!result) {
          resolve(null);

          return;
        }

        const customUserAttributes = result.reduce(
          (prev, curr) => ({ ...prev, [curr.getName()]: curr.getValue() }),
          {}
        ) as ICognitoUserAttributes;
        resolve(customUserAttributes);
      });
    } catch (error) {
      reject(error);
    }
  });
/* eslint-activate consistent-return */
/* eslint-activate no-async-promise-executor */
