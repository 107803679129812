import { setEnv } from '../utils/tenant';
import { Environment } from '../utils/environment';

import { IApiConfig, getDomainConfig } from './apiConfig';

export interface IConfig {
  env: Environment;
  origin: string;
  moduleBaseUrl: string;
  reactAppApiInitialUrl: string;
  offerPageUrl: string;
  homeUrl: string;
  hubbleUrl: string;
  websiteUrl: string;
  api: IApiConfig;
  contractInformationBankBlacklist: string[];
  settings: {
    termOptions: string[];
    defaultAmount: number;
    acceptedFileTypes: string;
    maxFileSize: number;
  };
  loanAmountPossibility: {
    minimumLoanAmountPossible: number;
    maximumLoanAmountPossible: number;
  };
  finalIframeAffiliateIdFC: number;
}

const env: Environment = (import.meta.env.VITE_ENV as Environment) || 'live';

export const config: IConfig = {
  env: env,
  get api() {
    return getDomainConfig(env).api;
  },
  contractInformationBankBlacklist:
    import.meta.env.VITE_CONTRACT_INFORMATION_BANK_BLACKLIST?.split(',') || [],
  origin: import.meta.env.VITE_ORIGIN,
  get moduleBaseUrl() {
    return getDomainConfig(env).clientHostUrl;
  },
  get reactAppApiInitialUrl() {
    return getDomainConfig(env).reactAppApiInitialUrl;
  },
  get offerPageUrl() {
    return getDomainConfig(env).offerPageUrl;
  },
  get homeUrl() {
    return getDomainConfig(env).homePageUrl;
  },
  get hubbleUrl() {
    return getDomainConfig(env).hubbleUrl;
  },
  get websiteUrl() {
    return getDomainConfig(env).websiteUrl;
  },
  settings: {
    termOptions: import.meta.env.VITE_TERM_OPTIONS?.split(',') || [],
    defaultAmount: parseInt(import.meta.env.VITE_DEFAULT_AMOUNT, 10),
    acceptedFileTypes: import.meta.env.VITE_ACCEPTED_FILE_TYPES,
    maxFileSize: parseInt(import.meta.env.VITE_MAX_FILE_SIZE, 10),
  },
  loanAmountPossibility: {
    minimumLoanAmountPossible: Number(
      import.meta.env.VITE_MIN_LOAN_AMOUNT_POSSIBLE
    ),
    maximumLoanAmountPossible: Number(
      import.meta.env.VITE_MAX_LOAN_AMOUNT_POSSIBLE
    ),
  },
  finalIframeAffiliateIdFC: 1000,
};

setEnv(config.env);
