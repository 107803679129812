import BugsnagError from './bugsnagError';

export class EventEmitterError extends BugsnagError {
  public event;

  constructor(message: string, event: any) {
    super(message);

    this.event = event;
  }

  getMetadata() {
    return {
      event: this.event,
    };
  }
}
