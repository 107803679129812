export enum CognitoExceptionTypes {
  UserNotFoundException = 'UserNotFoundException',
  Exception = 'Exception',
  NotAuthorizedException = 'NotAuthorizedException',
  InvalidParameterException = 'InvalidParameterException',
  UsernameExistsException = 'UsernameExistsException',
  InvalidPasswordException = 'InvalidPasswordException',
  PasswordResetRequiredException = 'PasswordResetRequiredException',
  LimitExceededException = 'LimitExceededException',
  ExpiredCodeException = 'ExpiredCodeException',
}
