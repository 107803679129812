import {
  MouseEvent,
  FunctionComponent,
  memo,
  useEffect,
  useState,
  useRef,
  useCallback,
} from 'react';
import { InputAdornment, IconButton } from '@mui/material';
import { VisibilityOff, Visibility } from '@mui/icons-material';

import { Input, InputProps } from '../Input';

export type IProps = Omit<InputProps, 'type'> & {
  isPasswordVisible?: boolean;
  handleShowPassword: () => void;
};

export const PasswordView: FunctionComponent<IProps> = memo(
  ({ isPasswordVisible = false, handleShowPassword, ...rest }) => {
    const inputRef = useRef<HTMLInputElement>();
    const getCursorPosition = useCallback(() => {
      if (!inputRef.current) {
        return { start: 0, end: 0 };
      }

      return {
        start: inputRef.current.selectionStart || 0,
        end: inputRef.current.selectionEnd || 0,
      };
    }, [inputRef]);

    const [cursorPosition, setCursorPosition] = useState(getCursorPosition);

    const handleVisibilityChange = useCallback(() => {
      handleShowPassword();

      setCursorPosition(getCursorPosition());
    }, [handleShowPassword, getCursorPosition, setCursorPosition]);

    useEffect(() => {
      if (!inputRef.current) {
        return;
      }

      inputRef.current.setSelectionRange(
        cursorPosition.start,
        cursorPosition.end
      );
    }, [isPasswordVisible, inputRef, cursorPosition.start, cursorPosition.end]);

    const handleMouseDown = useCallback(
      (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
      },
      []
    );

    return (
      <Input
        type={isPasswordVisible ? 'text' : 'password'}
        color="primary"
        inputRef={inputRef}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleVisibilityChange}
                onMouseDown={handleMouseDown}
              >
                {isPasswordVisible ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        {...rest}
      />
    );
  }
);
