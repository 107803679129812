/* eslint-disable import/no-extraneous-dependencies */
import { CognitoUser } from '@aws-amplify/auth';
import { UserStateActions, UserStateActionTypes } from '../../actions/user';
import { CognitoErrorCode } from '../../errors/cognito-error';
import {
  SignInState,
  IUserDetails,
} from '@finanzcheck/ti-shared-ui/constants/interfaces/Session';

interface IUser {
  signInState: SignInState;
  error: CognitoErrorCode | null;
  applicationUuid?: string;
  homeAccountId?: string; // accountholder customerUuid
  clientUuid?: string;
  customerUuid?: string;
  details: Partial<IUserDetails>;
  idleLogout: boolean;
}

interface IChallengedUser extends IUser {
  signInState: SignInState.NEW_PASSWORD_REQUIRED;
  challengeUser: CognitoUser;
}

export type IUserState = IUser | IChallengedUser;

export function isChallengedUser(state: IUserState): state is IChallengedUser {
  return (
    state.signInState === SignInState.NEW_PASSWORD_REQUIRED &&
    (state as IChallengedUser).challengeUser !== undefined
  );
}

export const InitialUserState: IUserState = {
  signInState: SignInState.UNKNOWN,
  error: null,
  idleLogout: false,
  details: {},
};

export const userStateReducer = (
  state: IUserState,
  action: UserStateActions
): IUserState => {
  switch (action.type) {
    case UserStateActionTypes.USER_LOGIN_SUCCESSFUL: {
      return {
        ...state,
        idleLogout: false,
        signInState: SignInState.SIGNED_IN,
      };
    }

    case UserStateActionTypes.USER_IDLE_LOGOUT: {
      return {
        ...InitialUserState,
        idleLogout: true,
        signInState: SignInState.SIGNED_OUT,
      };
    }

    case UserStateActionTypes.USER_IDLE_DELETE: {
      return {
        ...state,
        idleLogout: false,
      };
    }

    case UserStateActionTypes.USER_SET_ATTRIBUTES: {
      if (!action.attributes) {
        return state;
      }
      /* eslint-disable dot-notation */
      return {
        ...state,
        homeAccountId: action.attributes['custom:customer_uuid'],
        clientUuid: action.attributes['custom:client_uuid'],
      };
      /* eslint-activate dot-notation */
    }

    case UserStateActionTypes.USER_SET_DETAILS: {
      return {
        ...state,
        details: {
          ...state.details,
          ...action.details,
        },
      };
    }

    case UserStateActionTypes.USER_IN_CHALLENGEMODE: {
      return {
        ...state,
        signInState: SignInState.NEW_PASSWORD_REQUIRED,
        challengeUser: action.user,
      };
    }

    case UserStateActionTypes.USER_LOGOUT_SUCCESSFUL: {
      return {
        ...InitialUserState,
        signInState: SignInState.SIGNED_OUT,
      };
    }

    case UserStateActionTypes.GUEST_LOGIN_SUCCESSFUL: {
      if (!action.attributes) {
        return state;
      }

      /* eslint-disable dot-notation */
      return {
        ...state,
        signInState: SignInState.GUEST,
        applicationUuid: action.attributes['applicationUuid'],
        homeAccountId: action.attributes['customerUuid'],
        customerUuid: action.attributes['customerUuid'],
        clientUuid: action.attributes['clientUuid'],
      };
    }
    /* eslint-activate dot-notation */

    default: {
      return state;
    }
  }
};
