import { Box, Collapse, Typography } from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import { SingleInput } from './SingleInput';

interface Props {
  onComplete: (value: string) => void;
  disabled?: boolean;
  externalError?: string | React.ReactElement;
  onChange: () => void;
}

const checkDate = (testDate: string) => {
  const date_regex = /^(0[1-9]|1\d|2\d|3[01])\.(0[1-9]|1[0-2])\.(19|20)\d{2}$/;
  if (!date_regex.test(testDate)) {
    return false;
  }
  return true;
};

export const DatePicker: React.FC<Props> = ({
  onComplete,
  disabled,
  externalError,
  onChange,
}) => {
  const [dateParts, setDateParts] = React.useState<Array<number | undefined>>(
    []
  );
  const [hasError, setHasError] = React.useState<boolean>(false);

  const setDateIdx = useCallback((idx: number, value?: number) => {
    setDateParts((prev) => {
      prev[idx] = value;
      return [...prev];
    });
  }, []);

  useEffect(() => {
    onChange();
    if (dateParts.filter((d) => d !== undefined).length !== 8) {
      return;
    }
    const [d1, d2, m1, m2, y1, y2, y3, y4] = dateParts;
    const d = `${d1}${d2}.${m1}${m2}.${y1}${y2}${y3}${y4}`;
    if (!checkDate(d)) {
      setHasError(true);
      const nextField =
        document.querySelector<HTMLInputElement>(`[name=date0]`);
      nextField?.focus();
      return;
    }
    setHasError(false);
    onComplete(d);
  }, [dateParts, onChange, onComplete]);

  return (
    <Box>
      <Box display="flex" gap={1} justifyContent="center" alignItems="center">
        <SingleInput
          placeholder="T"
          idx={0}
          onChange={(n) => setDateIdx(0, n)}
          disabled={disabled}
        />
        <SingleInput
          placeholder="T"
          idx={1}
          onChange={(n) => setDateIdx(1, n)}
          disabled={disabled}
        />
        -
        <SingleInput
          placeholder="M"
          idx={2}
          onChange={(n) => setDateIdx(2, n)}
          disabled={disabled}
        />
        <SingleInput
          placeholder="M"
          idx={3}
          onChange={(n) => setDateIdx(3, n)}
          disabled={disabled}
        />
        -
        <SingleInput
          placeholder="J"
          idx={4}
          onChange={(n) => setDateIdx(4, n)}
          disabled={disabled}
        />
        <SingleInput
          placeholder="J"
          idx={5}
          onChange={(n) => setDateIdx(5, n)}
          disabled={disabled}
        />
        <SingleInput
          placeholder="J"
          idx={6}
          onChange={(n) => setDateIdx(6, n)}
          disabled={disabled}
        />
        <SingleInput
          placeholder="J"
          idx={7}
          onChange={(n) => setDateIdx(7, n)}
          disabled={disabled}
        />
      </Box>
      <Collapse in={hasError}>
        <Typography color="error" mt={2} textAlign="center" fontSize=".9em">
          Bitte geben Sie ein valides Datum ein
        </Typography>
      </Collapse>
      <Collapse in={!!externalError}>
        <Typography color="error" mt={2} textAlign="center" fontSize=".9em">
          {externalError}
        </Typography>
      </Collapse>
    </Box>
  );
};
