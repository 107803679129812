import { Box, Button, Typography } from '@mui/material';
import { FunctionComponent, memo } from 'react';

interface IProps {
  error: Error;
  info: React.ErrorInfo;
  clearError: () => void;
}

export const ErrorFallback: FunctionComponent<IProps> = memo(
  ({ clearError }) => {
    return (
      <Box display="flex" flexDirection="column" alignItems="center" p={2}>
        <Typography paragraph>
          Entschuldigung, da ist leider etwas schief gelaufen!
        </Typography>
        <Button variant="contained" onClick={clearError}>
          Erneut versuchen
        </Button>
      </Box>
    );
  }
);
