import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

interface IErrorDialogViewProps {
  open: boolean;
  onCloseErrorDialog?: () => void;
}

const CustomErrorOutlineIcon = styled(ErrorOutlineIcon)(({ theme }) => ({
  width: '100px',
  height: '100px',
  color: theme.palette.error.main,
}));

const CustomButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.error.main,
  ':hover': {
    backgroundColor: theme.palette.error.main,
  },
}));

export const ErrorDialogView: React.FC<IErrorDialogViewProps> = ({
  open,
  onCloseErrorDialog,
}) => (
  <Dialog maxWidth="xs" open={open}>
    <Box pt={3} px={3}>
      <DialogContent>
        <Box textAlign="center">
          <CustomErrorOutlineIcon />
        </Box>
        <Typography variant="h6" textAlign="center">
          Whoops!
        </Typography>
        <Typography variant="body2" sx={{ pt: 1 }}>
          Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch
          einmal oder kontaktieren Sie uns, wenn das Problem weiterhin besteht.
        </Typography>
      </DialogContent>
      <DialogActions sx={{ pb: 5, px: 5 }}>
        <CustomButton
          variant="contained"
          fullWidth
          onClick={onCloseErrorDialog}
        >
          Schließen
        </CustomButton>
      </DialogActions>
    </Box>
  </Dialog>
);
