import { apiClient } from 'api';

export const emailAdapter = async (
  applicationUuid: string,
  clientUuid: string
): Promise<void> => {
  await apiClient('home').post(`magic-token-email/application`, {
    applicationUuid,
    clientUuid,
  });
};
