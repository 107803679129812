import { QueryKey } from 'react-query';
import { apiClient } from 'api';
import { IOperator } from '@finanzcheck/ti-shared-ui/constants/domain/operator';
import { SignInState } from '@finanzcheck/ti-shared-ui/constants/interfaces/Session';

const getRelativeApiUrlForSignInState = (signInState: SignInState) => {
  switch (signInState) {
    case SignInState.SIGNED_IN:
    case SignInState.GUEST:
      return 'operator';
    default:
      return 'operator-public';
  }
};

export const operatorAdapter = async ({
  queryKey,
}: {
  queryKey: QueryKey;
}): Promise<IOperator> => {
  /**
   * @todo remove ts-ignore notation
   */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore: Type 'QueryKey' can only be iterated through when using the '--downlevelIteration' flag or with a '--target' of 'es2015' or higher.
  const [, signInState] = queryKey;
  const { data } = await apiClient('home').get<IOperator>(
    getRelativeApiUrlForSignInState(signInState as SignInState)
  );

  return data;
};
