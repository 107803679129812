import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';

import { DescriptionList } from './DescriptionList';
import { DialogActionsView } from './DialogActions';

interface IConsentDialogProps {
  open: boolean;
  onUserConsentAgree?: () => void;
  onUserConsentDisAgree?: () => void;
}

export const ConsentDialog: React.FC<IConsentDialogProps> = ({
  open,
  onUserConsentAgree,
  onUserConsentDisAgree,
}) => (
  <Dialog maxWidth="xs" open={open}>
    <Box pt={3} px={3}>
      <DialogTitle sx={{ textAlign: 'center' }}>
        Anfrage zur Unterstützung
      </DialogTitle>
      <DialogContent>
        <Typography variant="body2">
          Unser Kreditberater würde Sie gerne vorübergehend unterstützen.
          Möchten Sie dies zulassen?
        </Typography>
        <DescriptionList />
      </DialogContent>
      <DialogActionsView
        onUserConsentAgree={onUserConsentAgree}
        onUserConsentDisAgree={onUserConsentDisAgree}
      />
    </Box>
  </Dialog>
);
