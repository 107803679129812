import { PropsWithChildren, memo, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import { Box, useTheme } from '@mui/material';
import { Footer } from '@finanzcheck/ti-shared-ui/components/Footer';
import { NewHeader } from '../NewHeader';

import { getMetaDataFromDomain } from '@finanzcheck/ti-shared-ui/utils/domain';
import useResizeObserver from '@finanzcheck/ti-shared-ui/hooks/useCalculateElementDimension';

export const Body: React.FC<PropsWithChildren> = memo(({ children }) => {
  const theme = useTheme();
  const { pathname } = useLocation();
  const [height, setHeight] = useState(0);
  const headerRef = useResizeObserver<HTMLDivElement>((target, params) => {
    setHeight(params.contentRect.height);
  });
  const sharedTenantConfig = useMemo(() => getMetaDataFromDomain(), []);

  return (
    <>
      <Box minHeight="100vh" display="flex" flexDirection="column">
        <Box
          sx={{
            position: 'fixed',
            width: '100%',
            zIndex: 1000,
            background: theme.palette.common.white,
          }}
          ref={headerRef}
        >
          <NewHeader />
        </Box>
        <Box
          bgcolor={
            pathname.includes('/antrag/')
              ? theme.palette.common.white
              : sharedTenantConfig.backgroundColor
          }
          flex={1}
          sx={{ mt: `${height}px` }}
        >
          {children}
        </Box>
        <Box
          sx={{
            width: '100%',
            background: theme.palette.common.white,
          }}
        >
          <Footer />
        </Box>
      </Box>
    </>
  );
});
