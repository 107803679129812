export default class OptimizelyError extends Error {
  public error: Error | undefined;

  constructor(message: string, error?: Error) {
    super(message);

    this.error = error;
    this.name = 'OptimizelyError';
  }

  getMetadata() {
    return {
      errorDetails: {
        error: this.error,
      },
    };
  }
}
