import { clearCookie } from '@finanzcheck/ti-shared-ui/utils/cookie';
import { getCurrentSession } from '@finanzcheck/ti-shared-ui/utils/amplify/amplify';
import { apiClient } from 'api';
import { AxiosError } from 'axios';
import { SignInState } from '@finanzcheck/ti-shared-ui/constants/interfaces/Session';

export function updateApiClientAuthHeader(token: string) {
  const client = apiClient('home');
  client.defaults.headers['Authorization'] = `Bearer ${token}`;
}

export const setAccessToken = async () => {
  const session = await getCurrentSession();
  const accessToken = session.getIdToken().getJwtToken();
  updateApiClientAuthHeader(accessToken);
};

/**
 * @description ignore auth errors for bugsnag report
 */
export const isGuestAuthError = (error: AxiosError, signIn: SignInState) => {
  return (
    (error.response?.status === 401 ||
      error.message === 'Request failed with status code 401') &&
    (signIn === SignInState.GUEST || signIn === SignInState.UNKNOWN)
  );
};

export function removeGuestTokenFromSessionStorage() {
  clearCookie('guestAccessToken');
  clearCookie('guestRefreshToken');
  sessionStorage.clear();
}
