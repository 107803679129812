import { FunctionComponent } from 'react';
import { Box, Link } from '@mui/material';

interface TopQuestionProps {
  query: string;
  url: string;
  last: boolean | undefined;
}

export const TopQuestion: FunctionComponent<TopQuestionProps> = ({
  query,
  url,
  last,
}) => {
  return (
    <Box
      py={2}
      sx={
        !last
          ? (theme) => ({
              borderBottom: `1px solid ${theme.palette.grey[200]}`,
              display: 'flex',
              alignItems: 'center',
            })
          : {}
      }
    >
      <Link
        variant="subtitle1"
        color="textSecondary"
        href={url}
        target="_blank"
        rel="noopener"
      >
        {query}
      </Link>
    </Box>
  );
};
