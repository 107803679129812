import { FunctionComponent, memo } from 'react';

import { MailView } from './Mail';
import { InputProps } from '../Input';

export type IProps = Omit<InputProps, 'value'> & { value?: string };

export const Mail: FunctionComponent<IProps> = memo((inputFieldProps) => {
  return <MailView {...inputFieldProps} />;
});
