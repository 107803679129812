import { useContext } from 'react';
import { Experiments } from '../utils/optimizely/experiments';
import { TenantDomain, getCurrentTenantConfig } from '../utils/tenant';
import {
  useDecision as nativeUseDecision,
  OptimizelyContext,
} from '@optimizely/react-sdk';

export const useAppBannerABCDTest = () => {
  const { domain } = getCurrentTenantConfig();
  const isSmava = domain === TenantDomain.Smava;
  const { optimizely } = useContext(OptimizelyContext);

  // We are calling the useDecision hook conditionally to avoid errors on console if app is not wrapped inside optimizely provider(affiliate cases!)
  const decision =
    optimizely && nativeUseDecision(Experiments.PLAYERS_APP_BANNER_ABCD_TEST);

  if (!isSmava) {
    return {
      variationKey: 'variation_a',
    };
  }

  // Start the App Banner ABC test.
  return {
    variationKey:
      decision && decision?.[0]?.variationKey
        ? decision?.[0]?.variationKey
        : 'variation_a',
  };
};
