import { getDevTenant } from '../../../utils/dev';
import { getEnv, TenantDomain } from '../../../utils/tenant';
import { TenantUuid } from '../../../utils/tenant/getTenantForClientUuid';
import { Environment } from '../../../utils/domain';

// exported for testing purposes
export class ThemeClientDomainError extends Error {
  constructor(origin: string) {
    super(`client domain theming error - unknown origin: ${origin}`);
    this.name = 'ThemeClientDomainError';
  }
}

export interface ITenantConfig {
  domain: TenantDomain;
  clientUuid: TenantUuid;
}

export interface ITenantConfigMap {
  domain: TenantDomain;
  clientUuids: Record<Environment, TenantUuid>;
}

export const tenantDomainMap: Record<TenantDomain, ITenantConfigMap> = {
  [TenantDomain.Finanzcheck]: {
    domain: TenantDomain.Finanzcheck,
    clientUuids: {
      live: TenantUuid.FinanzcheckLive,
      stage: TenantUuid.FinanzcheckStage,
      shared: TenantUuid.FinanzcheckShared,
    },
  },
  [TenantDomain.Smava]: {
    domain: TenantDomain.Smava,
    clientUuids: {
      live: TenantUuid.Smava,
      stage: TenantUuid.Smava,
      shared: TenantUuid.Smava,
    },
  },
};

/**
 Checks the domain the application is running on
 and returns the respective uuid for loading in the correct theme and content
 */
export const useClientDomain = (): ITenantConfig => {
  const { origin } = window.location;
  const matchedDomain = Object.keys(tenantDomainMap).find((domainPart) =>
    origin.includes(domainPart)
  );
  const env = getEnv();

  if (!matchedDomain) {
    // there's no default theme for unknown origin on the live environment
    if (env === 'live') {
      throw new ThemeClientDomainError(origin);
    }

    // only for local development!
    // we are loading the theme to be applied by default
    const { domain, clientUuids } =
      tenantDomainMap[(getDevTenant() as TenantDomain) ?? 'finanzcheck'];
    return { domain, clientUuid: clientUuids[env as Environment] };
  }

  const { domain, clientUuids } =
    tenantDomainMap[matchedDomain as TenantDomain];
  return { domain, clientUuid: clientUuids[env as Environment] };
};

export const useRegistrationRouteBasePath = () => {
  // We redirect to base website for now, since link to rr behaves strangely.
  const { domain } = useClientDomain();
  if (domain === TenantDomain.Smava) {
    return import.meta.env.VITE_WEBSITE_URL_SM;
  }
  return import.meta.env.VITE_WEBSITE_URL_FC;
};
