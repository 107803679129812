import { operatorAdapter } from 'adapter/operatorAdapter';
import { IOperator } from '@finanzcheck/ti-shared-ui/constants/domain/operator';
import { createContext, useContext, memo, useMemo, ReactNode, FC } from 'react';
import { MutationStatus, useQuery } from 'react-query';
import { useDashboardState } from '../Dashboard';
import { useUserState } from '../UserContext';
import { useClient } from '@finanzcheck/ti-shared-ui/components/Context/Client';
import { SignInState } from '@finanzcheck/ti-shared-ui/constants/interfaces/Session';

interface IOperatorContextType {
  leadUuid: string | null;
  operator: IOperator | undefined;
  status: MutationStatus;
}

interface IOperatorProviderProps {
  children: ReactNode;
}

const OperatorContext = createContext<IOperatorContextType>({
  leadUuid: null,
  operator: undefined,
  status: 'idle',
});

const OperatorProvider: FC<IOperatorProviderProps> = ({ children }) => {
  const { defaultAdvisor } = useClient();
  const {
    user: { signInState },
  } = useUserState();
  const {
    status: dashboardStatus,
    state: { dashboard },
  } = useDashboardState();

  const { leadUuid } = useMemo(() => {
    if (!dashboard) {
      return { leadUuid: null };
    }

    return dashboard.operator;
  }, [dashboard]);

  const { data = undefined, status } = useQuery<IOperator>(
    ['operator', signInState],
    operatorAdapter,
    {
      enabled:
        signInState !== SignInState.SIGNED_IN ||
        ['success', 'error'].includes(dashboardStatus),
    }
  );

  return (
    <OperatorContext.Provider
      value={{
        leadUuid,
        operator: dashboard?.operator.leadUuid ? data : defaultAdvisor,
        status,
      }}
    >
      {children}
    </OperatorContext.Provider>
  );
};

export default memo(OperatorProvider);
export const useOperator = () => useContext(OperatorContext);
