import { FunctionComponent, memo } from 'react';

import { PhoneView } from './Phone';
import { InputProps } from '../Input';

export type IProps = Omit<InputProps, 'value'> & { value?: string };

export const Phone: FunctionComponent<IProps> = memo((inputFieldProps) => {
  return <PhoneView {...inputFieldProps} />;
});
