import { getStorage } from '@finanzcheck/ti-shared-ui/utils/storage';
import { dashboardAdapter } from 'adapter/dashboardAdapter';
import {
  createContext,
  useReducer,
  useContext,
  memo,
  useEffect,
  FC,
  ReactNode,
} from 'react';
import { MutationStatus, useQuery } from 'react-query';

import {
  DashboardStateActions,
  DashboardStateActionTypes,
} from '../../../actions/dashboard';
import {
  IDashboardState,
  InitialDashboardState,
  dashboardStateReducer,
} from '../../../reducers/dashboard';
import { isGuestLogin } from '../GuestAuthentication';
import { useUserState } from '../UserContext';
import { SignInState } from '@finanzcheck/ti-shared-ui/constants/interfaces/Session';

interface IDashboardProviderProps {
  children: ReactNode;
}
interface IDashboardStateContextType {
  state: IDashboardState;
  status: MutationStatus;
}

interface IDashboardDispatchContextType {
  dispatch: React.Dispatch<DashboardStateActions>;
}

const DashboardDispatchContext = createContext<IDashboardDispatchContextType>({
  dispatch: () => {
    throw new Error('The DashboardProvider is missing');
  },
});

const DashboardStateContext = createContext<IDashboardStateContextType>({
  state: InitialDashboardState,
  status: 'idle',
});

const DashboardProvider: FC<IDashboardProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(
    dashboardStateReducer,
    InitialDashboardState
  );
  const { user } = useUserState();

  const isLoadDashboardEnabled = Boolean(
    (user.signInState !== SignInState.UNKNOWN &&
      !!user.homeAccountId &&
      !isGuestLogin()) ||
      (user.signInState !== SignInState.UNKNOWN &&
        !!user.homeAccountId &&
        isGuestLogin() &&
        getStorage('sessionStorage').getItem('dateOfBirth'))
  );

  const { data, status } = useQuery(['dashboard'], dashboardAdapter, {
    enabled: isLoadDashboardEnabled,
  });

  useEffect(() => {
    if (status === 'loading' || !data) {
      return;
    }

    dispatch({
      type: DashboardStateActionTypes.DASHBOARD_LOAD_SUCCESSFUL,
      dashboard: data,
    });
  }, [status, data]);

  useEffect(() => {
    if (user.signInState === 'SIGNED_OUT') {
      dispatch({
        type: DashboardStateActionTypes.DASHBOARD_INVALIDATE_STATE,
      });
    }
  }, [user.signInState]);

  return (
    <DashboardDispatchContext.Provider value={{ dispatch }}>
      <DashboardStateContext.Provider value={{ state, status }}>
        {children}
      </DashboardStateContext.Provider>
    </DashboardDispatchContext.Provider>
  );
};

export default memo(DashboardProvider);

export const useDashboardState = () => useContext(DashboardStateContext);
export const useDashboardDispatch = () => useContext(DashboardDispatchContext);
