import CustomOptimizelyProvider from '@finanzcheck/ti-shared-ui/utils/optimizely/OptimizelyProvider';
import { useUserState } from '../UserContext';
import { PropsWithChildren, memo } from 'react';

export const OptimizelyProvider: React.FC<PropsWithChildren> = memo(
  ({ children }) => {
    const { user } = useUserState();

    return (
      <CustomOptimizelyProvider userId={user.details.personId || null}>
        {children}
      </CustomOptimizelyProvider>
    );
  }
);
