import React, { createContext, useContext, useState, ReactNode } from 'react';

interface DacContextType {
  state: string;
  setState: React.Dispatch<React.SetStateAction<string>>;
  confirmed: boolean;
  setConfirmed: React.Dispatch<React.SetStateAction<boolean>>;
}

const DacContext = createContext<DacContextType | undefined>(undefined);

interface DacProviderProps {
  children: ReactNode;
}

export const DacProvider: React.FC<DacProviderProps> = ({ children }) => {
  const [state, setState] = useState('');
  const [confirmed, setConfirmed] = useState(false);

  return (
    <DacContext.Provider value={{ state, setState, confirmed, setConfirmed }}>
      {children}
    </DacContext.Provider>
  );
};

export const useDacState = (): DacContextType => {
  const context = useContext(DacContext);
  if (!context) {
    throw new Error('useDacState must be used within a useDacStateProvider');
  }
  return context;
};
