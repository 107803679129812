import { FunctionComponent, memo, SVGProps } from 'react';

export const MagicWand: FunctionComponent<SVGProps<SVGSVGElement>> = memo(
  () => (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1502_22961)">
        <path
          d="M17.0417 4.99994L17.45 4.1166L18.3334 3.70827C18.6584 3.55827 18.6584 3.09993 18.3334 2.94993L17.45 2.5416L17.0417 1.6666C16.8917 1.3416 16.4334 1.3416 16.2834 1.6666L15.875 2.54993L15 2.95827C14.675 3.10827 14.675 3.5666 15 3.7166L15.8834 4.12493L16.2917 4.99994C16.4334 5.32494 16.9 5.32494 17.0417 4.99994Z"
          fill="white"
        />
        <path
          d="M17.0417 13.5771L17.45 12.6938L18.3334 12.2854C18.6584 12.1354 18.6584 11.6771 18.3334 11.5271L17.45 11.1187L17.0417 10.2438C16.8917 9.91875 16.4334 9.91875 16.2834 10.2438L15.875 11.1271L15 11.5354C14.675 11.6854 14.675 12.1438 15 12.2938L15.8834 12.7021L16.2917 13.5771C16.4334 13.9021 16.9 13.9021 17.0417 13.5771Z"
          fill="white"
        />
        <path
          d="M9.28542 4.57708L9.69375 3.69375L10.5771 3.28542C10.9021 3.13542 10.9021 2.67708 10.5771 2.52708L9.69375 2.11875L9.28542 1.24375C9.13542 0.91875 8.67708 0.91875 8.52708 1.24375L8.11875 2.12708L7.24375 2.53542C6.91875 2.68542 6.91875 3.14375 7.24375 3.29375L8.12708 3.70208L8.53542 4.57708C8.67708 4.90208 9.14375 4.90208 9.28542 4.57708Z"
          fill="white"
        />
        <path
          d="M14.7584 7.59989L12.4 5.24155C12.075 4.91655 11.55 4.91655 11.225 5.24155L1.90836 14.5499C1.58336 14.8749 1.58336 15.3999 1.90836 15.7249L4.2667 18.0832C4.5917 18.4082 5.1167 18.4082 5.4417 18.0832L14.75 8.77489C15.0834 8.45822 15.0834 7.92489 14.7584 7.59989ZM11.8417 9.34155L10.6667 8.16655L11.8167 7.01655L12.9917 8.19155L11.8417 9.34155Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1502_22961">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
);
