export enum GenderTypes {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
}

export enum DebtorTypeEnum {
  NONE = 'NONE',
  STANDARD = 'DEATH_INABILITY',
  COMPLETE = 'UNEMPLOYMENT_DEATH_INABILITY',
  DEATH = 'DEATH',
}

type HouseholdType =
  | 'LIVING_SITUATION_NULL'
  | 'RENTING'
  | 'RENTFREE'
  | 'PARENTS'
  | 'PROPERTY';
export type RealEstateType =
  | 'TYPE_NULL'
  | 'NONE'
  | 'APPARTMENT'
  | 'SINGLE_FAMILY_HOUSE'
  | 'APPARTMENT_HOUSE'
  | 'OFFICE';
export type RentalPropertyType =
  | 'TYPE_NULL'
  | 'NONE'
  | 'APPARTMENT'
  | 'SINGLE_FAMILY_HOUSE'
  | 'APPARTMENT_HOUSE'
  | 'OFFICE';

export interface IDebtor {
  id: string;
  personId?: string; // TODO: Align the response of home & kronos to have only id. REMOVE personID, keep id (kronos has id)
  gender: GenderTypes;
  firstName: string;
  lastName: string;
  emailHash?: string;
  occupationType?: string;
  household?: HouseholdType;
  realEstate?: RealEstateType;
  rentalProperty?: RentalPropertyType;
}

export type IDebtorReduced = Pick<IDebtor, 'id' | 'firstName' | 'lastName'>;

export interface IRdiDebtor {
  firstname: string;
  lastname: string;
  type: DebtorTypeEnum;
}

export interface IIdentDebtor {
  firstName: string;
  lastName: string;
  sex: string;
  ident: 'primary' | 'secondary';
  identifier: 'primary' | 'secondary';
  bank: string;
}

/**
 * @todo merge with DebtorTypeEnum
 */
export { DebtorTypeEnum as RdiType };
