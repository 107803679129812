import { IApplicationOverviewListInterface } from '../../constants/domain/application-overview-list.interface';
import { IApplication } from '@finanzcheck/ti-shared-ui/constants/interfaces/Application';

export enum ApplicationListStateActionTypes {
  APPLICATIONLIST_LOAD_START = 'APPLICATIONLIST_LOAD_START',
  APPLICATIONLIST_LOAD_SUCCESSFUL = 'APPLICATIONLIST_LOAD_SUCCESSFUL',
  APPLICATIONLIST_LOAD_ERROR = 'APPLICATIONLIST_LOAD_ERROR',
  APPLICATIONLIST_ADD_DETAILED_APPLICATION_START = 'APPLICATIONLIST_ADD_DETAILED_APPLICATION_START',
  APPLICATIONLIST_ADD_DETAILED_APPLICATION_SUCCESSFUL = 'APPLICATIONLIST_ADD_DETAILED_APPLICATION_SUCCESSFUL',
  APPLICATIONLIST_ADD_DETAILED_APPLICATION_FAILED = 'APPLICATIONLIST_ADD_DETAILED_APPLICATION_FAILED',
  APPLICATIONLIST_INVALIDATE_STATE = 'APPLICATIONLIST_INVALIDATE_STATE',
}

interface IApplicationListLoadStart {
  type: ApplicationListStateActionTypes.APPLICATIONLIST_LOAD_START;
}

interface IApplicationListLoadSuccessful {
  type: ApplicationListStateActionTypes.APPLICATIONLIST_LOAD_SUCCESSFUL;
  traversal: IApplicationOverviewListInterface;
}

interface IApplicationListLoadError {
  type: ApplicationListStateActionTypes.APPLICATIONLIST_LOAD_ERROR;
  error: string;
}

interface IApplicationListAddDetailedApplicationStart {
  type: ApplicationListStateActionTypes.APPLICATIONLIST_ADD_DETAILED_APPLICATION_START;
  applicationUuid: string;
}

interface IApplicationListAddDetailedApplicationSuccessful {
  type: ApplicationListStateActionTypes.APPLICATIONLIST_ADD_DETAILED_APPLICATION_SUCCESSFUL;
  application: IApplication;
}

interface IApplicationListAddDetailedApplicationFailed {
  type: ApplicationListStateActionTypes.APPLICATIONLIST_ADD_DETAILED_APPLICATION_FAILED;
  applicationUuid: string;
  message: string;
}

interface IApplicationListInvalidateState {
  type: ApplicationListStateActionTypes.APPLICATIONLIST_INVALIDATE_STATE;
}

export type ApplicationListStateActions =
  | IApplicationListLoadStart
  | IApplicationListLoadSuccessful
  | IApplicationListLoadError
  | IApplicationListInvalidateState
  | IApplicationListAddDetailedApplicationStart
  | IApplicationListAddDetailedApplicationSuccessful
  | IApplicationListAddDetailedApplicationFailed;
