import { memo, useCallback, useEffect } from 'react';
import { useLocation } from 'react-router';
import { Location } from 'history';

import { TrackingEvent } from '@finanzcheck/ti-shared-ui/utils/tracking/events.interface';
import { RouteSwitchView } from './RouteSwitch';
import { getMetaDataFromDomain } from '@finanzcheck/ti-shared-ui/utils/domain';
import useTrack from '@finanzcheck/ti-shared-ui/hooks/useTrack';
import { useUserState } from '../Context/UserContext';
import { SignInState } from '@finanzcheck/ti-shared-ui/constants/interfaces/Session';
import { useAppBannerABCDTest } from '@finanzcheck/ti-shared-ui/hooks/useAppBannerABCDTest';
import { useExpandedSorting } from '@finanzcheck/ti-shared-ui/hooks/useExpandedSorting';
import { useDACFlowInCheckoutABTest } from '@finanzcheck/ti-shared-ui/hooks/useDACFlowInCheckoutABTest';

export const RouteSwitch = memo(() => {
  const location = useLocation();
  const track = useTrack();
  const { clientUuid } = getMetaDataFromDomain();
  const { user } = useUserState();

  const setCurrentPage = useCallback(
    ({ pathname }: Location<unknown>) => {
      track({ event: TrackingEvent.PageView, payload: { pathname } });
    },
    [track]
  );

  useEffect(() => {
    // Firing the pageView event once we have decision from Optimizely to differentiate bounce rate between variations
    // TODO: This condition should be removed once we stop the ab test for offer cards.
    if (user.signInState !== SignInState.SIGNED_IN) {
      setCurrentPage(location);
    }
  }, [location, setCurrentPage, user.signInState]);

  useEffect(() => {
    track({
      event: undefined,
      payload: {
        ClientUuid: clientUuid,
      },
      type: 'identify',
      global: false,
    });
  }, [clientUuid, track]);

  return (
    <>
      {user.signInState === SignInState.SIGNED_IN && <OptimizelyDecisions />}
      <RouteSwitchView />
    </>
  );
});

// We should only call the useDecision hooks, once user is signed in. Otherwise we don't have the correct ids in place for the user
const OptimizelyDecisions = () => {
  useAppBannerABCDTest();
  useExpandedSorting();
  useDACFlowInCheckoutABTest();
  return <></>;
};
