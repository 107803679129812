import { IToastMessage } from '../../reducers/toast';

export enum ToastStateActionTypes {
  QUEUE_MESSAGE = 'QUEUE_MESSAGE',
  POP_MESSAGE = 'POP_MESSAGE',
  REMOVE_MESSAGE = 'REMOVE_MESSAGE',
}

interface IQueueMessage {
  type: ToastStateActionTypes.QUEUE_MESSAGE;
  message: IToastMessage;
}

interface IPopMessage {
  type: ToastStateActionTypes.POP_MESSAGE;
}

interface IRemoveMessage {
  type: ToastStateActionTypes.REMOVE_MESSAGE;
  key: string;
}

export type ToastStateActions = IQueueMessage | IPopMessage | IRemoveMessage;
