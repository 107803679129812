import { SaveNowBadgeActions, SaveNowBadgeActionTypes } from './actions';

export interface ISaveNowBadgeState {
  saveNowBadgeShown: boolean;
}

export const initialSaveNowBadgeState: ISaveNowBadgeState = {
  saveNowBadgeShown: false,
};

export const saveNowBadgeReducer = (
  state: ISaveNowBadgeState,
  action: SaveNowBadgeActions
): ISaveNowBadgeState => {
  switch (action.type) {
    case SaveNowBadgeActionTypes.SAVE_NOW_BADGE_SHOWN: {
      return {
        ...state,
        saveNowBadgeShown: action.shown,
      };
    }

    default: {
      return state;
    }
  }
};
