import { Box, Link, Typography } from '@mui/material';
import EventIcon from '@mui/icons-material/Event';
import { config } from '../../../../constants/config';
import { getCurrentTenantConfig } from '../../../../utils/tenant';
import { useOperator } from '../../../Context/Operator';

export const AppointmentSection = () => {
  const { leadUuid } = useOperator();
  const { domain } = getCurrentTenantConfig();
  const { baseUrl, appointmentTypeId } = config.appointment[domain];

  const appointmentUrl = `${baseUrl}?utm_campaign=Kundenbereich&appointmentTypeId=${appointmentTypeId}&lid=${leadUuid}`;

  return (
    <Box
      pt={2}
      pb={1}
      sx={{
        display: 'flex',
        alignItems: 'flex-start',
      }}
    >
      <Box>
        <EventIcon
          sx={{
            width: '18px',
            height: 'auto',
            marginTop: '6px',
          }}
        />
      </Box>
      <Box pl={2}>
        <Link
          variant="subtitle1"
          color="textSecondary"
          href={appointmentUrl}
          target="_blank"
          rel="noopener"
        >
          <Typography variant="subtitle1">Termin vereinbaren</Typography>
        </Link>
      </Box>
    </Box>
  );
};
