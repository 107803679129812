import { Banner, BannerType } from '@finanzcheck/teal-components';
import { TenantDomain } from '../../utils/tenant';
import { MultiTenantComponent } from '../../utils/tenant/tenant-differentiation';
import { Link } from '@mui/material';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import { FunctionComponent } from 'react';

export const ErrorSnackbar: FunctionComponent<{
  open: boolean;
  onClose: () => void;
}> = ({ open, onClose }) => {
  return (
    <Snackbar
      open={open}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      autoHideDuration={6000}
      onClose={(_, reason) => reason === 'timeout' && onClose()}
    >
      <Box>
        <Banner
          type={BannerType.ERROR}
          desc={
            <>
              Es tut uns leid, wir hatten einige technische Probleme während
              Ihres letzen Vorgangs.
              <MultiTenantComponent
                componentMap={{
                  [TenantDomain.Smava]: (
                    <>
                      <br /> Bitte versuchen Sie es später erneut oder{' '}
                      <Link
                        sx={{ color: 'error.main', fontWeight: 'bold' }}
                        href="https://smava.zendesk.com/hc/de/requests/new"
                      >
                        melden Sie Das Problem
                      </Link>
                    </>
                  ),
                  [TenantDomain.Finanzcheck]: <></>,
                }}
              />
            </>
          }
        />
      </Box>
    </Snackbar>
  );
};
