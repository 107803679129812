import { Box, Grid, Typography, Link } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import { useClient } from '@finanzcheck/ti-shared-ui/components/Context/Client';

export const Hotline = () => {
  const { phone } = useClient();

  return (
    <>
      <Box pl={2} pt={1}>
        <Typography variant="h4">Hotline</Typography>
      </Box>
      <Grid item>
        <Box
          px={2}
          py={2}
          mx={2}
          my={2}
          sx={(theme) => ({
            border: `1px solid ${theme.palette.grey[200]}`,
            borderRadius: '4px',
            display: 'flex',
            alignItems: 'flex-start',
          })}
        >
          <Box>
            <PhoneIcon
              sx={{
                width: '18px',
                height: 'auto',
                marginTop: '6px',
              }}
            />
          </Box>
          <Box pl={2}>
            <Link color="textPrimary" href={`tel:${phone}`} variant="subtitle1">
              <b>{phone}</b>
            </Link>
            <Typography
              variant="subtitle2"
              sx={(theme) => ({
                color: theme.palette.grey[600],
              })}
            >
              Kostenlose Beratung
            </Typography>
            <Typography
              variant="subtitle2"
              sx={(theme) => ({
                color: theme.palette.grey[600],
              })}
            >
              Montag - Samstag: 8:00 - 20:00 Uhr
            </Typography>
          </Box>
        </Box>
      </Grid>
    </>
  );
};
