export enum SaveNowBadgeActionTypes {
  SAVE_NOW_BADGE_SHOWN = 'SAVE_NOW_BADGE_SHOWN',
}

export type SaveNowBadgeActions = ISaveNowBadgeShownActionType;

interface ISaveNowBadgeShownActionType {
  type: SaveNowBadgeActionTypes.SAVE_NOW_BADGE_SHOWN;
  shown: boolean;
}
