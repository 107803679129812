const COOKIE_KEY_FCID = 'FCID';

export const getCookie = (name: string) => {
  const nameLenPlus = name.length + 1;
  return (
    document.cookie
      .split(';')
      .map((c) => c.trim())
      .filter((cookie) => {
        return cookie.substring(0, nameLenPlus) === `${name}=`;
      })
      .map((cookie) => {
        return decodeURIComponent(cookie.substring(nameLenPlus));
      })[0] || null
  );
};

export const getHardenedCookieBase64 = (name: string) => {
  const nameLenPlus = name.length + 1;
  const cookieValue =
    document.cookie
      .split(';')
      .map((c) => c.trim())
      .filter((cookie) => cookie.substring(0, nameLenPlus) === `${name}=`)
      .map((cookie) => cookie.substring(nameLenPlus))[0] || null;

  if (cookieValue) {
    return decodeURIComponent(atob(cookieValue));
  }

  return null;
};

export function getFcId() {
  return getCookie(COOKIE_KEY_FCID);
}

/**
 * WORK AROUND SOLUTION to delete the cookie in production as they are being stored in sub domain
 * TODO: Need proper solution to delete cookies for all subdomains like .smava.de, .home.smava.de, .home-stage.smava.de etc..
 */
export const clearCookie = (name: string) => {
  // This will delete the cookie for ".smava.de" domain in production, ".smava-stage.de" in staging, ".smava-shared.de" in shared.
  document.cookie = `${name}=; path=/; domain=.smava.de; expires=${new Date(
    0
  ).toUTCString()};`;
  document.cookie = `${name}=; path=/; domain=.smava-stage.de; expires=${new Date(
    0
  ).toUTCString()};`;
  document.cookie = `${name}=; path=/; domain=.smava-shared.de; expires=${new Date(
    0
  ).toUTCString()};`;

  // This will delete the cookie for current domain.
  document.cookie = `${name}=; path=/; expires=${new Date(0).toUTCString()};`;
};

export const setCookie = (
  name: string,
  value: string | number,
  days: number,
  cookieDomain?: string
) => {
  let expires = '',
    domain = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toUTCString();
  }
  if (cookieDomain) {
    domain = '; domain=' + cookieDomain;
  }
  document.cookie = name + '=' + (value || '') + expires + domain + '; path=/';
};

export const setHardenedCookieBase64 = (
  name: string,
  value: string | number,
  days: number,
  cookieDomain?: string
) => {
  let expires = '';
  let domain = '';

  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toUTCString();
  }

  if (cookieDomain) {
    domain = '; domain=' + cookieDomain;
  }

  const encodedValue = btoa(encodeURIComponent(value.toString()));

  document.cookie =
    name +
    '=' +
    encodedValue +
    expires +
    domain +
    '; path=/' +
    '; secure' +
    '; samesite=strict';
};
