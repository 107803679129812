import { IDashboard } from '../../constants/domain/dashboard.interface';
import { ITraversal } from '../../constants/domain/traversal.interface';

export enum DashboardStateActionTypes {
  DASHBOARD_LOAD_SUCCESSFUL = 'DASHBOARD_LOAD_SUCCESSFUL',
  DASHBOARD_LOAD_MORE_TRAVERSALS_SUCCESSFUL = 'DASHBOARD_LOAD_MORE_TRAVERSALS_SUCCESSFUL',
  DASHBOARD_INVALIDATE_STATE = 'DASHBOARD_INVALIDATE_STATE',
}

interface IDashboardLoadSuccessful {
  type: DashboardStateActionTypes.DASHBOARD_LOAD_SUCCESSFUL;
  dashboard: IDashboard;
}

interface IDashboardInvalidateState {
  type: DashboardStateActionTypes.DASHBOARD_INVALIDATE_STATE;
}

interface IDashboardLoadMoreTraversalsSuccessful {
  type: DashboardStateActionTypes.DASHBOARD_LOAD_MORE_TRAVERSALS_SUCCESSFUL;
  traversals: ITraversal[];
}

export type DashboardStateActions =
  | IDashboardLoadSuccessful
  | IDashboardInvalidateState
  | IDashboardLoadMoreTraversalsSuccessful;
