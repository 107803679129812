import { Alert, AlertTitle, Snackbar } from '@mui/material';
import { FunctionComponent, memo } from 'react';
import { IToastMessage } from '../../reducers/toast';

interface IProps {
  message: IToastMessage;
  onRemove: () => void;
}

const DEFAULT_MESSAGE_DURATION = 5000;

export const ToastContainer: FunctionComponent<IProps> = memo(
  ({ message, onRemove }) => {
    return (
      <Snackbar
        sx={{
          '&.MuiSnackbar-root': {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            width: '100%',
            transform: 'none',
            translate: 'none',
          },
        }}
        open
        autoHideDuration={message.duration || DEFAULT_MESSAGE_DURATION}
        onClose={onRemove}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'top',
        }}
        key={message.key}
      >
        {message && (
          <Alert
            sx={{
              flex: 1,
              justifyContent: 'center',
              borderRadius: 0,

              /**
               * @todo should be removed after MuiAlert overrides from MainTheme would be removed
               */
              '& .MuiAlert-icon': {
                color: (theme) =>
                  `${theme.palette.primary.contrastText} !important`,
              },
            }}
            severity={message.severity}
            variant="filled"
          >
            <AlertTitle>{message.title}</AlertTitle>
            {message.description}
          </Alert>
        )}
      </Snackbar>
    );
  }
);
