import {
  createInstance,
  OptimizelyProvider,
  ReactSDKClient,
  logOnlyEventDispatcher,
} from '@optimizely/react-sdk';

import optimizelyErrorHandler from './errorHandler';
import { ReactNode, memo } from 'react';
import { useOptimizelySegmentSetup } from '../../hooks/optimizely/useDecision';
import { getMetaDataFromDomain } from '../domain';
import { isAffiliate } from '../affiliate/isAffiliate';

const tenantConfig = getMetaDataFromDomain();

// Relies on continous internet connection in contrast to the datafile approach
export const optimizelyClient: ReactSDKClient = createInstance({
  sdkKey: tenantConfig.tracking.optimizelySdkKey,
  errorHandler: optimizelyErrorHandler,
  eventDispatcher: logOnlyEventDispatcher,
});

const CustomOptimizelyProvider: React.FC<{
  userId: string | null;
  context?: string;
  children?: ReactNode;
}> = memo(({ children, userId, context }) => {
  // Preload decisions for the user
  useOptimizelySegmentSetup();
  const isOptimizelyDisabled = isAffiliate(context);

  if (!userId || isOptimizelyDisabled) {
    return <>{children}</>;
  }

  return (
    <OptimizelyProvider optimizely={optimizelyClient} user={{ id: userId }}>
      {children}
    </OptimizelyProvider>
  );
});

export default CustomOptimizelyProvider;
