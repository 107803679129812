import { useContext, useCallback, FC, PropsWithChildren } from 'react';
import { useHistory } from 'react-router';
import { logError } from '@finanzcheck/ti-shared-ui/utils/log';
import { routes } from 'constants/routes';
import { useQueryClient } from 'react-query';
import { SessionError } from 'errors/sessionError';
import { UserStateActionTypes } from '../../../actions/user';
import { isChallengedUser } from '../../../reducers/user';
import {
  completeNewPasswordChallenge,
  signIn,
  signOut,
} from '@finanzcheck/ti-shared-ui/utils/amplify/amplify';
import { removeGuestTokenFromSessionStorage, setAccessToken } from './utils';
import { useSession } from './hooks';
import jwtDecode from 'jwt-decode';
import { setCookie } from '@finanzcheck/ti-shared-ui/utils/cookie';
import { getStorage } from '@finanzcheck/ti-shared-ui/utils/storage';
import { getGuestToken } from 'adapter/guestLoginAdapter';
import { IGuestToken, UserDispatchContext, UserStateContext } from './context';
import { SignInState } from '@finanzcheck/ti-shared-ui/constants/interfaces/Session';

const UserProvider: FC<PropsWithChildren> = ({ children }) => {
  const { dispatch, user, isLoading, isError, logoutUser, handleGuestLogin } =
    useSession();
  const { push } = useHistory();
  const queryClient = useQueryClient();

  const handleLogin = useCallback(
    async (userLogin: string, password: string, redirectUrl?: string) => {
      removeGuestTokenFromSessionStorage();
      const cognitoUser = await signIn(userLogin, password);
      await setAccessToken();

      if (cognitoUser.challengeName === SignInState.NEW_PASSWORD_REQUIRED) {
        dispatch({
          type: UserStateActionTypes.USER_IN_CHALLENGEMODE,
          user: cognitoUser,
        });
        return;
      }

      dispatch({
        type: UserStateActionTypes.USER_LOGIN_SUCCESSFUL,
        user: cognitoUser,
      });
      if (redirectUrl) {
        push(redirectUrl);
      }
    },
    [dispatch, push]
  );

  const handleLogout = useCallback(async () => {
    try {
      await signOut();
      logoutUser();
      queryClient.clear();
    } catch (e) {
      logError(e);
    } finally {
      push(routes.login);
    }
  }, [logoutUser, queryClient, push]);

  const handleIdleLogout = useCallback(async () => {
    await handleLogout();
    dispatch({
      type: UserStateActionTypes.USER_IDLE_LOGOUT,
    });
  }, [dispatch, handleLogout]);

  const deleteIdleState = useCallback(() => {
    dispatch({
      type: UserStateActionTypes.USER_IDLE_DELETE,
    });
  }, [dispatch]);

  const handleChallenge = useCallback(
    async (password: string) => {
      if (!isChallengedUser(user)) {
        throw new SessionError('User is not in challenge mode');
      }

      const confirmedUser = await completeNewPasswordChallenge(
        user.challengeUser,
        password
      );

      dispatch({
        type: UserStateActionTypes.USER_LOGIN_SUCCESSFUL,
        user: confirmedUser,
      });
    },
    [dispatch, user]
  );

  const guestLogin = useCallback(
    async (
      newDateOfBirthValue: string,
      currentToken: string,
      campaign?: string
    ) => {
      const { token, refresh, expiryDate } = await getGuestToken(
        newDateOfBirthValue,
        currentToken,
        campaign
      );
      getStorage('sessionStorage').setItem('dateOfBirth', newDateOfBirthValue);
      setCookie(
        'guestAccessToken',
        token,
        parseInt(import.meta.env.VITE_GUEST_SESSION_TTLS_DAYS)
      );

      setCookie(
        'guestRefreshTokenExp',
        expiryDate,
        parseInt(import.meta.env.VITE_GUEST_SESSION_TTLS_DAYS)
      );
      setCookie(
        'guestRefreshToken',
        refresh,
        parseInt(import.meta.env.VITE_GUEST_SESSION_TTLS_DAYS)
      );
      const accessTokenBody = jwtDecode<IGuestToken>(token);
      handleGuestLogin(accessTokenBody, token);
    },
    [handleGuestLogin]
  );

  return (
    <UserDispatchContext.Provider
      value={{
        guestLogin: guestLogin,
        logIn: handleLogin,
        logOut: handleLogout,
        completeChallenge: handleChallenge,
        idleLogout: handleIdleLogout,
        idleDelete: deleteIdleState,
      }}
    >
      <UserStateContext.Provider value={{ user, isLoading, isError }}>
        {children}
      </UserStateContext.Provider>
    </UserDispatchContext.Provider>
  );
};

UserProvider.displayName = 'UserProvider';

export default UserProvider;

export const useUserState = () => useContext(UserStateContext);
export const useUserDispatch = () => useContext(UserDispatchContext);
