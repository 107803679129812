import { Helmet } from 'react-helmet';
import { getConfig } from '@finanzcheck/ti-shared-ui/utils/tenant';
import { config } from 'constants/config';

import { getCurrentTenantConfig } from 'utils/tenant';
import { useClient } from '@finanzcheck/ti-shared-ui/components/Context/Client';
import { config as domainConfig } from './config';

export const DynamicHead = () => {
  const { icons, displayName } = useClient();
  const { domain } = getCurrentTenantConfig();
  const { iconColor, description, tenantDomainName, noScriptContent, font } =
    getConfig(domain, domainConfig);

  const { host } = config.cdn[domain];

  return (
    <Helmet>
      <link rel="icon" sizes="180x180" href={icons?.appleTouch} />
      <link rel="mask-icon" href={icons?.safariPinned} color={iconColor} />
      <meta name="msapplication-TileImage" content={icons?.appleTouch} />
      <meta name="apple-mobile-web-app-title" content={displayName} />
      <meta name="application-name" content={displayName} />
      <meta name="description" content={description} />
      <link rel="preconnect" href={host} />
      <link href={`${host}/fonts/${font}.css`} rel="stylesheet" />
      <noscript>{noScriptContent}</noscript>
      <link rel="manifest" href={`/manifest.${tenantDomainName}.json`} />
    </Helmet>
  );
};
