// TODO: These images should be static assets and not bundled...
import { IConfig, TenantDomain } from '@finanzcheck/ti-shared-ui/utils/tenant';

import OperatorMaleFC from '../UI/images/operator/finanzcheck/operator_male_square.png';
import OperatorFemaleFC from '../UI/images/operator/finanzcheck/operator_female_square.jpg';
import OperatorDefaultFC from '../UI/images/operator/finanzcheck/operator_default_square.jpg';
import OperatorNeutralFC from '../UI/images/operator/finanzcheck/operator_neutral.png';
import OperatorMaleSmava from '../UI/images/operator/smava/operator_male_square.png';
import OperatorFemaleSmava from '../UI/images/operator/smava/operator_female_square.png';
import OperatorDefaultSmava from '../UI/images/operator/smava/operator_default_square.png';
import OperatorNeutralSmava from '../UI/images/operator/smava/operator_neutral.png';

interface DomainConfig {
  OperatorMale: string;
  OperatorFemale: string;
  OperatorDefault: string;
  OperatorNeutral: string;
}

export const config: IConfig<DomainConfig> = {
  [TenantDomain.Finanzcheck]: {
    OperatorMale: OperatorMaleFC,
    OperatorFemale: OperatorFemaleFC,
    OperatorDefault: OperatorDefaultFC,
    OperatorNeutral: OperatorNeutralFC,
  },
  [TenantDomain.Smava]: {
    OperatorMale: OperatorMaleSmava,
    OperatorFemale: OperatorFemaleSmava,
    OperatorDefault: OperatorDefaultSmava,
    OperatorNeutral: OperatorNeutralSmava,
  },
};
